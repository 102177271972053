import React from "react"
import { getFullUserName, getInitialsFromFullName } from "lib/string-utilities"
import useUserAvatar from "hooks/useUserAvatar"
import Avatar from "components/Avatar"

export function UserAvatar({ className = "", user = null, size = null }) {
  const avatar = useUserAvatar(user)
  const initials = getInitialsFromFullName(getFullUserName(user))
  return <Avatar className={className} initials={initials} avatar={avatar} size={size} />
}
