import { lazy } from "react"
import { ProtectedHubLayoutRoute } from "../components/layouts/HubLayout"
import PermissionsProtected from "../components/permissions/PermissionsProtected"
import permissions from "constants/permissions"
import { Switch } from "routing"
import { useAccess } from "components/permissions/Access"

const Locations = lazy(() => import("./LocationsList/LocationsList"))
const Location = lazy(() => import("./Location/Location"))
const NewLocation = lazy(() => import("./NewLocation/NewLocation"))

const locationsPath = "/locations"
const locationPath = "/locations/:locationId"
const locationZonesPath = "/locations/:locationId/zones"
const newLocationPath = "/locations/new"
const locationsActionRequiredPath = "/locations/action-required"

const buildLocationPath = locationId => `/locations/${locationId}`
const buildLocationZonesPath = locationId => `/locations/${locationId}/zones`

const Routes = () => {
  return (
    <Switch>
      <ProtectedHubLayoutRoute exact path={locationsPath} Component={Locations} />
      <ProtectedHubLayoutRoute exact path={locationsActionRequiredPath} Component={Locations} />
      <ProtectedHubLayoutRoute exact path={newLocationPath} Component={NewLocation} />
      <ProtectedHubLayoutRoute exact path={locationPath} Component={Location} />
      <ProtectedHubLayoutRoute exact path={locationZonesPath} Component={Location} />
    </Switch>
  )
}

const LocationsAccess = ({ children }) => children(locationsPath)
const LocationsActionRequiredAccess = ({ children }) => children(locationsActionRequiredPath)
const LocationAccess = ({ children, locationId }) => children(buildLocationPath(locationId))
const LocationsAddAccess = ({ children }) => children(newLocationPath)

const LocationsExportAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.locations.view]}>{children}</PermissionsProtected>
)

const useLocationAccess = () => useAccess([permissions.locations.view], buildLocationPath)
const useLocationListAccess = () => useAccess([permissions.locations.view], locationsPath)
const useLocationEditAccess = () => useAccess([permissions.locations.edit])
const useLocationCallerIdBulkEditAccess = () => useAccess([permissions.locations.bulkEditCallerId])

const useLocationZoneAddAccess = () => useAccess([permissions.zones.add])
const useLocationZoneEditAccess = () => useAccess([permissions.zones.edit])
const useLocationZoneDeleteAccess = () => useAccess([permissions.zones.delete])

export {
  buildLocationPath,
  buildLocationZonesPath,
  LocationsAccess,
  LocationAccess,
  LocationsActionRequiredAccess,
  LocationsAddAccess,
  locationsPath,
  locationsActionRequiredPath,
  LocationsExportAccess,
  useLocationAccess,
  useLocationListAccess,
  useLocationEditAccess,
  useLocationCallerIdBulkEditAccess,
  useLocationZoneAddAccess,
  useLocationZoneEditAccess,
  useLocationZoneDeleteAccess
}
export default Routes
