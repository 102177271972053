import ExternalPathnames from "constants/external-pathnames"
import { FormattedMessage } from "react-intl"
import useConfiguration from "hooks/useConfiguration"
import useIsHubActive from "../hooks/useIsHubActive"
import classnames from "classnames"
import { useProtectedLinkSection } from "routing"

const ExternalLinks = () => {
  const { portal } = useConfiguration()
  const isHubActive = useIsHubActive()
  const [HomeSection, HomeLink] = useProtectedLinkSection("/")

  return (
    <ul className="top-nav-bar--links">
      <HomeSection>
        <li>
          <HomeLink
            className={classnames({
              "header-navigation-button": !isHubActive,
              "header-navigation-button--active": isHubActive
            })}
            id="navigation-hub"
          >
            <FormattedMessage id="navigation.hub" />
          </HomeLink>
        </li>
      </HomeSection>
      <li>
        <a href={portal.url} className={"header-navigation-button"}>
          <FormattedMessage id="navigation.portal" />
        </a>
      </li>
      <li>
        <a href={ExternalPathnames.DATA_FUZE} className={"header-navigation-button"}>
          <FormattedMessage id="navigation.data" />
        </a>
      </li>
      <li>
        <a href={ExternalPathnames.STATUS_THINKINGPHONES} className={"header-navigation-button"}>
          <FormattedMessage id="navigation.status" />
        </a>
      </li>
    </ul>
  )
}

export default ExternalLinks
