import _maxBy from "lodash/maxBy"
import { retrieveContactsByFoundryUsers } from "data/apis/contactive"
import { useAsync } from "react-use"

export default function useUserAvatar(user) {
  const { value: avatar } = useAsync(async () => {
    // TODO Refactor avatar to accept the actual URL of the image rather than an object with a property of smallUrl
    if (user) {
      const contactive = await retrieveContactsByFoundryUsers([user])
      const contactiveOrigin = contactive && contactive.length && contactive[0] && contactive[0].origins
      const picture = contactiveOrigin?.[0]?.picture?.length > 0 && _maxBy(contactiveOrigin[0].picture, "retrieved")
      return picture
    }
  }, [user])

  return avatar
}
