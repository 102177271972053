// the line below aims to fix the IE11 bug which was caused by react-scripts upgrade to v4
// the fix is taken from this thread https://github.com/facebook/create-react-app/issues/9906
/** @jsxRuntime classic */
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "lib/polyfill"

import React from "react"
import ReactDOM from "react-dom"
import { unregister } from "./registerServiceWorker"

import { Provider } from "react-redux"
import createStore from "./store"
import TranslationProvider from "./app/TranslationProvider"
import AuthProvider from "components/authentication/AuthenticationProvider"
import { ConfigurationProvider } from "hooks/useConfiguration"

import App from "./app/App"
import { hideSpinner } from "components/spinner/PageSpinner"

import registerApiProviders from "data/apis/apiProviders"

registerApiProviders()

async function renderApplication() {
  hideSpinner()
  const store = createStore()

  ReactDOM.render(
    <TranslationProvider>
      <ConfigurationProvider>
        <Provider store={store}>
          <AuthProvider appName="hub">
            <App />
          </AuthProvider>
        </Provider>
      </ConfigurationProvider>
    </TranslationProvider>,
    document.getElementById("root")
  )
  unregister()
}

renderApplication()
