import { connect } from "react-redux"
import {
  addEarlyAccessUser,
  approveReleaseById,
  getTenantView,
  removeEarlyAccessUser,
  setRolloutSchedule,
  setTenantUpdateType
} from "data/apis/citadel"
import { getUsers, getUsersWithDepartmentsLocationsAndAvatars } from "data/user-fetcher"
import { createAsyncActionTypes } from "../../../ducks/utils"
import moment from "moment"
import { track } from "data/analytics"

const CLEAR_ERROR = "tailor/error/clear"

const viewLoadActions = createAsyncActionTypes("tailor", "view", "LOAD")
const updateTypeActions = createAsyncActionTypes("tailor", "type", "LOAD")
const updateRolloutDateActions = createAsyncActionTypes("tailor", "rolloutDate", "LOAD")
const approvedByUserActions = createAsyncActionTypes("tailor", "approvedByUsers", "LOAD")
const userLoadActions = createAsyncActionTypes("tailor", "early-access-users", "LOAD")
const userAddActions = createAsyncActionTypes("tailor", "early-access-users", "ADD")
const userRemoveActions = createAsyncActionTypes("tailor", "early-access-users", "REMOVE")

function reducer(state = {}, action = {}) {
  switch (action.type) {
    case updateTypeActions.types.failure:
    case updateRolloutDateActions.types.failure:
    case viewLoadActions.types.failure:
      const error = action.err
      return {
        ...state,
        error
      }
    case CLEAR_ERROR:
      return {
        ...state,
        error: null
      }
    case viewLoadActions.types.success:
      const view = action.results
      return {
        ...state,
        view
      }
    case userLoadActions.types.success:
      const users = action.results
      return {
        ...state,
        users
      }
    case approvedByUserActions.types.success:
      const approvedByUsers = action.results
      const result = {
        ...state,
        view: {
          ...state.view,
          updates: {
            ...state.view.updates,
            releases: state.view.updates.releases.map(populateApprovedByUser(approvedByUsers))
          }
        }
      }
      return result
    default:
      return state
  }
}

const populateApprovedByUser = users => release => {
  if (release.approved) {
    const [user] = users.filter(user => user.id === release.approval.by)
    const result = {
      ...release,
      approval: {
        ...release.approval,
        by: user
      }
    }
    return result
  }
  return release
}

function clearError() {
  return { type: CLEAR_ERROR }
}

function retrieveView() {
  return dispatch => {
    dispatch(viewLoadActions.start())
    getTenantView()
      .then(data => {
        return {
          ...data,
          users: data.users.filter(u => u.type && u.type === "automatic")
        }
      })
      .then(data => {
        localizeReleaseDates(data.updates.releases)
        dispatch(viewLoadActions.success(data))
        retrieveUsers(data.users)(dispatch)
        retrievedApprovedByUsers(data.updates.releases)(dispatch)
      })
      .catch(err => {
        dispatch(viewLoadActions.failure(err))
      })
  }
}

function retrievedApprovedByUsers(releases) {
  return dispatch => {
    const userIds = releases.filter(r => r.approved).map(r => r.approval.by)
    if (userIds.length > 0) {
      dispatch(approvedByUserActions.start())
      getUsers(userIds)
        .then(data => {
          dispatch(approvedByUserActions.success(data))
        })
        .catch(err => {
          dispatch(approvedByUserActions.failure(err))
        })
    }
  }
}

/**
 *
 * @param {Object} users - assumes that a user has a userId property
 */
function retrieveUsers(users) {
  return dispatch => {
    dispatch(userLoadActions.start())
    let ids = users.map(user => user.userId)
    getUsersWithDepartmentsLocationsAndAvatars(ids)
      .then(data => {
        dispatch(userLoadActions.success(data))
      })
      .catch(err => {
        dispatch(userLoadActions.failure(err))
      })
  }
}

function updateType(type) {
  return dispatch => {
    dispatch(updateTypeActions.start())
    setTenantUpdateType(type)
      .then(data => {
        track("Update type", { type })
        dispatch(updateTypeActions.success(data))
        retrieveView()(dispatch)
      })
      .catch(err => {
        dispatch(updateTypeActions.failure(err))
      })
  }
}

function updateRolloutDate(rolloutId, date) {
  return dispatch => {
    dispatch(updateRolloutDateActions.start())
    // no need to convert the time to GMT since moment does it for us by default
    setRolloutSchedule(rolloutId, date.format("x"))
      .then(data => {
        track("Update rollout date", { rolloutId, date: date.format() })
        dispatch(updateRolloutDateActions.success(data))
        retrieveView()(dispatch)
      })
      .catch(err => {
        dispatch(updateRolloutDateActions.failure(err))
      })
  }
}

function scheduleAndApproveRelease(release, date) {
  return dispatch => {
    // Update rollout date
    dispatch(updateRolloutDateActions.start())
    // no need to convert the time to GMT since moment does it for us by default
    setRolloutSchedule(release.rollout.id, date.format("x"))
      .then(data => {
        if (release.approved) {
          dispatch(updateRolloutDateActions.success(data))
          retrieveView()(dispatch)
        } else {
          // Approve release
          approveReleaseById(release.productVersion)
            .then(data => {
              track("Schedule and approve release", {
                rolloutId: release.rollout.id,
                date: date.format(),
                version: release.productVersion
              })
              dispatch(updateRolloutDateActions.success(data))
              retrieveView()(dispatch)
            })
            .catch(err => {
              dispatch(updateRolloutDateActions.failure(err))
            })
        }
      })
      .catch(err => {
        dispatch(updateRolloutDateActions.failure(err))
      })
  }
}

function addUser(id) {
  return dispatch => {
    dispatch(userAddActions.start())
    addEarlyAccessUser(id)
      .then(() => {
        track("Add user", { user: id })
        dispatch(userAddActions.success())
        retrieveView()(dispatch)
      })
      .catch(err => {
        dispatch(userAddActions.failure(err))
      })
  }
}

function removeUser(id) {
  return dispatch => {
    dispatch(userRemoveActions.start())
    removeEarlyAccessUser(id)
      .then(() => {
        track("Remove user", { user: id })
        dispatch(userRemoveActions.success())
        retrieveView()(dispatch)
      })
      .catch(err => {
        dispatch(userRemoveActions.failure(err))
      })
  }
}

function localizeReleaseDates(releases) {
  releases.forEach(release => {
    release.availableDate = moment(release.availableDate)
    release.releaseDate = moment(release.releaseDate)
    release.endOfSupportDate && (release.endOfSupportDate = moment(release.endOfSupportDate))
    release.rollout && release.rollout.start && (release.rollout.start = moment(release.rollout.start))
    release.rollout && release.rollout.end && (release.rollout.end = moment(release.rollout.end))
  })
  return releases
}

export default reducer
export { clearError, retrieveView, addUser, removeUser, updateType, updateRolloutDate, scheduleAndApproveRelease }

// modifies the behaviour of react-redux connect to have only the relevant portion of the state available to map functions
function DesktopUpdatesConnect(mapStateToProps, mapDispatchToProps, mergeProps, options = {}) {
  return connect(
    state => (mapStateToProps ? mapStateToProps(state.DesktopUpdates) : {}),
    mapDispatchToProps,
    mergeProps,
    options
  )
}

export { DesktopUpdatesConnect as connect }
