import { lazy } from "react"
import { ProtectedHubLayoutRoute } from "components/layouts/HubLayout"

import { rootPath, homePath } from "./Paths"

const Dashboard = lazy(() => import("./Dashboard"))

const Routes = () => {
  return (
    <>
      <ProtectedHubLayoutRoute exact path={rootPath} Component={Dashboard} />
      {/* TODO When tailor is the master app, this route can be removed */}
      <ProtectedHubLayoutRoute exact path={homePath} Component={Dashboard} />
    </>
  )
}

export default Routes
