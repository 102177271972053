import React, { useContext, useEffect, useRef } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classnames from "classnames"
import { tabId, TabsContext } from "./Tabs"

interface ITab {
  id: string | number
}

const Tab: FuzzyFunctionalComponent<ITab | HTMLButtonElement> = ({ children, className, id, ...props }) => {
  const { activeTab, onKeyUp, onTabClick, registerTab } = useContext(TabsContext)
  const buttonRef = useRef(null)

  const isActive = id === activeTab

  useEffect(() => {
    if (isActive) {
      buttonRef.current.focus()
    }
  }, [isActive])

  useEffect(() => {
    registerTab(id)
  }, [id])

  return (
    <button
      aria-selected={isActive}
      aria-controls={`panel-${id}`}
      id={`tab-${id}`}
      className={classnames(className, "fuze-tabs__tab", {
        "fuze-tablist__tab--active": isActive,
      })}
      onClick={() => onTabClick(id)}
      onKeyUp={(event) => onKeyUp(event)}
      role="tab"
      ref={buttonRef}
      tabIndex={isActive ? 0 : -1}
      type="button"
      {...props}
    >
      {children}
    </button>
  )
}

export default Tab
