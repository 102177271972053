import {
  registerUrlProvider,
  registerErrorHandlerProvider,
  registerBearerTokenProvider,
  registerOrganizationProvider,
  registerImpersonatorBearerTokenProvider,
  registerImpersonatorOrganizationProvider
} from "@fuze/apis-mint"

import getConfiguration from "data/configuration"
import { getImpersonatorBearerToken, getToken } from "lib/token"
import { errorHandler } from "../errorInterceptor"
import { getOrganization as getActingOrganization, getOriginalImpersonatorOrganization } from "lib/user-session"

export default function registerApiProviders() {
  registerUrlProvider(() => getConfiguration().then(data => data.mint.url))
  registerBearerTokenProvider(async () => getToken())
  registerErrorHandlerProvider(async () => errorHandler)
  registerOrganizationProvider(async () => getActingOrganization())
  registerImpersonatorBearerTokenProvider(() => getImpersonatorBearerToken() ?? getToken())
  registerImpersonatorOrganizationProvider(() => getOriginalImpersonatorOrganization())
}
