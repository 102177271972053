import { connect } from "react-redux"
import { createAsyncActionTypes } from "../../../ducks/utils"
import { track } from "data/analytics"
import {
  getGuestsPolicy,
  setGuestsProfile,
  setGuestsEmailNotifications,
  getGuests,
  deactivateGuest,
  getGuestsEmailNotifications
} from "data/apis/warden"
import { retrieveContactsByFoundryUsers } from "data/apis/contactive"
import _find from "lodash/find"
import { getUsersWithDepartmentsAndLocations } from "data/user-fetcher"

const userLoadActions = createAsyncActionTypes("tailor", "users", "LOAD")
const guestsLoadActions = createAsyncActionTypes("tailor", "guests", "LOAD")
const guestsSearchActions = createAsyncActionTypes("tailor", "guests", "SEARCH")
const guestSearchByIdActions = createAsyncActionTypes("tailor", "guestId", "SEARCH")
const contactiveDataLoadActions = createAsyncActionTypes("tailor", "contactive", "LOAD")
const policyLoadActions = createAsyncActionTypes("tailor", "policy", "LOAD")
const updatePolicyActions = createAsyncActionTypes("tailor", "policy", "UPDATE")
const updateEmailNotificationsActions = createAsyncActionTypes("tailor", "emailNotifications", "UPDATE")
const retrieveEmailNotificationsActions = createAsyncActionTypes("tailor", "emailNotifications", "LOAD")

// we may already have a deactivatedGuests within the state, in this case copy over its registration data
function combineGuests(existingGuests, newGuests) {
  const combinedGuests = newGuests.map(newGuest => {
    const cachedGuest = _find(existingGuests, existingGuest => existingGuest.userId === newGuest.userId)
    if (cachedGuest) {
      return { ...newGuest, registrationDetails: cachedGuest.registrationDetails }
    } else {
      return newGuest
    }
  })
  return combinedGuests
}

function reducer(state = {}, action = {}) {
  let guests, users

  switch (action.type) {
    case policyLoadActions.types.success:
      const policies = action.results
      return {
        ...state,
        guestsPolicy: policies[0].policy === "all" && policies[1].policy === "all"
      }
    case guestsLoadActions.types.start:
      guests = []
      users = []
      return {
        ...state,
        guests,
        users
      }
    case guestsLoadActions.types.failure:
      return {
        ...state,
        errors: action.err
      }
    case guestsLoadActions.types.success:
    case guestsSearchActions.types.success:
      guests = combineGuests(state.guests, action.results)
      return {
        ...state,
        guests
      }
    case guestSearchByIdActions.types.success:
      guests = combineGuests(state.guestId, action.results)
      return {
        ...state,
        guests
      }
    case userLoadActions.types.success:
      users = action.results
      return {
        ...state,
        users
      }
    case contactiveDataLoadActions.types.success:
      // filter out null values and unpack the results
      const contactiveData = action.results.filter(n => n)

      const withContactiveData = state.guests.map(guest => {
        const [matchingContactiveData] = contactiveData.filter(
          data => data.key.split("ngchat:user_")[1] === guest.userId
        )
        const details = matchingContactiveData && matchingContactiveData.origins && matchingContactiveData.origins[0]
        if (details) {
          // if details are found, then append to guest
          return {
            ...guest,
            registrationDetails: details
          }
        } else {
          // else return guest as is
          return {
            ...guest
          }
        }
      })

      return {
        ...state,
        guests: withContactiveData
      }
    case retrieveEmailNotificationsActions.types.success:
      const emailNotifications = action.results.policy === "all"
      return {
        ...state,
        emailNotifications
      }
    default:
      return state
  }
}

function retrieveGuestsPolicy() {
  return dispatch => {
    dispatch(policyLoadActions.start())
    getGuestsPolicy()
      .then(data => {
        dispatch(policyLoadActions.success(data))
      })
      .catch(err => {
        dispatch(policyLoadActions.failure(err))
      })
  }
}

function retrieveGuests(areActive = true) {
  return dispatch => {
    dispatch(guestsLoadActions.start())
    getGuests({}, areActive) // {} == send empty options
      .then(data => {
        data.forEach(item => {
          const parts = item.inviter.split("_")
          if (parts[0] === "user") {
            item.inviterId = parts[1]
          }
        })
        const userIDs = data.map(item => item.inviterId)
        getUsersWithDepartmentsAndLocations(userIDs)
          .then(data => {
            dispatch(userLoadActions.success(data))
          })
          .catch(err => {
            dispatch(userLoadActions.failure(err))
          })
        dispatch(guestsLoadActions.success(data))
      })
      .catch(err => dispatch(guestsLoadActions.failure(err)))
  }
}

function searchGuests(options, areActive = true) {
  return dispatch => {
    dispatch(guestsSearchActions.start())
    getGuests(options, areActive)
      .then(data => {
        data.forEach(item => {
          const parts = item.inviter.split("_")
          if (parts[0] === "user") {
            item.inviterId = parts[1]
          }
        })
        const userIDs = data.map(item => item.inviterId)
        getUsersWithDepartmentsAndLocations(userIDs)
          .then(data => {
            dispatch(userLoadActions.success(data))
          })
          .catch(err => {
            dispatch(userLoadActions.failure(err))
          })
        dispatch(guestsSearchActions.success(data))
      })
      .catch(err => {
        dispatch(guestsSearchActions.failure(err))
      })
  }
}

function searchGuestById(options, areActive = true) {
  return dispatch => {
    dispatch(guestSearchByIdActions.start())
    getGuests(options, areActive)
      .then(data => {
        data.forEach(item => {
          const parts = item.inviter.split("_")
          if (parts[0] === "user") {
            item.inviterId = parts[1]
          }
        })
        const userIDs = data.map(item => item.inviterId)
        getUsersWithDepartmentsAndLocations(userIDs)
          .then(data => {
            dispatch(userLoadActions.success(data))
          })
          .catch(err => {
            dispatch(userLoadActions.failure(err))
          })
        dispatch(guestSearchByIdActions.success(data))
      })
      .catch(err => {
        dispatch(guestSearchByIdActions.failure(err))
      })
  }
}

function retrieveContactiveData(users) {
  return dispatch => {
    dispatch(contactiveDataLoadActions.start())
    retrieveContactsByFoundryUsers(users)
      .then(data => {
        dispatch(contactiveDataLoadActions.success(data))
      })
      .catch(err => {
        dispatch(contactiveDataLoadActions.failure(err))
      })
  }
}

function updateGuestsPolicy(profile) {
  return dispatch => {
    dispatch(updatePolicyActions.start())
    setGuestsProfile(profile)
      .then(data => {
        track("Update Guests policy", { profile })
        dispatch(updatePolicyActions.success(data))
        retrieveGuestsPolicy()(dispatch)
      })
      .catch(err => {
        dispatch(updatePolicyActions.failure(err))
      })
  }
}

function updateEmailNotifications(state) {
  return dispatch => {
    dispatch(updateEmailNotificationsActions.start())
    setGuestsEmailNotifications(state ? "enabled" : "disabled")
      .then(data => {
        track("Update Email Notifications for Guests", { state })
        dispatch(updateEmailNotificationsActions.success(data))
        retrieveEmailNotifications()(dispatch)
      })
      .catch(err => {
        dispatch(updateEmailNotificationsActions.failure(err))
      })
  }
}

function retrieveEmailNotifications() {
  return dispatch => {
    dispatch(retrieveEmailNotificationsActions.start())
    getGuestsEmailNotifications()
      .then(data => {
        dispatch(retrieveEmailNotificationsActions.success(data))
      })
      .catch(err => {
        dispatch(retrieveEmailNotificationsActions.failure(err))
      })
  }
}

function deactivateGuestIds(guestIds, guests) {
  const deactivatePromises = guestIds.map(id => {
    // return the guest details currently in memory
    //   for use by the calling component,
    //   because it will be gone after deactivation
    const guestDetails = guests.filter(guest => guest.userId === id)[0]
    return new Promise(resolve => {
      deactivateGuest(id)
        .then(data => {
          resolve({ success: true, guest: guestDetails, data: data })
        })
        .catch(err => {
          resolve({ success: false, guest: guestDetails, err: err })
        })
    })
  })

  return Promise.all(deactivatePromises).then(results => {
    track("Deactivate guests", { guests: guestIds })
    return results
  })
}

export default reducer
export {
  retrieveGuests,
  retrieveGuestsPolicy,
  searchGuests,
  searchGuestById,
  updateGuestsPolicy,
  updateEmailNotifications,
  deactivateGuestIds,
  retrieveContactiveData,
  retrieveEmailNotifications
}

// modifies the behaviour of react-redux connect to have only the relevant portion of the state available to map functions
function GuestsConnect(mapStateToProps, mapDispatchToProps, mergeProps, options = {}) {
  return connect(
    state => (mapStateToProps ? mapStateToProps(state.Guests) : {}),
    mapDispatchToProps,
    mergeProps,
    options
  )
}

export { GuestsConnect as connect }
