import { useState, useEffect } from "react"
import { getExpandedJob } from "data/apis/foundry"

export default function useExpandedJob(jobId) {
  const [job, setJob] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function retrieveJob() {
      const job = await getExpandedJob(jobId)
      setJob(job)
      setLoading(false)
    }
    retrieveJob()
  }, [jobId])

  return [job, loading]
}
