import React from "react"
import { FormattedMessage } from "react-intl"
import logo from "./images/fuze-logo-text-purple.svg"

import "./MaintenancePage.scss"

const MaintenancePage = () => {
  return (
    <div className="maintenance">
      <header>
        <img src={logo} alt="Fuze" width="104" height="33" />
      </header>

      <div className="maintenance__contents">
        <div className="maintenance__well">
          <h1 className="maintenance__title">
            <FormattedMessage id="maintenance.title" />
          </h1>
          <p className="maintenance__message">
            <FormattedMessage id="maintenance.description" />
          </p>
        </div>
        <p className="maintenance__up-to-date">
          <FormattedMessage id="maintenance.upToDate" />
        </p>
        <a href="https://status.fuze.com">
          <FormattedMessage id="maintenance.viewStatus" />
        </a>
      </div>
    </div>
  )
}

export default MaintenancePage
