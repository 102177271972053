import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface IModalSubtitle {}

const ModalSubtitle: FuzzyFunctionalComponent<IModalSubtitle> = ({ children, className, ...props }) => {
  return (
    <h2 className={classnames(className, "fuze-modal__subtitle modal-subtitle")} {...props}>
      {children}
    </h2>
  )
}

export { ModalSubtitle }
