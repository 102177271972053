import { lazy } from "react"
import { Switch } from "routing"

import { ProtectedHubLayoutRoute } from "components/layouts/HubLayout"

import { chatQueuesPath, addChatQueuePath, editChatQueuePath } from "./Paths"

const ChatQueues = lazy(() => import("./ChatQueues/ChatQueueList"))
const AddChatQueue = lazy(() => import("./ChatQueues/AddChatQueue"))
const EditChatQueue = lazy(() => import("./ChatQueues/EditChatQueue"))

const ContactCenterRoutes = () => {
  return (
    <Switch>
      <ProtectedHubLayoutRoute exact path={chatQueuesPath} Component={ChatQueues} />
      <ProtectedHubLayoutRoute exact path={addChatQueuePath} Component={AddChatQueue} />
      <ProtectedHubLayoutRoute exact path={editChatQueuePath} Component={EditChatQueue} />
    </Switch>
  )
}

export default ContactCenterRoutes
