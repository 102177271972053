import React from "react"
import Button from "../Buttons/Button"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classnames from "classnames"
import { ButtonProps } from "reactstrap"

interface ITableButton {}

const TableButton: FuzzyFunctionalComponent<ITableButton & ButtonProps> = ({ children, className, ...props }) => {
  return (
    <Button className={classnames("fuze-table-button", className)} {...props}>
      {children}
    </Button>
  )
}

export default TableButton
