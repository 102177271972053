import _getMobiles from "@fuze/apis-foundry/dist/mobiles/functions/getMobiles"

import { wrapAllResultsNoOrganization } from "./decorators"

/**
 * Get Fuze applications installed on user mobiles
 *
 * @param userName The username of the user being requested
 */
export const getMobiles = userName => wrapAllResultsNoOrganization(_getMobiles)(userName)
