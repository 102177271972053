import React from "react"
import { Col, Row } from "reactstrap"
import OrdersModalContent from "./OrdersModalContent"
import { TripleRowSingleColTableWithFooter } from "./tables"
import { FormattedMessage, injectIntl } from "react-intl"
import FormattedNumberNoWarning from "components/FormattedNumberNoWarning"
import { getPhoneBySku } from "data/hardware"
import { useAsync } from "react-use"
import noPhoneImage from "images/device_illustration_outline_no_plus.svg"

import styles from "./HardwareModal.module.scss"
import { Modal, ModalHeader, ModalBody, ModalSubHeader } from "@fuze/react-fuzzy"
import useDeviceImageResolver from "hooks/useDeviceImageResolver"

function usePhoneBySku(sku) {
  const { value } = useAsync(() => getPhoneBySku(sku))
  const imageResolver = useDeviceImageResolver()
  return imageResolver(value)
}

const HardwareModal = ({ isOpen, onToggle, billing, completedBy, loadDate, order, intl }) => {
  const product = usePhoneBySku(order?.sku)

  if (!order) {
    return null
  }

  const src = product?.image ? `${product.image}` : noPhoneImage
  const features = product?.features || []

  return (
    <Modal id="hardware-modal" isOpen={isOpen} toggle={onToggle} color="primary">
      <ModalHeader>{intl.formatMessage({ id: "table.modals.hardwareTitle" }, { product: order.brand })}</ModalHeader>
      <ModalSubHeader>
        <OrdersModalContent date={loadDate} completedBy={completedBy} />
      </ModalSubHeader>
      <ModalBody>
        <TripleRowSingleColTableWithFooter
          headerTitle={<FormattedMessage id={"table.modals.hardwareDetails"} />}
          firstRowData={
            <Row className="align-items-center">
              <Col className={styles.deviceImage} sm={6}>
                <img src={src} alt="" className="img-fluid" />
              </Col>
              <Col className={styles.deviceDetails} sm={6}>
                <div className={styles.productName}>{order.model}</div>
                {features.length > 0 && (
                  <ul>
                    {features.map(feature => (
                      <li key={feature}>{feature}</li>
                    ))}
                  </ul>
                )}
              </Col>
            </Row>
          }
          secondRowTitle={
            <div className={styles.orderDetails}>
              <span>
                <FormattedMessage id={"table.modals.hardwareOrderDetails"} />
              </span>
              {order.poNumber && (
                <span>
                  <FormattedMessage id={"table.modals.hardwarePoNumber"} values={{ poNumber: order.poNumber }} />
                </span>
              )}
            </div>
          }
          thirdRowData={
            <Row>
              <Col md={6}>
                {order.quantity && (
                  <div className={styles.information}>
                    <FormattedMessage
                      id={"table.modals.hardwareQuantity"}
                      values={{
                        quantity: <span className={styles.informationValue}>{order.quantity}</span>
                      }}
                    />
                  </div>
                )}
                {order.shippingLocation && (
                  <div className={styles.information}>
                    <FormattedMessage
                      id={"table.modals.hardwareShippingLocation"}
                      values={{
                        location: <span className={styles.informationValue}>{order.shippingLocation}</span>
                      }}
                    />
                  </div>
                )}
                {order.billingLocation && (
                  <div className={styles.information}>
                    <FormattedMessage
                      id={"table.modals.hardwareBillingLocation"}
                      values={{
                        location: <span className={styles.informationValue}>{order.billingLocation}</span>
                      }}
                    />
                  </div>
                )}
              </Col>
              <Col md={6}>
                {order.contactName && (
                  <div className={styles.information}>
                    <FormattedMessage
                      id={"table.modals.hardwareContactName"}
                      values={{
                        name: <span className={styles.informationValue}>{order.contactName}</span>
                      }}
                    />
                  </div>
                )}
                {order.contactPhoneNumber && (
                  <div className={styles.information}>
                    <FormattedMessage
                      id={"table.modals.hardwareContactPhone"}
                      values={{
                        phone: <span className={styles.informationValue}>{order.contactPhoneNumber}</span>
                      }}
                    />
                  </div>
                )}
                {order.contactEmail && (
                  <div className={styles.information}>
                    <FormattedMessage
                      id={"table.modals.hardwareContactEmail"}
                      values={{
                        email: <span className={styles.informationValue}>{order.contactEmail}</span>
                      }}
                    />
                  </div>
                )}
              </Col>
            </Row>
          }
          billingImpact={
            billing.billingImpact !== undefined ? (
              <FormattedMessage
                id={"table.modals.billingImpactAdded"}
                values={{
                  isPositive: billing.billingImpact > 0 ? "+" : "",
                  amount: (
                    <FormattedNumberNoWarning
                      value={billing.billingImpact}
                      styleProp={"currency"}
                      currency={billing.currency}
                    />
                  )
                }}
              />
            ) : null
          }
        />
      </ModalBody>
    </Modal>
  )
}

export default injectIntl(HardwareModal)
