import { FormattedMessage } from "react-intl"
import React from "react"

import styles from "./tables.module.scss"
import { Col, Row } from "reactstrap"
import classNames from "classnames"

const SingleColUserRow = ({ data }) => (
  <tr>
    <td className={classNames(styles.td, styles.userCell)} colSpan="2">
      <Row>
        <Col lg={8}>{data}</Col>
      </Row>
    </td>
  </tr>
)

const TableFooter = ({ billingImpact, messageElement }) =>
  billingImpact ? (
    <tfoot className={styles.footer}>
      <tr>
        <td className={classNames(styles.th, styles.leftCell)}>{messageElement}</td>
        <td className={classNames(styles.th, styles.billingCell)}>{billingImpact}</td>
      </tr>
    </tfoot>
  ) : null

const TaxesMessage = () => (
  <Row>
    <Col className={styles.footerMessage}>
      <FormattedMessage id={"table.modals.taxes"} />
    </Col>
  </Row>
)

/* SingleRowDoubleColTableWithFooter
  |-------------------------|
  | Header 1a  | Header 1b  |
  |-------------------------|
  | Cell 1a    | Cell 1b    |
  |-------------------------|
  | Foooter a  | Footer b   | - optional
  |-------------------------|
                    Message
*/
export const SingleRowDoubleColTableWithFooter = ({
  leftSideTitle,
  leftSideData,
  rightSideTitle,
  rightSideData,
  billingImpact
}) => (
  <>
    <table className={classNames(styles.orderTable, styles.verticalTable)}>
      <thead className={styles.header}>
        <tr>
          <th className={styles.th}>{leftSideTitle}</th>
          <th className={classNames(styles.th, styles.mobileHiddenTh)}>{rightSideTitle}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={classNames(styles.td, styles.dividerCell)}>{leftSideData}</td>
          <td className={classNames(styles.td, styles.rightCell)}>{rightSideData}</td>
        </tr>
      </tbody>
      <TableFooter
        billingImpact={billingImpact}
        messageElement={<FormattedMessage id={"table.modals.billingImpact"} />}
      />
    </table>
    {billingImpact && <TaxesMessage />}
  </>
)

/* SingleRowSingleColTableWithFooter
  |------------------------|
  | Header 1a              |
  |------------------------|
  | Icon/Avatar Cell 1a    |
  |------------------------|
  | Foooter a  | Footer b  | - optional
  |------------------------|
                    Message
*/
export const SingleRowSingleColTableWithFooter = ({ title, data, billingImpact }) => (
  <>
    <table className={classNames(styles.orderTable, styles.verticalTable)}>
      <thead className={styles.header}>
        <tr>
          <th className={styles.th} colSpan="2">
            {title}
          </th>
        </tr>
      </thead>
      <tbody>
        <SingleColUserRow data={data} />
      </tbody>
      <TableFooter
        billingImpact={billingImpact}
        messageElement={<FormattedMessage id={"table.modals.billingImpact"} />}
      />
    </table>
    {billingImpact && <TaxesMessage />}
  </>
)

/* TripleRowSingleDoubleColTableWithFooter
  |-------------------------|
  | Header 1a               |
  |-------------------------|
  | Icon/Avatar Cell 1a     |
  |-------------------------|
  | Header 2a  | Header 2b  |
  |-------------------------|
  | Cell 3a    | Cell 3b    |
  |-------------------------|
  | Foooter a  | Footer b   | - optional
  |-------------------------|
                    Message
*/
export const TripleRowSingleDoubleColTableWithFooter = ({
  firstRowTitle,
  firstRowData,
  secondRowLeftSideTitle,
  secondRowRightSideTitle,
  thirdRowLeftSideData,
  thirdRowRightSideData,
  billingImpact
}) => (
  <>
    <table className={classNames(styles.orderTable, styles.verticalTable)}>
      <thead className={styles.header}>
        <tr>
          <th className={styles.th} colSpan="2">
            {firstRowTitle}
          </th>
        </tr>
      </thead>
      <tbody>
        <SingleColUserRow data={firstRowData} />
        <tr>
          <th className={styles.th}>{secondRowLeftSideTitle}</th>
          <th className={classNames(styles.th, styles.mobileHiddenTh)}>{secondRowRightSideTitle}</th>
        </tr>
        <tr>
          <td className={classNames(styles.td, styles.dividerCell)}>{thirdRowLeftSideData}</td>
          <td className={classNames(styles.td, styles.rightCell)}>{thirdRowRightSideData}</td>
        </tr>
      </tbody>
      <TableFooter
        billingImpact={billingImpact}
        messageElement={<FormattedMessage id={"table.modals.billingImpact"} />}
      />
    </table>
    {billingImpact && <TaxesMessage />}
  </>
)

/* TripleRowSingleColTableWithFooter
  |-------------------------|
  | Header 1a               |
  |-------------------------|
  | Cell 1a                 |
  |-------------------------|
  | Header 2a               |
  |-------------------------|
  | Cell 3a                 |
  |-------------------------|
  | Foooter a  | Footer b   | - optional
  |-------------------------|
                    Message
*/
export const TripleRowSingleColTableWithFooter = ({
  headerTitle,
  firstRowData,
  secondRowTitle,
  thirdRowData,
  billingImpact
}) => (
  <>
    <table className={styles.orderTable}>
      <thead className={styles.header}>
        <tr>
          <th className={styles.th} colSpan="2">
            {headerTitle}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={styles.td} colSpan="2">
            {firstRowData}
          </td>
        </tr>
        <tr>
          <th className={styles.th} colSpan="2">
            {secondRowTitle}
          </th>
        </tr>
        <tr>
          <td className={styles.td} colSpan="2">
            {thirdRowData}
          </td>
        </tr>
      </tbody>
      <TableFooter
        billingImpact={billingImpact}
        messageElement={<FormattedMessage id={"table.modals.oneTimeCharge"} />}
      />
    </table>
    {billingImpact && <TaxesMessage />}
  </>
)
