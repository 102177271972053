import React from "react"
import OrdersModalContent from "./OrdersModalContent"
import { SingleRowSingleColTableWithFooter } from "./tables"
import { FormattedMessage } from "react-intl"
import LineDetails from "./LineDetails"
import BillingImpactMessage from "./BillingImpactMessage"
import { Modal, ModalHeader, ModalSubHeader, ModalBody, ModalFooter } from "@fuze/react-fuzzy"
import ModalFooterButtons from "components/buttons/ModalFooterButtons"

const NewLineActivatedModal = ({ isOpen, onToggle, lineDetails, billing, completedBy, loadDate }) => {
  if (!lineDetails) {
    return null
  }
  return (
    <Modal id="new-line-activated-modal" isOpen={isOpen} toggle={onToggle} color="primary">
      <ModalHeader>
        <FormattedMessage id={"table.modals.lineAddedTitle"} />
      </ModalHeader>
      <ModalSubHeader>
        <OrdersModalContent date={loadDate} completedBy={completedBy} />
      </ModalSubHeader>
      <ModalBody>
        <SingleRowSingleColTableWithFooter
          title={<FormattedMessage id={"table.modals.lineDetails"} />}
          data={<LineDetails details={lineDetails} />}
          billingImpact={billing.billingImpact !== undefined && <BillingImpactMessage billing={billing} />}
        />
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons onAction={onToggle}>
          <FormattedMessage id="common.finish" />
        </ModalFooterButtons>
      </ModalFooter>
    </Modal>
  )
}

export default NewLineActivatedModal
