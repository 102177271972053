import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import InternalAvatarOverlay from "./InternalAvatarOverlay"
import classNames from "classnames"

interface IAvatarIcon {}

const AvatarIcon: FuzzyFunctionalComponent<IAvatarIcon> = ({ children, className }) => {
  return (
    <InternalAvatarOverlay className={classNames(className, "fuze-avatar__overlay--icon")}>
      {children}
    </InternalAvatarOverlay>
  )
}

export default AvatarIcon
