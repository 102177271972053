import React, { useContext } from "react"
import classNames from "classnames"
import { Tooltip as ReactstrapTooltip } from "reactstrap"
import { TooltipContext } from "./TooltipContainer"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface IFuzeTooltip {}

const FuzeTooltip: FuzzyFunctionalComponent<IFuzeTooltip> = ({ className, ...props }) => {
  const tooltipContext = useContext(TooltipContext)

  if (!tooltipContext) {
    return null
  }

  const { id: target, isOpen, method, toggle } = tooltipContext

  return (
    <ReactstrapTooltip
      className={classNames("fuze-tooltip", className)}
      isOpen={isOpen}
      target={target}
      toggle={toggle}
      trigger={method.substring(2).toLowerCase()}
      {...props}
    />
  )
}

export default FuzeTooltip
