export const createUserFaxServiceEvent = "fax-service-create"
export const removeUserFaxServiceEvent = "fax-service-delete"
export const createUserRecordingServiceEvent = "recording-service-create"
export const removeUserRecordingServiceEvent = "recording-service-delete"
export const createUserPhoneNumberServiceEvent = "didaddon-service-create"
export const removeUserPhoneNumberServiceEvent = "didaddon-service-delete"
export const createUserDiscoverServiceEvent = "discover-service-create"
export const removeUserDiscoverServiceEvent = "discover-service-delete"
export const createUserContactCenterServiceEvent = "contact-center-service-create"
export const removeUserContactCenterServiceEvent = "contact-center-service-delete"
export const createUserSMSServiceEvent = "sms-service-added"
export const removeUserSMSServiceEvent = "sms-service-delete"
export const createPhoneLineServiceEvent = "did-service-create"
export const removePhoneLineServiceEvent = "did-service-delete"
export const createUserEvent = "user-create"
export const deactivateUserEvent = "user-deactivate"
export const deleteUserEvent = "user-delete"
export const bulkCreateUsersEvent = "bulk-user-create"
export const upgradeUserServicesEvent = "service-upgrade"
export const createUtilityServiceEvent = "utility-phone-create"
export const removeUtilityServiceEvent = "utility-deprovision"
export const createTeamsDirectRoutingServiceEvent = "teams-direct-routing-service-create"
export const removeTeamsDirectRoutingServiceEvent = "teams-direct-routing-service-remove"

export const serviceActivityEventTypes = [
  createUserFaxServiceEvent,
  removeUserFaxServiceEvent,
  createUserRecordingServiceEvent,
  removeUserRecordingServiceEvent,
  createUserPhoneNumberServiceEvent,
  removeUserPhoneNumberServiceEvent,
  createUserDiscoverServiceEvent,
  removeUserDiscoverServiceEvent,
  createUserContactCenterServiceEvent,
  removeUserContactCenterServiceEvent,
  createUserSMSServiceEvent,
  removeUserSMSServiceEvent,
  createPhoneLineServiceEvent,
  //removePhoneLineService, we don't do this... yet
  createUserEvent,
  deactivateUserEvent,
  deleteUserEvent,
  bulkCreateUsersEvent,
  upgradeUserServicesEvent,
  createUtilityServiceEvent,
  removeUtilityServiceEvent,
  createTeamsDirectRoutingServiceEvent,
  removeTeamsDirectRoutingServiceEvent
]

export const createHardwareOrderEvent = "hardware-order-create"

export const hardwareActivityEventTypes = [createHardwareOrderEvent]
