import { lazy } from "react"

import { Switch } from "routing"

import { ProtectedHubLayoutRoute } from "components/layouts/HubLayout"

import { createUtilityPhonesPath, utilityPhonesPath } from "./Paths"

import permissions from "constants/permissions"
import PermissionsProtected from "components/permissions/PermissionsProtected"
import { ALLOY_UTILITY_SKU } from "constants/skus"
import NoContractedProducts from "components/NoContractedProducts/NoContractedProducts"
import AlloyProtected from "components/permissions/AlloyProtected"

const NewUtility = lazy(() => import("./NewUtility/NewUtility"))
const UtilityList = lazy(() => import("./UtilityList/UtilityList"))

export const EditUtilityAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.utility.edit]}>{children}</PermissionsProtected>
)

const Routes = () => {
  return (
    <Switch>
      <AlloyProtected
        requiresSku={true}
        sku={ALLOY_UTILITY_SKU}
        fallback={<NoContractedProducts />}
        path={createUtilityPhonesPath}
      >
        <ProtectedHubLayoutRoute exact path={createUtilityPhonesPath} Component={NewUtility} />
      </AlloyProtected>
      <AlloyProtected
        requiresSku={true}
        sku={ALLOY_UTILITY_SKU}
        fallback={<NoContractedProducts />}
        path={utilityPhonesPath}
      >
        <ProtectedHubLayoutRoute exact path={utilityPhonesPath} Component={UtilityList} />
      </AlloyProtected>
    </Switch>
  )
}

export default Routes
