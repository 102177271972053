import { lazy } from "react"

import { ProtectedNoSidebarLayoutRoute } from "components/layouts/HubLayout"

const Onboarding = lazy(() => import("./Onboarding"))
const Welcome = lazy(() => import("./Welcome"))

const Paths = Object.freeze({
  Onboarding: "/onboarding",
  Welcome: "/welcome"
})

const OnboardingRoutes = () => (
  <>
    <ProtectedNoSidebarLayoutRoute exact path={Paths.Onboarding} Component={Onboarding} />
    <ProtectedNoSidebarLayoutRoute exact path={Paths.Welcome} Component={Welcome} />
  </>
)

export default OnboardingRoutes
