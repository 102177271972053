import { connect } from "react-redux"
import {
  loadOrders,
  loadUsers,
  loadDepartments,
  loadLocations,
  loadCurrencies,
  loadServices,
  initEventFilterTypes,
  initUserFilterTypes,
  initDateRangeFilterTypes,
  selectEventFilter,
  selectDateRangeFilter,
  selectUserFilter,
  loadJobs,
  loadProducts,
  tabSelection,
  loadFloppyProducts,
  loadExtensions
} from "./actionTypes"
import _unionBy from "lodash/unionBy"
import _cloneDeep from "lodash/cloneDeep"
import _find from "lodash/find"
import _isEmpty from "lodash/isEmpty"
import { getServiceLocationFromOrder } from "../utils"
import { calcCostSelector } from "./selectors"
import { getFullUserName } from "lib/string-utilities"

const reducer = (state = {}, action) => {
  switch (action.type) {
    case loadOrders.types.start:
      return {
        ...state,
        ordersLoading: true
      }
    case loadOrders.types.success:
      const { orders, pagination } = action.results
      orders.forEach(order => (order.totalCost = calcCostSelector(order)))
      return {
        ...state,
        orders: _unionBy(state.orders, orders, "uowId"),
        ordersLoading: orders.length || !pagination.hasMore ? false : state.ordersLoading,
        pagination
      }
    case loadUsers.types.success:
      const { users } = action.results
      const allUsers = _unionBy(state.users, users, "userName")

      // updating completed by filter with full user names labels (initially those are userName properties that we get from the api)
      const updatedUserFilterTypes = state.userFilterTypes
        ? state.userFilterTypes.map(userFilterType => {
            if (!userFilterType.value) {
              return userFilterType
            } else {
              const userObject = _find(allUsers, { userName: userFilterType.value })
              return {
                value: userFilterType.value,
                label: userObject ? getFullUserName(userObject) : userFilterType.label
              }
            }
          })
        : []
      return {
        ...state,
        users: allUsers,
        userFilterTypes: updatedUserFilterTypes
      }
    case loadDepartments.types.success:
      const { departments } = action.results
      return {
        ...state,
        departments: _unionBy(state.departments, departments, "id")
      }
    case loadLocations.types.success:
      const { locations } = action.results
      return {
        ...state,
        locations: _unionBy(state.locations, locations, "id")
      }
    case loadCurrencies.types.success:
      const { currencies } = action.results
      const ordersWithCurrencies = _cloneDeep(state.orders)
      ordersWithCurrencies.forEach(
        order => (order.currency = order.currency ? order.currency : currencies[getServiceLocationFromOrder(order)])
      )
      return {
        ...state,
        currencies,
        orders: ordersWithCurrencies
      }
    case loadServices.types.success:
      const { services } = action.results
      return {
        ...state,
        services: _unionBy(state.services, services, "id")
      }
    case loadProducts.types.success:
      const { products } = action.results
      return {
        ...state,
        products: _unionBy(state.products, products, "sku")
      }
    case loadJobs.types.success:
      const { jobs } = action.results
      // filter out jobs that don't have metadata
      const relevantJobs = jobs.filter(job => !_isEmpty(job.meta))

      return {
        ...state,
        jobs: _unionBy(state.jobs, relevantJobs, "unitOfWorkId")
      }
    case loadExtensions.types.success:
      const { extensions } = action.results
      return {
        ...state,
        extensions
      }
    case initEventFilterTypes.type:
      return {
        ...state,
        eventFilterTypes: action.options,
        selectedEventFilter: action.options[0]
      }
    case initUserFilterTypes.type:
      return {
        ...state,
        userFilterTypes: action.options,
        selectedUserFilter: action.options[0]
      }
    case initDateRangeFilterTypes.type:
      return {
        ...state,
        dateRangeFilterTypes: action.options,
        selectedDateRangeFilter: action.options[0]
      }
    case selectEventFilter.type:
      return {
        ...state,
        orders: [],
        selectedEventFilter: action.selection
      }
    case selectUserFilter.type:
      return {
        ...state,
        orders: [],
        selectedUserFilter: action.selection
      }
    case selectDateRangeFilter.type:
      return {
        ...state,
        orders: [],
        selectedDateRangeFilter: action.selection
      }
    case tabSelection.type:
      return {
        ...state,
        tab: action.tab,
        // resetting filters and pagination
        pagination: { hasMore: false },
        selectedDateRangeFilter: state.dateRangeFilterTypes && state.dateRangeFilterTypes[0],
        // user filter is a special case: it is initialized late in the app state, when we get a response from the api
        selectedUserFilter: { value: "showAll", label: "All users" },
        selectedEventFilter: state.eventFilterTypes && state.eventFilterTypes[0],
        orders: []
      }
    case loadFloppyProducts.types.success:
      return {
        ...state,
        floppyProducts: action.results
      }
    default:
      return state
  }
}

export default reducer

// modifies the behaviour of react-redux connect to have only the relevant portion of the state available to map functions
function OrdersConnect(mapStateToProps, mapDispatchToProps, mergeProps, options = {}) {
  return connect(
    state => (mapStateToProps ? mapStateToProps(state.Orders) : {}),
    mapDispatchToProps,
    mergeProps,
    options
  )
}

export { OrdersConnect as connect }
