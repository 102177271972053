import _isEmpty from "lodash/isEmpty"
import _curryRight from "lodash/curryRight"

import {
  consolidateUser,
  getEntity,
  getJobFromState,
  getProductFromEvent,
  getLocationFromState,
  getUserFromEvent,
  getStandardEventProperties,
  getPhoneNumberFromEvent,
  getExtensionFromEvent,
  getServiceFromState
} from "../utils"

import { isVideoProduct, isVoiceProduct } from "data/products"

export const hardwareOrder = (event, normalizedState) => {
  const completedBy =
    normalizedState.entities.users && normalizedState.entities.users[event.createdBy]
      ? consolidateUser(normalizedState.entities.users[event.createdBy], normalizedState)
      : {}

  const hardwareOrder = getEntity(event, "hubOrderHardware")
  const shippingLocation =
    !_isEmpty(hardwareOrder) &&
    hardwareOrder.cur.shippingLocationId &&
    normalizedState.entities.locations &&
    normalizedState.entities.locations[hardwareOrder.cur.shippingLocationId]
      ? normalizedState.entities.locations[hardwareOrder.cur.shippingLocationId]
      : {}
  const billingLocation =
    !_isEmpty(hardwareOrder) &&
    hardwareOrder.cur.shippingLocationId &&
    normalizedState.entities.locations &&
    normalizedState.entities.locations[hardwareOrder.cur.billingLocationId]
      ? normalizedState.entities.locations[hardwareOrder.cur.billingLocationId]
      : {}

  const product =
    !_isEmpty(hardwareOrder) &&
    hardwareOrder.cur.productId &&
    normalizedState.entities.products &&
    Object.values(normalizedState.entities.products).find(product => product.id === hardwareOrder.cur.productId)
  return {
    action: event.action,
    completedBy: completedBy,
    loadDate: event.loadDate,
    order: {
      poNumber: !_isEmpty(hardwareOrder) && hardwareOrder.cur.poNumber,
      quantity: !_isEmpty(hardwareOrder) && hardwareOrder.cur.quantity,
      shippingLocation: shippingLocation.name,
      billingLocation: billingLocation.name,
      contactName: !_isEmpty(hardwareOrder) && hardwareOrder.cur.contactName,
      contactPhoneNumber: !_isEmpty(hardwareOrder) && hardwareOrder.cur.contactPhoneNumber,
      contactEmail: !_isEmpty(hardwareOrder) && hardwareOrder.cur.email,
      model: !_isEmpty(product) && product.name,
      brand: !_isEmpty(product) && product.brand,
      sku: !_isEmpty(product) && product.sku
    },
    billingImpact: !_isEmpty(hardwareOrder) && event.totalCost * hardwareOrder.cur.quantity,
    currency: event.currency,
    uowId: event.uowId
  }
}

export const serviceUpgrade = (event, state) => {
  const createdService = getEntity(event, "service", "CREATE")
  const deletedService = getEntity(event, "service", "DELETE")
  // todo remove upgradedService when the data is fixed
  const upgradedService = getEntity(event, "service", "UPDATE")
  let targetUserId
  if (!_isEmpty(createdService)) {
    targetUserId = createdService?.cur?.userId
  } else if (!_isEmpty(deletedService)) {
    targetUserId = deletedService?.prev?.userId
  } else {
    const fullService =
      state.entities.services && upgradedService && state.entities.services[upgradedService.cur.serviceId]
    targetUserId = fullService && fullService?.user?.id
  }
  let targetUser =
    state.entities.users && targetUserId && Object.values(state.entities.users).find(user => user.id === targetUserId)
  targetUser = targetUser ? consolidateUser(targetUser, state) : {}

  // retrieving different services to figure out the operation that caused this data
  const createdProduct =
    !_isEmpty(createdService) &&
    state.entities.products &&
    Object.values(state.entities.products).find(product => product.id === createdService.cur.productId)
  const deletedProduct =
    !_isEmpty(deletedService) &&
    state.entities.products &&
    Object.values(state.entities.products).find(product => product.id === deletedService.prev.productId)
  const updatedProduct =
    !_isEmpty(upgradedService) &&
    state.entities.products &&
    Object.values(state.entities.products).find(product => product.id === upgradedService.cur.productId)
  const originalProduct =
    !_isEmpty(upgradedService) &&
    state.entities.products &&
    Object.values(state.entities.products).find(product => product.id === upgradedService.prev.productId)

  let originalService,
    updatedService,
    servicesAffected = []
  if (!createdProduct && deletedProduct) {
    if (isVoiceProduct(deletedProduct, state.floppyProducts)) {
      originalService = { messageId: "serviceUpgrade.voiceAndCollab", details: deletedProduct.name }
      updatedService = { messageId: "serviceUpgrade.collab" }
      servicesAffected = ["serviceUpgrade.callingPlan"]
    }
  } else if (createdProduct && deletedProduct) {
    if (isVoiceProduct(createdProduct, state.floppyProducts) && isVideoProduct(deletedProduct, state.floppyProducts)) {
      originalService = { messageId: "serviceUpgrade.collab", details: deletedProduct.name }
      updatedService = { messageId: "serviceUpgrade.voiceAndCollab", details: createdProduct.name }
      servicesAffected = ["serviceUpgrade.callingPlan"]
    }
  } else if (createdProduct && !deletedProduct) {
    const didCreateMultipleServices = event.entities.map(entity => entity.type === "CREATE").length >= 2
    const isAnyCreatedServiceVideoProduct = event.entities.filter(entity => {
      const product =
        state.entities.products &&
        Object.values(state.entities.products).find(product => product.id === entity.cur.productId)
      return entity.type === "CREATE" && isVideoProduct(product, state.floppyProducts)
    }).length
    if (isAnyCreatedServiceVideoProduct) {
      if (didCreateMultipleServices) {
        originalService = { messageId: "serviceUpgrade.none" }
        updatedService = { messageId: "serviceUpgrade.collab", details: createdProduct.name }
        servicesAffected = ["serviceUpgrade.callingPlan"]
      } else {
        originalService = { messageId: "serviceUpgrade.none" }
        updatedService = { messageId: "serviceUpgrade.voiceAndCollab", details: createdProduct.name }
        servicesAffected = ["serviceUpgrade.callingPlan"]
      }
    } else {
      // a case when a user didn't have any service and was upgraded to voice
      originalService = { messageId: "serviceUpgrade.none" }
      updatedService = { messageId: "serviceUpgrade.calling", details: createdProduct.name }
      servicesAffected = ["serviceUpgrade.callingPlan"]
    }
  } else if (originalProduct && updatedProduct) {
    originalService = { details: originalProduct.name }
    updatedService = { details: updatedProduct.name }
    servicesAffected = ["serviceUpgrade.callingPlan"]
  }

  return {
    ...getStandardEventProperties(event, state),
    action: event.action,
    targetUser,
    originalService,
    updatedService,
    servicesAffected
  }
}

export const didService = (event, normalizedState) => {
  const serviceEntity = getEntity(event, "service")["cur"]

  const product = getProductFromEvent(event, normalizedState)
  const did = getPhoneNumberFromEvent(event, normalizedState)

  const service = getServiceFromState(normalizedState, serviceEntity?.serviceId)
  const location = getLocationFromState(normalizedState, service?.location?.id)

  return {
    ...getStandardEventProperties(event, normalizedState),
    lineDetails: { did, location: location?.name, plan: product?.name }
  }
}

function userService(event, normalizedState, servicesAffected, isRemovingService) {
  const targetUser = getUserFromEvent(event, normalizedState)
  const product = getProductFromEvent(event, normalizedState)

  return {
    ...getStandardEventProperties(event, normalizedState, isRemovingService),
    servicesAffected,
    targetUser,
    product,
    isRemovingService
  }
}

function userServiceWithPhoneNumber(event, normalizedState, servicesAffected, isRemovingService) {
  const did = getPhoneNumberFromEvent(event, normalizedState)
  const extension = getExtensionFromEvent(event, normalizedState)

  return {
    ...userService(event, normalizedState, servicesAffected, isRemovingService),
    did,
    extension,
    isRemovingService
  }
}

/**
 * A note on currying
 *
 * Currying is a way of pre-setting some of the parameters of a function. It's one of those tools in the function commposition toolbox.
 *
 * A very contrived example would be if you had a function:
 *
 * function multiply(x, y) { return x * y }
 *
 * but you found that you were often multiplying by 2, you could create a new function
 *
 * const multiplyBy2 = _.curry(multiply)(2)
 *
 * So you're supplying the first parameter
 *
 * In a more practical example (selector composition), we want our selector to get called like this:
 *
 * selector(event, normalizedState)
 *
 * If we wanted to write a selector for fax service, we could do that with one version for adding and one for removing:
 *
 * function createUserFaxServiceSelector(event, normalizedState) { ... }
 * function deleteUserFaxServiceSelector(event, normalizedState) { ... }
 *
 * But it turns out that fax and phone number are very similar, so we could instead write:
 *
 * function userServiceWithPhoneNumber(event, normalizedState, servicesAffected) { ... }
 *
 * and then rewrite the fax selectors as:
 *
 * function createUserFaxServiceSelector(event, normalizedState) {
 *  return {
 *   ...userServiceWithPhoneNumber(event, normalizedState, "fax.service"),
 *   isRemovingService: false
 *  }
 * }
 *
 * and then another one for deleting a fax service.
 *
 * I felt like that was a lot of boilerplate, so we start by converting the shared selector to accept affected services and an isRemovingService flag
 *
 * function userServiceWithPhoneNumber(event, normalizedState, servicesAffected, isRemovingService)
 *
 * and then we could curry this function.
 *
 * const createUserFaxServiceSelector = _curryRight(userServiceWithPhoneNumber)("fax.service", false)
 * const deleteUserFaxServiceSelector = _curryRight(userServiceWithPhoneNumber)("fax.service", true)
 *
 * Finally, for one more bit of reuse, I created a function that templates this:
 *
 * function createCreateAndDeleteSelectorsWithVoice(servicesAffected) {
 *  const createSelector = curriedUserServiceWithPhoneNumber(servicesAffected, false)
 *  const deleteSelector = curriedUserServiceWithPhoneNumber(servicesAffected, true)
 *
 *  return [createSelector, deleteSelector]
 * }
 *
 * And now we can get to our final form:
 *
 * const [createUserFaxServiceSelector, deleteUserFaxServiceSelector] = createCreateAndDeleteSelectorsWithVoice("fax.service")
 */

const curriedUserService = _curryRight(userService)
const curriedUserServiceWithPhoneNumber = _curryRight(userServiceWithPhoneNumber)

function createCreateAndDeleteSelectorsWithVoice(servicesAffected) {
  const createSelector = curriedUserServiceWithPhoneNumber(servicesAffected, false)
  const deleteSelector = curriedUserServiceWithPhoneNumber(servicesAffected, true)

  return [createSelector, deleteSelector]
}

function createCreateAndDeleteSelectors(servicesAffected) {
  const createSelector = curriedUserService([servicesAffected], false)
  const deactivateSelector = curriedUserService([servicesAffected], true)
  const deleteSelector = curriedUserService([servicesAffected], true)

  return [createSelector, deactivateSelector, deleteSelector]
}

const [createUserPhoneNumberSelector, deleteUserPhoneNumberSelector] = createCreateAndDeleteSelectorsWithVoice([
  "services.didAddon"
])
export { createUserPhoneNumberSelector, deleteUserPhoneNumberSelector }

const [createUserFaxSelector, deleteUserFaxSelector] = createCreateAndDeleteSelectorsWithVoice(["services.faxService"])
export { createUserFaxSelector, deleteUserFaxSelector }

const [createUserContactCenterSelector, deleteUserContactCenterSelector] = createCreateAndDeleteSelectors([
  "services.contactCenterService"
])
export { createUserContactCenterSelector, deleteUserContactCenterSelector }

const [createUserDiscoverSelector, deleteUserDiscoverSelector] = createCreateAndDeleteSelectors([
  "services.discoveryService"
])
export { createUserDiscoverSelector, deleteUserDiscoverSelector }

const [createUserSMSSelector, deleteUserSMSSelector] = createCreateAndDeleteSelectors(["services.smsService"])
export { createUserSMSSelector, deleteUserSMSSelector }

const [createUserRecordingSelector, deleteUserRecordingSelector] = createCreateAndDeleteSelectors([
  "services.recordingService"
])
export { createUserRecordingSelector, deleteUserRecordingSelector }

const [createUserSelector, deactivateUserSelector, deleteUserSelector] = createCreateAndDeleteSelectors(undefined)
export { createUserSelector, deactivateUserSelector, deleteUserSelector }

const [createTeamsDirectRoutingSelector, removeTeamsDirectRoutingSelector] = createCreateAndDeleteSelectors([
  "services.teamsDirectRouting"
])
export { createTeamsDirectRoutingSelector, removeTeamsDirectRoutingSelector }

export const bulkCreateUsersSelector = (event, normalizedState) => {
  const job = getJobFromState(normalizedState, event.uowId)

  return {
    ...getStandardEventProperties(event, normalizedState),
    job
  }
}

export const utilitySelector = (event, normalizedState, isRemovingService) => {
  const did = getPhoneNumberFromEvent(event, normalizedState)
  const product = getProductFromEvent(event, normalizedState)

  const service = getEntity(event, "service")["cur"]

  const location = getLocationFromState(normalizedState, service.locationId)

  return {
    ...getStandardEventProperties(event, normalizedState),
    isRemovingService,
    lineDetails: { did, location: location?.name, plan: product?.name }
  }
}
