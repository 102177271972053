import {
  uploadFileAction,
  removeFileAction,
  userLoadActions,
  downloadFileActions,
  fileValidationAction,
  foundryValidationActions,
  departmentCheckActions,
  departmentCreationActions,
  provisioningActions,
  sendNotificationAction,
  invoicingStartDateAction,
  setUnitOfWorkTokenAction,
  getPermissionsActions,
  resetStateAction,
  selectLocationAction,
  selectServiceBundleAction,
  setVideoProductAction,
  setVoiceProductAction
} from "../actionTypes"

const areUsersValid = users => {
  return users.filter(user => user.errors && user.errors.length > 0).length > 0
}

const initialState = { sendNotificationNow: true }

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case resetStateAction.type: {
      return initialState
    }
    case selectLocationAction.type: {
      return {
        selectedLocation: action.selectedLocation
      }
    }
    case selectServiceBundleAction.type: {
      return {
        ...state,
        selectedBundle: action.selectedBundle,
        voiceProduct: null,
        videoProduct: action.selectedBundle === "voice" ? null : state.videoProduct
      }
    }
    case setVoiceProductAction.type: {
      return {
        ...state,
        voiceProduct: action.voiceProduct
      }
    }
    case setVideoProductAction.type: {
      return {
        ...state,
        videoProduct: action.videoProduct
      }
    }
    case downloadFileActions.types.start:
      return {
        ...state,
        downloadingFile: true
      }
    case downloadFileActions.types.success:
      return {
        ...state,
        downloadingFile: false,
        fileDownloaded: true
      }
    case downloadFileActions.types.failure:
      return {
        ...state,
        downloadingFile: false,
        errorMessage: action.err
      }
    case uploadFileAction.type:
      return {
        ...state,
        fileValidationErrors: [],
        topLevelValidationErrors: [],
        users: [],
        file: action.file
      }
    case removeFileAction.type:
      return {
        ...state,
        fileValidationErrors: [],
        topLevelValidationErrors: [],
        cumulativeErrors: undefined,
        users: [],
        file: undefined
      }
    case fileValidationAction.type:
      const { error } = action
      return {
        ...state,
        fileValidationErrors: [error],
        cumulativeErrors: (state.cumulativeErrors || 0) + 1
      }
    case userLoadActions.types.start:
      return {
        ...state,
        usersLoading: true
      }
    case userLoadActions.types.success:
      return {
        ...state,
        usersLoading: false,
        users: action.results
      }
    case foundryValidationActions.types.start:
      return {
        ...state,
        fileValidationErrors: [],
        topLevelValidationErrors: [],
        cumulativeErrors: undefined,
        validating: true
      }
    case foundryValidationActions.types.success:
      const usersAreValid = areUsersValid(action.results)

      return {
        ...state,
        validating: false,
        users: action.results,
        cumulativeErrors: (state.cumulativeErrors || 0) + (usersAreValid ? 1 : 0)
      }
    case foundryValidationActions.types.failure:
      const errors = action.err

      return {
        ...state,
        validating: false,
        topLevelValidationErrors: errors
      }
    case departmentCheckActions.types.start:
      return {
        ...state,
        checkingDepartments: true
      }
    case departmentCheckActions.types.success:
      const { newDepartments, users } = action.results
      return {
        ...state,
        checkingDepartments: false,
        newDepartments,
        users
      }
    case departmentCreationActions.types.start:
      return {
        ...state,
        creatingDepartments: true
      }
    case departmentCreationActions.types.success:
      return {
        ...state,
        creatingDepartments: false,
        departmentsCreated: true,
        newDepartments: [],
        users: action.results
      }
    case departmentCreationActions.types.failure:
      return {
        ...state,
        departmentCreationErrors: action.err,
        creatingDepartments: false
      }
    case provisioningActions.types.start:
      return {
        ...state,
        isProvisioning: true
      }
    case provisioningActions.types.success:
      return {
        ...state,
        isProvisioning: false
      }
    case provisioningActions.types.failure:
      return {
        ...state,
        isProvisioning: false,
        provisioningError: true
      }
    case sendNotificationAction.type:
      return {
        ...state,
        sendNotificationNow: action.sendNotificationNow
      }
    case invoicingStartDateAction.type:
      return {
        ...state,
        invoicingStartDate: action.invoicingStartDate
      }
    case setUnitOfWorkTokenAction.type:
      return {
        ...state,
        scribeToken: action.scribeToken
      }
    case getPermissionsActions.types.start:
      return {
        ...state,
        permissions: []
      }
    case getPermissionsActions.types.success:
      return {
        ...state,
        permissions: action.results
      }
    default:
      return state
  }
}

export default reducer
