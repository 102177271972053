import _compact from "lodash/compact"
import _cloneDeep from "lodash/cloneDeep"
import _isEmpty from "lodash/isEmpty"
import { parseDidToPhoneNumber } from "lib/phone-number-utilities"

export const printPlusOrMinus = number => (number > 0 ? "+ " : number < 0 ? "- " : null)

export const getServiceLocationFromOrder = order =>
  _compact(
    order.entities.map(entity => {
      if (entity.entityType === "service") {
        return entity.cur.locationId
      } else if (entity.entityType === "hubOrderHardware") {
        return entity.cur.billingLocationId
      } else {
        return null
      }
    })
  )[0]

export const eventTypeToFilterConverter = (eventType, intl) => {
  return intl.formatMessage({ id: `events.${eventType}` })
}

// find the first entity in event that has the same entityType (aka service, user, extension)
// and the same type [optional] (ie CREATE, DELETE, UPDATE)
export const getEntity = (event, entityType, type) => {
  return (
    event.entities.find(entity =>
      type ? entity.entityType === entityType && entity.type === type : entity.entityType === entityType
    ) || {}
  )
}

export function getUserFromStateByUsername(state, userName) {
  return state.entities.users && state.entities.users[userName]
    ? consolidateUser(state.entities.users[userName], state)
    : {}
}

export function getUserFromStateById(state, userId) {
  return state.entities.usersById && state.entities.usersById[userId]
    ? consolidateUser(state.entities.usersById[userId], state)
    : {}
}

export function getProductFromState(state, sku) {
  return state.entities.products && state.entities.products[sku]
}

export function getProductFromStateById(state, productId) {
  return state.entities.productsById && state.entities.productsById[productId]
}

export function getServiceFromState(state, serviceId) {
  return state.entities.services ? state.entities.services[serviceId] : { location: {} }
}

export function getLocationFromState(state, locationId) {
  return state.entities.locations && state.entities.locations[locationId] ? state.entities.locations[locationId] : {}
}

export function getDepartmentFromState(state, departmentId) {
  return state.entities.departments && state.entities.departments[departmentId]
    ? state.entities.departments[departmentId]
    : {}
}

export function getJobFromState(state, unitOfWorkId) {
  return state.entities.jobs && state.entities.jobs[unitOfWorkId] ? state.entities.jobs[unitOfWorkId] : { meta: {} }
}

export function getUserIdForService(service) {
  if (service?.cur?.userId) {
    return service.cur.userId
  }
  if (service?.prev?.userId) {
    return service.prev.userId
  }
  return undefined
}

export const consolidateUser = (normalizedUser, normalizedState) => {
  const modifiedUser = _cloneDeep(normalizedUser || {})
  modifiedUser.location = getLocationFromState(normalizedState, normalizedUser?.location)
  modifiedUser.department = getDepartmentFromState(normalizedState, normalizedUser?.department)
  return modifiedUser
}

export function getPhoneNumberFromExtension(extension) {
  // remove a leading plus sign if there is one
  return !_isEmpty(extension) ? `(${parseDidToPhoneNumber(extension.cur.did)})` : undefined
}

export function getExtensionCodeFromExtension(extension, state) {
  const serviceId = extension?.cur?.serviceId
  const code = serviceId && state.entities.extensions && state.entities.extensions[serviceId]
  return code
}

export function pickUser(user1, user2) {
  return !_isEmpty(user1) ? user1 : user2
}

export function getUserFromEvent(event, state) {
  // Note that when events come back from butler, the user object will have a userName property but not an id
  const currentUser = getEntity(event, "user")["cur"]
  const service = getEntity(event, "service")

  const eventUser = getUserFromStateByUsername(state, currentUser?.userName)
  const serviceUser = getUserFromStateById(state, getUserIdForService(service))

  const result = pickUser(eventUser, serviceUser)

  if (Object.keys(result).length > 0) {
    return result
  }

  return event.entities.map(({ cur }) => cur).filter(({ userName }) => userName)[0]
}

export function getProductFromEvent(event, state) {
  const serviceEntity = getEntity(event, "service")["cur"]
  const product = getProductFromStateById(state, serviceEntity?.productId)
  return product
}

export function getAdminFromEvent(event, normalizedState) {
  return getUserFromStateByUsername(normalizedState, event?.createdBy)
}

export function getPhoneNumberFromEvent(event, normalizedState) {
  const extension = getEntity(event, "extension")
  const did = getPhoneNumberFromExtension(extension)
  return did
}

export function getExtensionFromEvent(event, normalizedState) {
  const extension = getEntity(event, "extension")
  const code = getExtensionCodeFromExtension(extension, normalizedState)
  return code
}

export function getStandardEventProperties(event, normalizedState, isRemovingService = false) {
  const completedBy = getAdminFromEvent(event, normalizedState)

  return {
    completedBy,
    action: event?.action,
    loadDate: event.loadDate,
    billingImpact: !isRemovingService ? event.totalCost : undefined,
    currency: event.currency,
    uowId: event.uowId
  }
}

export const hardwareTableActions = ["hardware-order-create"]
export const activityTableEntityTypes = ["configService", "service", "extension", "user"]
export const hardwareTableEntityTypes = ["hubOrder", "hubOrderHardware"]
