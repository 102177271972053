import React from "react"
import { Button } from "reactstrap"
import { FormattedMessage } from "react-intl"
import BasicHubLayout from "components/layouts/BasicHubLayout"
import ContactSupportInline from "components/support/ContactSupportInline"
import ErrorCover from "components/layouts/components/ErrorCover"
import ErrorCoverTopBar from "components/layouts/components/ErrorCoverTopBar"
import useLogout from "components/authentication/useLogout"

export function isSuspended(organization) {
  return organization.operationalStatus === "SUSPENDED" || organization.operationalStatus === "TERMINATED"
}

export function ActingOrganizationIsSuspended() {
  return (
    <BasicHubLayout>
      <ErrorCover
        description={
          <FormattedMessage
            id="errors.actingOrganizationIsSuspended.description"
            values={{
              contactSupport: (
                <strong>
                  <ContactSupportInline />
                </strong>
              )
            }}
          />
        }
        title={<FormattedMessage id="errors.actingOrganizationIsSuspended.title" />}
      />
    </BasicHubLayout>
  )
}

export default function YourOrganizationIsSuspended() {
  const logout = useLogout()
  return (
    <>
      <ErrorCoverTopBar
        action={
          <Button onClick={logout}>
            <FormattedMessage id="header.logout" />
          </Button>
        }
      />
      <ErrorCover
        description={
          <FormattedMessage
            id="errors.yourOrganizationIsSuspended.description"
            values={{
              contactSupport: <ContactSupportInline />
            }}
          />
        }
        title={<FormattedMessage id="errors.yourOrganizationIsSuspended.title" />}
      />
    </>
  )
}
