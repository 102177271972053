// shared path for all settings
const settingsPath = "/settings"

const desktopUpdatesPath = "/settings/desktop-updates"

const guestsPath = "/guests"
const guestPolicyPath = "/settings/guests"
const activeGuestsPath = "/guests/active"
const deactivatedGuestsPath = "/guests/deactivated"
const guestActionPath = "/guests/:guestId/:actionId"

const buildGuestsActionPath = (guestId, actionId) => `/guests/${guestId}/${actionId}`

const notificationPolicyPath = "/settings/notifications"
const identityManagementPath = "/settings/identity-management"
const rostersVisibilityPath = "/settings/contact-rosters"
const actOnListVisibilityPath = "/settings/partner-management"

export {
  settingsPath,
  desktopUpdatesPath,
  guestPolicyPath,
  guestsPath,
  activeGuestsPath,
  deactivatedGuestsPath,
  guestActionPath,
  buildGuestsActionPath,
  notificationPolicyPath,
  identityManagementPath,
  rostersVisibilityPath,
  actOnListVisibilityPath
}
