import _flow from "lodash/flow"

import _createDepartment from "@fuze/apis-foundry/dist/departments/functions/createDepartment"
import _searchDepartments from "@fuze/apis-foundry/dist/departments/functions/searchDepartments"
import _getDepartments from "@fuze/apis-foundry/dist/departments/functions/getDepartments"
import _updateDepartment from "@fuze/apis-foundry/dist/departments/functions/updateDepartment"

import {
  wrapFoundryFunction,
  wrapAllResults,
  wrapAllResultsWithIds,
  wrapFoundryFunctionWithOrganizationWithPagination
} from "./decorators"
import { cached } from "../utils"

export const createDepartment = wrapFoundryFunction(_createDepartment)
export const updateDepartment = wrapFoundryFunction(_updateDepartment)
export const getDepartments = (q, active) => wrapAllResults(_getDepartments)(q, active)
export const getDepartmentsList = (q, active, pagination) =>
  wrapFoundryFunctionWithOrganizationWithPagination(_getDepartments)(q, active, pagination)
export const getDepartmentsByIds = _flow(wrapAllResultsWithIds, cached)(_searchDepartments)
