import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classnames from "classnames"

interface ITableActions {
  breakpoint?: "xs" | "sm" | "md" | "lg" | "xl"
}

const TableActions: FuzzyFunctionalComponent<ITableActions> = ({ breakpoint, children, className, ...props }) => {
  return (
    <div
      className={classnames("fuze-table-actions", className, breakpoint && `fuze-table-actions--${breakpoint}`)}
      {...props}
    >
      {children}
    </div>
  )
}

export default TableActions
