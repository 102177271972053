import { getOrganization, getUser } from "lib/user-session"

/**
 * useIsActingOn
 * Return true if the user is acting on behalf: TPN user that acts on a different organization or aa partner admin that impersonates
 */
const useIsActingOn = () => {
  const actingOrganizations = getOrganization()
  const user = getUser()
  return user.organization.code !== actingOrganizations
}

export default useIsActingOn
