import React from "react"
import { Button } from "reactstrap"

const CancelButton = ({ children, onCancel, className }) => {
  return (
    <Button color="cancel" onClick={onCancel} className={className}>
      {children}
    </Button>
  )
}

export default CancelButton
