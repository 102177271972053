import classNames from "classnames"
import { Collapse } from "reactstrap"
import { NavLink } from "routing"
import HubSidebarSubmenuItem from "./HubSidebarSubmenuItem"
import _castArray from "lodash/castArray"
import { IconChevronDown, IconChevronUp } from "@fuze/icon-font"

const HubSidebarSubmenu = ({
  children,
  isSubmenuOpen,
  onSubmenuToggle,
  isActive,
  submenuName,
  isLoaded,
  toggleButtonTranslation,
  Icon
}) => {
  if (!isLoaded) return null

  const listItems = _castArray(children).filter(child => !!child)

  return (
    <li>
      <button
        id={submenuName}
        onClick={onSubmenuToggle}
        className={classNames({ active: isActive })}
        aria-expanded={isSubmenuOpen}
        aria-controls={`${submenuName}-submenu`}
      >
        {Icon && <Icon />}
        <span className="side-bar__label">{toggleButtonTranslation}</span>
        <span className={classNames("submenu-caret", { "submenu-caret--is-open": isSubmenuOpen })}>
          {isSubmenuOpen ? <IconChevronUp /> : <IconChevronDown />}
        </span>
      </button>
      <Collapse isOpen={isSubmenuOpen}>
        <ul id={`${submenuName}-submenu`} aria-hidden={isSubmenuOpen ? "false" : "true"} className="submenu">
          {listItems.map((child, index) => {
            const id = child?.props?.id

            if (!id) return null

            return (
              <HubSidebarSubmenuItem
                isSubmenuOpen={true}
                key={`${submenuName}-item-${id}`}
                zIndex={listItems.length - index}
              >
                {child}
              </HubSidebarSubmenuItem>
            )
          })}
        </ul>
      </Collapse>
    </li>
  )
}

function SidebarSubmenuItem({ to, isSubmenuOpen, children, ...props }) {
  return (
    <NavLink exact to={to} tabIndex={isSubmenuOpen ? "0" : "-1"} {...props}>
      <span className="link-text">{children}</span>
    </NavLink>
  )
}

export default HubSidebarSubmenu
export { SidebarSubmenuItem }
