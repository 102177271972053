import React from "react"
import AppErrorBoundary from "../Errors/AppErrorBoundary"
import useActingOrganization from "hooks/useActingOrganization"

import AlloyMigrationRoutes from "../AlloyMigration/Routes"
import DeviceRoutes from "../Devices/Routes"
import LegalRoutes from "../Legal/routes"
import BulkProvisioningRoutes from "../BulkProvision/Routes"
import OnboardingRoutes from "../onboarding/Routes"
import UserRoutes from "../users/Routes"
import LineRoutes from "../Lines/Routes"
import Orders from "../Orders/Routes"
import LocationRoutes from "../Locations/Routes"
import CompanySettingsRoutes from "../CompanySettings/Routes"
import UtilityRoutes from "../Utility/Routes"
import DashboardRoutes from "../Dashboard/Routes"
//import DIDRoutes from "DIDs/NumberRequests/Routes"
//import PortRequestsRoutes from "DIDs/PortRequests/Routes"
//import ToolboxRoutes from "DIDs/ToolboxRoutes"
//import RateCenterRoutes from "../RateCenter/Routes"
import ErrorsRoutes from "Errors/Routes"
import IntegrationsRoutes from "../Integrations/Routes"
import TroubleshootingRoutes from "../Troubleshooting/Routes"
import DepartmentRoutes from "Departments/Routes"

import "../styles/style.scss"
import PageViews from "lib/logging/PageViews"
import { Switch } from "routing"

import VersionUpdater from "./VersionUpdater"
import { ActingOrganizationIsSuspended, isSuspended } from "../Errors/SuspendedOrganization"
import PageSpinner from "components/spinner/PageSpinner"
import "focus-visible"
import AuthenticationUnauthorized from "components/AuthenticationUnauthorized/AuthenticationUnauthorized"
import { ToastContainer } from "react-toastify"
import ContactCenterRoutes from "../ContactCenter/Routes"
import { AudioLibraryRoutes } from "CallFlowManager/Routes"
import Redirect404 from "Errors/Redirect404"

function App() {
  const [organization, loading] = useActingOrganization()

  if (loading) {
    return <PageSpinner />
  }

  if (isSuspended(organization)) {
    return <ActingOrganizationIsSuspended />
  }

  return (
    <>
      <VersionUpdater />
      <ToastContainer containerId="toast-notifications-container" />
      <AppErrorBoundary>
        <Switch>
          <>
            <PageViews />
            <AlloyMigrationRoutes />
            <DeviceRoutes />
            <LegalRoutes />
            <BulkProvisioningRoutes />
            <OnboardingRoutes />
            <UserRoutes />
            <LineRoutes />
            <AudioLibraryRoutes />
            <Orders />
            <LocationRoutes />
            <CompanySettingsRoutes />
            <UtilityRoutes />
            <DashboardRoutes />
            {/* <DIDRoutes /> */}
            <IntegrationsRoutes />
            {/* <PortRequestsRoutes />
            <RateCenterRoutes />
            <ToolboxRoutes /> */}
            <DepartmentRoutes />
            <ErrorsRoutes />
            <TroubleshootingRoutes />
            <ContactCenterRoutes />
          </>
        </Switch>
      </AppErrorBoundary>
      <AuthenticationUnauthorized />
      <Redirect404 />
    </>
  )
}

export default App
