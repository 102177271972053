import React from "react"
import PropTypes from "prop-types"
import { Spinner } from "reactstrap"
import classNames from "classnames"
import { Button } from "reactstrap"

import "./LoadingButton.scss"

const LoadingButton = ({
  children,
  isLoading = false,
  color = "primary",
  classes,
  onClick,
  defaultIcon,
  loadingChildren,
  disabled,
  ...props
}) => {
  loadingChildren = loadingChildren || children
  return (
    <Button
      className={classNames(`btn-loading`, classes)}
      color={color}
      onClick={onClick}
      disabled={disabled || isLoading}
      type="button"
      {...props}
    >
      {isLoading ? (
        <>
          <Spinner size="sm" className={"mr-3"} />
          {loadingChildren}
        </>
      ) : (
        <>
          {defaultIcon && <span>{defaultIcon}</span>}
          <span>{children}</span>
        </>
      )}
    </Button>
  )
}

LoadingButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  isLoading: PropTypes.bool,
  color: PropTypes.string,
  classes: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  defaultIcon: PropTypes.element,
  loadingChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
}

export default LoadingButton
