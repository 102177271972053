import { registerUrlProvider, registerBearerTokenProvider, registerErrorHandlerProvider } from "@fuze/apis-sound"

import getConfiguration from "data/configuration"
import { getToken } from "lib/token"
import { errorHandler } from "../errorInterceptor"

export default function registerApiProviders() {
  registerUrlProvider(() => getConfiguration().then(data => data.sound.url))
  registerBearerTokenProvider(async () => getToken())
  registerErrorHandlerProvider(async () => errorHandler)
}
