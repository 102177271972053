import React, { useContext, useMemo } from "react"
import classNames from "classnames"
import { TooltipContext, tooltipEventTypes } from "./TooltipContainer"
import Button from "../Buttons/Button"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface ITooltipAnchor {}

interface IHandlers {
  [key: string]: () => null
}

const TooltipAnchor: FuzzyFunctionalComponent<ITooltipAnchor> = ({ className, ...props }) => {
  const tooltipContext = useContext(TooltipContext)

  if (!tooltipContext) {
    return null
  }

  const { id, method, toggle } = tooltipContext
  const handlers = useMemo(
    () =>
      tooltipEventTypes.reduce((accumulator: IHandlers, eventType: string) => {
        if (eventType === method) {
          accumulator[`${eventType}`] = () => toggle()
        }

        return accumulator
      }, {}),
    [method]
  )

  return (
    <Button className={classNames("fuze-tooltip__anchor")} color="gray" transparent {...props} {...handlers} id={id} />
  )
}

export default TooltipAnchor
