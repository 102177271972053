import { connect } from "react-redux"
import { createSelector } from "reselect"
import { track } from "data/analytics"
import { bindHandset, getHandset, getHandsets, getHardwareDetails, unbindHandset } from "data/apis/foundry"
// we will not be using common/user-fetcher APIs for this task, but rather PORTAL APIs
// so we can query by individual usernames
import { getDisplayUser } from "data/user-fetcher"
import { createAsyncActionTypes, createSyncActionType } from "ducks/utils"
import { getService } from "data/services"
import { buildUserProfilePath } from "users/Access"
import { createClaim } from "data/apis/deviceClaims"
import { logJavascriptError } from "lib/logging/logging"
import { getOrganization } from "lib/user-session"

// ES6 Maps don't work with redux => falling back to immutableJS
const { Map } = require("immutable")

const userLoadActions = createAsyncActionTypes("tailor", "portal", "LOAD")
const handsetStatisticsActions = createAsyncActionTypes("tailor", "deviceStatistics", "LOAD")
const deviceStatusActions = createAsyncActionTypes("tailor", "device", "LOAD")
const deviceAssignmentActions = createAsyncActionTypes("tailor", "device", "ASSIGN")
const deviceClaimingActions = createAsyncActionTypes("tailor", "device", "CLAIM")
const clearStateActions = createSyncActionType("tailor", "device", "CLEAR")

export const deviceStatusOptions = {
  ASSIGNED_TO_INACTIVE_USER: 0,
  ASSIGNED_TO_NUMBER: 1,
  ASSIGNED_TO_USER: 2,
  UNASSIGNED: 3,
  NOT_FOUND: 4,
  LEGACY_ADDON: 5
}

function reducer(state = {}, action = {}) {
  let devices, device
  switch (action.type) {
    case userLoadActions.types.success:
      const user = action.results
      return {
        ...state,
        users: [...(state.users || []), user]
      }

    case handsetStatisticsActions.types.start:
      return {
        ...state,
        deviceStatistics: {
          loading: true
        }
      }
    case handsetStatisticsActions.types.success:
      const handsets = action.results
      const statistics = calculateHandsetStatistics(handsets)
      return {
        ...state,
        deviceStatistics: {
          loading: false,
          ...statistics
        }
      }
    case deviceStatusActions.types.success:
      devices = state.devices || Map()
      devices = devices.set(action.results.macAddress, {
        ...action.results,
        apiRequestFailed: false,
        apiRequestIssued: false,
        apiRequestSuccessful: false,
        apiClaimSuccessful: false,
        invalidOrgCode: false
      })
      return {
        ...state,
        devices
      }
    case deviceStatusActions.types.failure:
      devices = state.devices || Map()
      devices = devices.set(action.err.macAddress, {
        ...action.results,
        apiRequestFailed: false,
        apiRequestIssued: false,
        apiRequestSuccessful: false,
        apiClaimSuccessful: false,
        invalidOrgCode: true
      })
      return {
        ...state,
        devices
      }
    case deviceAssignmentActions.types.start:
      devices = state.devices || Map()
      // reset the failed api flag
      device = devices.get(action.initialState.macAddress)
      devices = devices.set(action.initialState.macAddress, {
        ...device,
        apiRequestFailed: false,
        apiRequestIssued: true,
        apiRequestSuccessful: false,
        apiClaimSuccessful: false
      })
      return {
        ...state,
        devices
      }

    case deviceAssignmentActions.types.success:
      devices = state.devices || Map()
      device = devices.get(action.results.macAddress)
      devices = devices.set(action.results.macAddress, {
        ...device,
        apiRequestFailed: false,
        apiRequestIssued: false,
        apiRequestSuccessful: true,
        apiClaimSuccessful: false,
        assignedTo: action.results.userDetails
      })
      return {
        ...state,
        devices
      }
    case deviceAssignmentActions.types.failure:
      devices = state.devices || Map()
      device = devices.get(action.err.macAddress)
      devices = devices.set(action.err.macAddress, {
        ...device,
        apiRequestFailed: { isFailed: action.err.message },
        apiRequestIssued: false
      })
      return {
        ...state,
        devices
      }
    case deviceClaimingActions.types.start:
      devices = state.devices || Map()
      // reset the failed api flag
      device = devices.get(action.initialState.macAddress)
      devices = devices.set(action.initialState.macAddress, {
        ...device,
        apiRequestFailed: false,
        apiRequestIssued: true,
        apiRequestSuccessful: false,
        apiClaimSuccessful: false
      })
      return {
        ...state,
        devices
      }

    case deviceClaimingActions.types.success:
      devices = state.devices || Map()
      device = devices.get(action.results.macAddress)
      devices = devices.set(action.results.macAddress, {
        ...device,
        apiRequestFailed: false,
        apiRequestIssued: false,
        apiRequestSuccessful: false,
        apiClaimSuccessful: true
      })
      return {
        ...state,
        devices
      }
    case deviceClaimingActions.types.failure:
      devices = state.devices || Map()
      device = devices.get(action.err.macAddress)
      devices = devices.set(action.err.macAddress, {
        ...device,
        apiRequestFailed: { isFailed: action.err.message },
        apiRequestIssued: false,
        apiClaimSuccessful: false
      })
      return {
        ...state,
        devices
      }
    case clearStateActions.type:
      devices = state.devices || Map()
      devices = devices.set(action.macAddress, {
        apiRequestFailed: false,
        apiRequestIssued: false,
        apiRequestSuccessful: false,
        apiClaimSuccessful: false
      })
      return {
        ...state,
        devices
      }
    default:
      return state
  }
}

function calculateHandsetStatistics(handsets) {
  const total = handsets.length
  const assigned = handsets.filter(handset => !!handset.service).length
  const available = total - assigned
  return {
    total,
    assigned,
    available
  }
}

function retrieveHandsetStatistics() {
  return async dispatch => {
    dispatch(handsetStatisticsActions.start())
    try {
      const handsets = await getHandsets()
      dispatch(handsetStatisticsActions.success(handsets))
    } catch (err) {
      dispatch(handsetStatisticsActions.failure(err))
    }
  }
}

function assignDevice(userDetails, device, history) {
  return async dispatch => {
    const [voiceService] = userDetails.services.voiceServices
    try {
      dispatch(deviceAssignmentActions.start({ macAddress: device.macAddress }))
      // in case the device is bound to a service
      if (
        [
          deviceStatusOptions.ASSIGNED_TO_NUMBER,
          deviceStatusOptions.ASSIGNED_TO_INACTIVE_USER,
          deviceStatusOptions.ASSIGNED_TO_USER
        ].includes(device.ownership.status)
      ) {
        unbindHandset(device.macAddress)
          .then(handset => {
            if (!handset.service) {
              bindHandset(device.macAddress, voiceService.id).then(handset => {
                if (handset.service && handset.service.id === `${voiceService.id}`) {
                  dispatch(deviceAssignmentActions.success({ macAddress: device.macAddress }))
                } else {
                  throw new Error("Binding unsuccessful")
                }
                history.push(buildUserProfilePath(userDetails.username))
              })
            } else {
              throw new Error("Unbinding failed")
            }
          })
          .catch(err => {
            console.error(err)
            dispatch(deviceAssignmentActions.failure({ macAddress: device.macAddress, handled: true }))
          })
      } else {
        bindHandset(device.macAddress, voiceService.id)
          .then(handset => {
            track("Assigned a device")
            if (handset.service && handset.service.id === `${voiceService.id}`) {
              dispatch(
                deviceAssignmentActions.success({
                  macAddress: device.macAddress,
                  userDetails: getDisplayUser(userDetails)
                })
              )
            } else {
              throw new Error("Binding unsuccessful")
            }
          })
          .catch(err => {
            console.error(err)
            track("Failed to assign a device")
            dispatch(deviceAssignmentActions.failure({ macAddress: device.macAddress, handled: true }))
          })
      }
    } catch (err) {
      console.error(err)
      dispatch(deviceAssignmentActions.failure(err))
    }
  }
}

function claimDevice(handsetModel, deviceDetails) {
  return async dispatch => {
    const device = {
      partNumber: handsetModel?.partNumber,
      macAddress: deviceDetails?.macAddress
    }
    try {
      dispatch(deviceClaimingActions.start({ macAddress: device.macAddress }))
      await createClaim([device])
      dispatch(deviceClaimingActions.success({ macAddress: device.macAddress }))
    } catch (error) {
      await logJavascriptError("error on RegisterNewPhone handleSubmit", error)
      dispatch(deviceClaimingActions.failure({ macAddress: device.macAddress, message: error.message() }))
    }
  }
}

function retrieveDeviceStatus(macAddress) {
  return async dispatch => {
    dispatch(deviceStatusActions.start())
    const deviceMetadata = {}
    const data = await getHandset(macAddress)

    // If the device is found but belongs to another customer, show an error popup instead of a modal
    if (data?.status !== 1006 && data?.organization?.code !== getOrganization()) {
      dispatch(deviceStatusActions.failure({ macAddress }))
    } else {
      // 1006 indicates a device not found error
      if (data?.status === 1006 || !data?.hardware?.id) {
        dispatch(
          deviceStatusActions.success({
            macAddress,
            ownership: { status: deviceStatusOptions.NOT_FOUND }
          })
        )
      } else {
        deviceMetadata.data = data
        deviceMetadata.macAddress = macAddress
        deviceMetadata.hardware = await getHardwareDetails(data.hardware.id)

        if (!data.service) {
          deviceMetadata.ownership = { status: deviceStatusOptions.UNASSIGNED }
        } else {
          deviceMetadata.service = await getService(data.service.id)
          deviceMetadata.ownership = await deviceStatus(deviceMetadata)
        }
        dispatch(deviceStatusActions.success(deviceMetadata))
      }
    }
  }
}

function clearState(macAddress) {
  return async dispatch => dispatch(clearStateActions.fn({ macAddress }))
}

async function deviceStatus(deviceData) {
  const { service } = deviceData
  let ownership = {}

  if (service.user) {
    ownership.owner = service.user
    ownership.status = service.user.active
      ? deviceStatusOptions.ASSIGNED_TO_USER
      : deviceStatusOptions.ASSIGNED_TO_INACTIVE_USER
  } else {
    // a legacy addon service is considered to be a service that is not assigned to a user and doesn't have an extension of it's own
    ownership.status = !!service.extension ? deviceStatusOptions.ASSIGNED_TO_NUMBER : deviceStatusOptions.LEGACY_ADDON
  }

  return ownership
}

// the following selector (userSelector) is used to speed up consequent user searches and conserve resources
const getUsersFromState = state => state.users || []
const usersFilter = (users, username) => users.filter(user => user.userName === username).pop()
const userSelector = createSelector(getUsersFromState, usersFilter)

export default reducer
export { clearState, retrieveHandsetStatistics, userSelector, assignDevice, retrieveDeviceStatus, claimDevice }
export { UserServicesConnect as connect }

// modifies the behaviour of react-redux connect to have only the relevant portion of the state available to map functions
function UserServicesConnect(mapStateToProps, mapDispatchToProps, mergeProps, options = {}) {
  return connect(
    (state, props) => (mapStateToProps ? mapStateToProps(state.Devices, props) : {}),
    mapDispatchToProps,
    mergeProps,
    options
  )
}
