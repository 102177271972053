import { getToken } from "lib/token"
import getConfiguration from "data/configuration"
import _getSplunkApiToken from "@fuze/apis-butler/dist/tokens/functions/getSplunkApiToken"

// Note, this is separate from butler.js on purpose to avoid
// circular dependency with errorInterceptor
async function getSplunkApiToken() {
  const { butler } = await getConfiguration()
  const token = getToken()
  return _getSplunkApiToken(butler.url, token)
}

export { getSplunkApiToken }
