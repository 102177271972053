import React, { useContext, useMemo } from "react"
import classnames from "classnames"
import { ModalContext } from "./FuzeModal"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface IModalHeader {}

const ModalHeader: FuzzyFunctionalComponent<IModalHeader> = ({ children, className, ...props }) => {
  const { color, toggle } = useContext(ModalContext)
  const gradient = useMemo(() => {
    switch (color) {
      case "secondary":
        return "gradient-dark-purple-to-light-purple"
      case "danger":
        return "gradient-red-to-orange"
      case "primary":
        return "gradient-blue-to-teal"
      case "gray":
      default:
        return ""
    }
  }, [color])

  return (
    <div className={classnames(gradient, className, "fuze-modal__header modal-header")} {...props}>
      {children}
      <button type="button" className="close" aria-label="Close" onClick={(): any => toggle(undefined)}>
        <span aria-hidden="true">×</span>
      </button>
    </div>
  )
}

export default ModalHeader
