import getConfiguration from "../configuration"
import { getAuthorizationHeader } from "@fuze/services-auth/dist/token"
import { getOrganization } from "lib/user-session"
import redirect from "lib/redirect"
import _flow from "lodash/flow"
import { errorInterceptor } from "data/apis/errorInterceptor"
import { goToUniversalLoginUrlDelayed } from "./authentication-utilities"
import { errorUnauthorizedPath, error404Path } from "Errors/Paths"

// X-Long-Encoding - used to circumvent <long> size limit on the Java backend when retrieving IDs
const defaultCitadelHeaders = {
  "X-Long-Encoding": "string"
}

async function getHealthCheckUrl() {
  const { citadel } = await getConfiguration()
  return `${citadel.url}/test`
}

function getOutageUrl() {
  return error404Path
}

function getPermissionsUrl() {
  return errorUnauthorizedPath
}

async function pingHealthCheckUrl() {
  const url = await getHealthCheckUrl()
  return await fetch(url, {
    method: "GET",
    headers: { ...defaultCitadelHeaders }
  })
}

function goToOutageUrl() {
  redirect(getOutageUrl())
}

function goToPermissionsUrl() {
  redirect(getPermissionsUrl())
}

async function getTenantView() {
  const { citadel } = await getConfiguration()
  const tenant = getOrganization()
  const url = `${citadel.url}/api/v1/tenants/${tenant}/views/applications/${citadel.appName}`
  return await fetchFromCitadel(url)
}

async function setTenantUpdateType(type) {
  const { citadel } = await getConfiguration()
  const tenant = getOrganization()
  const url = `${citadel.url}/api/v1/tenants/${tenant}/configurations/applications/${citadel.appName}/updates`
  return await fetchFromCitadel(url, {
    method: "PUT",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({ type })
  })
}

async function addEarlyAccessUser(userId) {
  const { citadel } = await getConfiguration()
  const url = `${citadel.url}/api/v1/users/${userId}/configurations/applications/${citadel.appName}/updates`
  return await fetchFromCitadel(url, {
    method: "PUT",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({ type: "automatic" })
  })
}

async function removeEarlyAccessUser(uid, type) {
  const { citadel } = await getConfiguration()
  const url = `${citadel.url}/api/v1/users/${uid}/configurations/applications/${citadel.appName}/updates`
  return await fetchFromCitadel(url, {
    method: "DELETE",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({ type })
  })
}

async function setRolloutSchedule(rolloutId, date) {
  const { citadel } = await getConfiguration()
  const tenant = getOrganization()
  const url = `${citadel.url}/api/v1/rollouts/${rolloutId}/schedules?tenant=${tenant}`
  return await fetchFromCitadel(url, {
    method: "PUT",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({
      rolloutDate: date,
      channel: "stable",
      tenant: tenant
    })
  })
}

async function approveReleaseById(releaseId) {
  const config = await getConfiguration()
  const tenant = getOrganization()
  const url = `${config.citadel.url}/api/v1/tenants/${tenant}/configurations/applications/${config.citadel.appName}/releases/approved/${releaseId}`
  return fetchFromCitadel(url, {
    method: "PUT"
  })
}

// TODO Find a better way to handle these errors than to do these redirects
// see https://itnext.io/centralizing-api-error-handling-in-react-apps-810b2be1d39d
function fetchFromCitadel(url, options = {}) {
  const headers = { headers: { ...options.headers, ...getAuthorizationHeader(), ...defaultCitadelHeaders } }
  const combinedOptions = { ...options, ...headers }
  return fetch(url, combinedOptions)
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        if (response.status === 401) {
          response.json().then(data => {
            if (data.status === 1008) {
              goToPermissionsUrl()
            } else {
              goToUniversalLoginUrlDelayed()
            }
          })
        } else if (response.status === 403) {
          goToPermissionsUrl()
        } else {
          return pingHealthCheckUrl().then(response => {
            if (response.status === 200) {
              throw new Error(response.statusText)
            } else {
              goToOutageUrl()
            }
          })
        }
      }
    })
    .then(response => response.data)
}

const getTenantViewHandled = _flow(errorInterceptor)(getTenantView)
const setTenantUpdateTypeHandled = _flow(errorInterceptor)(setTenantUpdateType)
const addEarlyAccessUserHandled = _flow(errorInterceptor)(addEarlyAccessUser)
const removeEarlyAccessUserHandled = _flow(errorInterceptor)(removeEarlyAccessUser)
const setRolloutScheduleHandled = _flow(errorInterceptor)(setRolloutSchedule)
const approveReleaseByIdHandled = _flow(errorInterceptor)(approveReleaseById)

export {
  getTenantViewHandled as getTenantView,
  setTenantUpdateTypeHandled as setTenantUpdateType,
  addEarlyAccessUserHandled as addEarlyAccessUser,
  removeEarlyAccessUserHandled as removeEarlyAccessUser,
  setRolloutScheduleHandled as setRolloutSchedule,
  approveReleaseByIdHandled as approveReleaseById
}
