import React from "react"
import { stringify } from "query-string"
import PermissionsProtected from "components/permissions/PermissionsProtected"
import permissions from "../constants/permissions"
import useIsActingOn from "components/layouts/TopNavBar/hooks/useIsActingOn"

export const buildUserListPath = queryParams => {
  const withDefault = {
    ...queryParams,
    status: "active"
  }
  const serializedQueryParams = stringify(withDefault)
  return `${userPagesHomePath}?${serializedQueryParams}`
}

export const buildAdminListPath = queryParams => {
  const withDefault = {
    ...queryParams,
    status: "active"
  }
  const serializedQueryParams = stringify(withDefault)
  return `${userPagesHomePath}/administrators?${serializedQueryParams}`
}

const UsersListPath = "/users"
const AdminListPath = "/users/administrators"

const Paths = Object.freeze({
  UserProfile: "/users/:userName",
  UserRatings: "/users/:userName/ratings",
  UserOverview: "/users/:userName/overview",
  UserAdd: "/users/new",
  UserAddNoService: "/users/new/noservice",
  UserClone: "/users/:userName/clone",
  ActivateDevice: "/users/:userName/device/activate",
  UserDeactivate: "/users/:userName/deactivate",
  UserServiceUpdate: "/users/:userName/update"
})

const userPagesHomePath = "/users"

const buildUserProfilePath = userName => `/users/${userName}`
const buildUserRatingsPath = userName => `/users/${userName}/ratings`
const buildUserClonePath = userName => `/users/${userName}/clone`
const buildUserDeactivatePath = userName => `/users/${userName}/deactivate`
const buildActivateDevicePath = userName => `/users/${userName}/device/activate`
const buildUserServiceUpdatePath = userName => `/users/${userName}/update`

const UserProfileAccess = ({ children, userName }) => {
  return useIsActingOn() ? null : children(buildUserProfilePath(userName))
}

//  TODO: Add proper permissions
const UserRatingsAccess = ({ children, userName }) => !useIsActingOn() && children(buildUserRatingsPath(userName))

const UserListAccess = ({ children, locationIds }) => children(buildUserListPath({ locationId: locationIds }))

const UserListAccessWithoutPath = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.view]}>{children}</PermissionsProtected>
)

const UserAddAccess = ({ children }) => children(Paths.UserAdd)

const UserServiceUpdateAccess = ({ children, userName }) => children(buildUserServiceUpdatePath(userName))

const UserCloneAccess = ({ children, userName }) => children(buildUserClonePath(userName))

const UserReactivateAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.reactivate]}>{children}</PermissionsProtected>
)

const UserDeactivateAccess = ({ children, userName }) => children(buildUserDeactivatePath(userName))

const UserExportAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.export]}>{children}</PermissionsProtected>
)

const UserSendWelcomeEmailAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.sendWelcomeEmail]}>{children}</PermissionsProtected>
)

const UserResetPasswordAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.resetPassword]}>{children}</PermissionsProtected>
)

const UserEditNumberAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.changePhoneNumber]}>{children}</PermissionsProtected>
)

const UserActivateDeviceAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.devices.bindHandset]}>{children}</PermissionsProtected>
)

const UserAddFaxAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.addFax]}>{children}</PermissionsProtected>
)

const UserEditIdmAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.idmEditUserProvider]}>{children}</PermissionsProtected>
)

const UserAddRecordingAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.addRecording]}>{children}</PermissionsProtected>
)

const UserEditRecordingAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.addRecording]}>{children}</PermissionsProtected>
)

const UserRemoveServiceAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.serviceDeprovision]}>{children}</PermissionsProtected>
)

const UserAddDiscoverAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.addDiscovery]}>{children}</PermissionsProtected>
)

const UserRemoveDiscoverAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.serviceDeprovision]}>{children}</PermissionsProtected>
)

const UserAddSMSAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.addSMS]}>{children}</PermissionsProtected>
)

const UserRemoveSMSAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.serviceDeprovision]}>{children}</PermissionsProtected>
)

const UserAddContactCenterAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.addContactCenter]}>{children}</PermissionsProtected>
)

const UserRemoveContactCenterAccess = ({ children, userName }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.serviceDeprovision]}>{children}</PermissionsProtected>
)

const SwitchCustomerAccessWithoutPath = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.switchCustomer]}>{children}</PermissionsProtected>
)

const EditRolesPermissionsAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.users.manageRoles]}>{children}</PermissionsProtected>
)

const UserViewRostersAccess = ({ children }) => (
  <PermissionsProtected requiredPermissions={[permissions.contactRosters.viewRostersOnUserProfile]}>
    {children}
  </PermissionsProtected>
)

export {
  UserProfileAccess,
  UserRatingsAccess,
  UserCloneAccess,
  UserListAccess,
  UserListAccessWithoutPath,
  UserAddAccess,
  UserExportAccess,
  UserServiceUpdateAccess,
  UserReactivateAccess,
  UserDeactivateAccess,
  UserSendWelcomeEmailAccess,
  UserResetPasswordAccess,
  SwitchCustomerAccessWithoutPath,
  UserActivateDeviceAccess,
  buildActivateDevicePath,
  UserEditNumberAccess,
  EditRolesPermissionsAccess,
  UserAddFaxAccess,
  UserAddRecordingAccess,
  UserEditRecordingAccess,
  UserAddDiscoverAccess,
  UserRemoveDiscoverAccess,
  UserAddSMSAccess,
  UserRemoveSMSAccess,
  UserAddContactCenterAccess,
  UserRemoveContactCenterAccess,
  UserEditIdmAccess,
  UserRemoveServiceAccess,
  buildUserProfilePath,
  buildUserRatingsPath,
  UsersListPath,
  AdminListPath,
  UserViewRostersAccess,
  buildUserServiceUpdatePath,
  userPagesHomePath
}
