import _getHandsets from "@fuze/apis-foundry/dist/handsets/functions/getHandsets"
import _getHandset from "@fuze/apis-foundry/dist/handsets/functions/getHandset"
import _bindHandset from "@fuze/apis-foundry/dist/handsets/functions/bindHandset"
import _unbindHandset from "@fuze/apis-foundry/dist/handsets/functions/unbindHandset"
import _searchHardware from "@fuze/apis-foundry/dist/hardware/functions/searchHardware"
import _updateHandsetLocation from "@fuze/apis-foundry/dist/handsets/functions/updateHandsetLocation"
import _getHardwareDetails from "@fuze/apis-foundry/dist/hardware/functions/getHardwareDetails"
import _getHardware from "@fuze/apis-foundry/dist/hardware/functions/getHardware"
import _searchHandsets from "@fuze/apis-foundry/dist/handsets/functions/searchHandsets"

import { wrapFoundryFunction, wrapAllResults, wrapAllResultsNoOrganization } from "./decorators"

// handsets
/**
 * getHandsets has a signature of
 * - baseUrl
 * - authenticationToken
 * - organizationCode
 * - query
 * - user
 * - pagination
 *
 * so here we're wrapping the function to auto-fill in query/user as blank
 */
const handsetsWithoutQueryOrUser = (baseUrl, token, origin, organizationCode, pagination) => {
  return _getHandsets(baseUrl, token, origin, organizationCode, "", "", pagination)
}

const handsetsWithoutQuery = (baseUrl, token, origin, organizationCode, user, pagination) =>
  _getHandsets(baseUrl, token, origin, organizationCode, "", user, pagination)

export const getHandsets = wrapAllResults(handsetsWithoutQueryOrUser)
export const getHandsetsForUser = wrapAllResults(handsetsWithoutQuery)
export const getHandset = wrapFoundryFunction(_getHandset)
export const bindHandset = wrapFoundryFunction(_bindHandset)
export const unbindHandset = wrapFoundryFunction(_unbindHandset)
export const searchHardware = wrapFoundryFunction(_searchHardware)
export const updateHandsetLocation = wrapFoundryFunction(_updateHandsetLocation)
export const getHardwareDetails = wrapFoundryFunction(_getHardwareDetails)
export const getHardware = wrapFoundryFunction(_getHardware)
export const searchHandsets = wrapAllResultsNoOrganization(_searchHandsets)
