import React, { useContext } from "react"
import { ModalContext } from "@fuze/react-fuzzy"
import ActionProcessingButtons, { ActionErrorHandling } from "components/buttons/ActionProcessingButtons"

const ModalFooterButtons = ({ children, errors = {}, id, onClearErrorMessage = null, isValid = true, ...props }) => {
  const modalContext = useContext(ModalContext)

  return (
    <>
      <ActionProcessingButtons
        color={modalContext.color}
        onCancel={modalContext.toggle}
        id={id}
        isValid={isValid}
        {...props}
      >
        {children}
      </ActionProcessingButtons>
      <ActionErrorHandling errors={errors} target={id} onClearErrorMessage={onClearErrorMessage} />
    </>
  )
}

export default ModalFooterButtons
