import { getDepartmentsByIds, searchServices } from "./apis/foundry"
import { emptyPromise } from "./query/resolverHelpers"
import _groupBy from "lodash/groupBy"
import _keyBy from "lodash/keyBy"
import _curryRight from "lodash/curryRight"
import _maxBy from "lodash/maxBy"
import { retrieveContactsByFoundryUsers } from "./apis/contactive"
import { getLocationsByIds } from "./locations"
import { getAllStoredLocationsByIds } from "data/locations"
import { searchDevices } from "./apis/foundry/devices"
import _compact from "lodash/compact"

function services(serviceQuery) {
  return {
    resolve: _curryRight(retrieveServicesForUsers, 2)(serviceQuery),
    reduce: combineUsersWithServices
  }
}

function retrieveServicesForUsers(users, serviceQuery = userIds => searchServices({ users: userIds })) {
  const userIds = users.map(user => user.id)
  if (userIds.length > 0) {
    return serviceQuery(userIds)
  }
  return emptyPromise()
}

function combineUsersWithServices(users, services) {
  const serviceMap = _groupBy(services, "user.id")
  return users.map(user => {
    return {
      ...user,
      services: serviceMap[user.id]
    }
  })
}

function locations() {
  return {
    resolve: retrieveLocationsForUsers,
    reduce: combineUsersWithLocations
  }
}

function allLocations() {
  return {
    resolve: retrieveAllLocationsForUsers,
    reduce: combineUsersWithLocations
  }
}

function retrieveLocationsForUsers(users) {
  const locationIds = users.filter(user => !!user.location).map(user => user.location.id)
  if (locationIds.length > 0) {
    return getLocationsByIds(locationIds)
  }
  return emptyPromise()
}

function retrieveAllLocationsForUsers(users) {
  const locationIds = users.filter(user => !!user.location).map(user => user.location.id)
  if (locationIds.length > 0) {
    return getAllStoredLocationsByIds(locationIds)
  }
  return emptyPromise()
}

function combineUsersWithLocations(users, locations) {
  const locationMap = _keyBy(locations, "id")
  return users.map(user => ({
    ...user,
    location: user.location && locationMap[user.location.id]
  }))
}

function departments() {
  return {
    resolve: retrieveDepartmentsForUsers,
    reduce: combineUsersWithDepartments
  }
}

function retrieveDepartmentsForUsers(users) {
  const departmentIds = users.filter(user => !!user.department).map(user => user.department.id)
  if (departmentIds.length > 0) {
    return getDepartmentsByIds(departmentIds)
  }
  return emptyPromise()
}

function combineUsersWithDepartments(users, departments) {
  const departmentMap = _keyBy(departments, "id")
  return users.map(user => ({
    ...user,
    department: user.department && departmentMap[user.department.id]
  }))
}

function avatars() {
  return {
    resolve: users => {
      return retrieveContactsByFoundryUsers(users.length > 0 ? users.slice(0, 100) : [])
    },
    reduce: combineUsersWithAvatars
  }
}

function combineUsersWithAvatars(users, contactiveData) {
  const cleanData = _compact(contactiveData)

  if (cleanData?.length > 0) {
    const contactiveMap = _keyBy(
      cleanData.map(cd => {
        const origins = cd.origins

        return {
          userId: cd.key.replace("ngchat:user_", ""),
          origins
        }
      }),
      "userId"
    )

    const combinedUsers = users.map(user => {
      const contactiveUser = contactiveMap[user.id]
      let picture = undefined
      if (!!contactiveUser) {
        const origins = contactiveUser.origins
        picture =
          origins.length > 0 &&
          origins[0] &&
          origins[0].picture &&
          origins[0].picture.length > 0 &&
          _maxBy(origins[0].picture, "retrieved")
      }

      return {
        ...user,
        avatar: picture
      }
    })
    return combinedUsers
  } else {
    return users
  }
}

function devices(deviceQuery) {
  return {
    resolve: _curryRight(retrieveDevicesForUsers, 2)(deviceQuery),
    reduce: combineUsersWithDevices
  }
}

function retrieveDevicesForUsers(users, deviceQuery = userIds => searchDevices({ userIds: userIds })) {
  const userIds = users.map(user => user.id)
  if (userIds.length > 0) {
    return deviceQuery(userIds)
  }
  return emptyPromise()
}

function combineUsersWithDevices(users, devices) {
  return users.map(user => ({
    ...user,
    devices
  }))
}

export {
  services,
  retrieveServicesForUsers,
  combineUsersWithServices,
  locations,
  allLocations,
  retrieveLocationsForUsers,
  retrieveAllLocationsForUsers,
  combineUsersWithLocations,
  departments,
  retrieveDepartmentsForUsers,
  combineUsersWithDepartments,
  avatars,
  combineUsersWithAvatars,
  devices
}
