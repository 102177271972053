import getConfiguration from "../../configuration"
import { getAuthorizationHeader } from "@fuze/services-auth/dist/token"
import { getOrganization as getOrganizationCode, getUser } from "lib/user-session"
import { getOrganizationDetails, invalidateOrganizationDetails } from "./organizations"
import _flow from "lodash/flow"
import { errorInterceptor } from "data/apis/errorInterceptor"
import { bearerToken, extractData, populateParameter } from "data/apis/utils"
import _getAcceptances from "@fuze/apis-gavel/dist/acceptances/functions/getAcceptances"
import _getAdmins from "@fuze/apis-gavel/dist/admins/functions/getAdmins"

/**
 * This feature has some history, so I thought it would be worth explaining here:
 *
 * Gavel was a microservice created for exposing endpoints related to end user license agreements.
 * Both Hub and Foundry (and maybe Portal) used Gavel to confirm that the customer had signed the latest
 * end user license agreement. If so, they could continue to use the Portal (and endpoints). If not, they
 * were prompted to sign the latest agreement.
 *
 * The BOAT team made the decision to merge the gavel endpoints into Foundry. Initially, the Hub could
 * continue to call gavel and the calls would be proxied to Foundry. The Hub eventually switched to
 * just calling Foundry.
 *
 * This file breaks a rule: it uses fetch (rather than the SDK) to make calls
 */

// X-Long-Encoding - used to circumvent <long> size limit on the Java backend when retrieving IDs
const defaultGavelHeaders = {
  // "X-Long-Encoding": "string"
}

// Get customer's latest agreement.
// We need to get the customer's latest agreement
//   before we can make a second request to determine if it's been signed.
async function getLegalStateForOrganization() {
  const user = await getUser()

  // Get the agreement associated with the user's tenant key, not the company's tenant key:
  //   this blocks fuze employees or resellers from acting/signing on behalf of another company
  const { organization, agreement, acceptance } = await getOrganizationDetails(user.tenantKey)

  return {
    agreementId: agreement.id,
    agreementUrl: agreement.url,
    customerName: organization.name,
    agreementIsSigned: !!acceptance || user.tenantKey === "thinkingphones"
  }
}

async function getLegalState() {
  return await getLegalStateForOrganization(getOrganizationCode())
}

const url = populateParameter(async () => {
  const { gavel } = await getConfiguration()
  return gavel.url
})
const defaultParameters = _flow(extractData, errorInterceptor, url, bearerToken)

// return zero or more agreements (all current and past)
//   zero found == new customer
//   one or more found == current customer
const getAcceptances = defaultParameters(_getAcceptances)
const getAdmins = defaultParameters(_getAdmins)

async function acceptAgreement(id) {
  const { foundry } = await getConfiguration()
  const url = `${foundry.url}/v1/acceptances`
  return fetchFromGavel(url, {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({ agreement: id })
  }).then(() => {
    invalidateOrganizationDetails(getOrganizationCode())
  })
}

async function hasCustomerSignedAgreement() {
  const legalState = await getLegalState()
  return legalState.agreementIsSigned
}

function fetchFromGavelUnhandled(url, options = {}) {
  const headers = { headers: { ...options.headers, ...getAuthorizationHeader(), ...defaultGavelHeaders } }
  const combinedOptions = { ...options, ...headers }

  return fetch(url, combinedOptions).then(response => {
    if (response.ok) {
      return response.json()
    } else {
      throw new Error(response.statusText)
    }
  })
}

const fetchFromGavel = _flow(errorInterceptor)(fetchFromGavelUnhandled)

export { acceptAgreement, getAdmins, getAcceptances, getLegalState, hasCustomerSignedAgreement }
