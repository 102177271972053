import {
  IconLaptopChartLine,
  IconPinLocation,
  IconUsers,
  IconDeskphone,
  IconDollarMenu,
  IconNetworkWiredAlt,
  IconCog,
  IconStar,
  IconArrowsSwitch,
  IconUserAgent
} from "@fuze/icon-font"

function SidebarIcon({ icon }) {
  const sidebarIconClassName = "side-bar__icon"
  switch (icon) {
    case "icon-dashboard":
      return <IconLaptopChartLine className={sidebarIconClassName} />
    case "icon-locations":
      return <IconPinLocation className={sidebarIconClassName} />
    case "icon-users":
      return <IconUsers className={sidebarIconClassName} />
    case "icon-devices":
      return <IconDeskphone className={sidebarIconClassName} />
    case "icon-billing-activity":
      return <IconDollarMenu className={sidebarIconClassName} />
    case "icon-phone-system":
      return <IconNetworkWiredAlt className={sidebarIconClassName} />
    case "icon-company-settings":
      return <IconCog className={sidebarIconClassName} />
    case "icon-star_stroke":
      return <IconStar className={sidebarIconClassName} />
    case "icon-integrations":
      return <IconArrowsSwitch className={sidebarIconClassName} />
    case "icon-contact-center":
      return <IconUserAgent className={sidebarIconClassName} />

    default:
      return null
  }
}

export default SidebarIcon
