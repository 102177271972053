import { hasCustomerSignedAgreement } from "data/apis/foundry/agreements"
import { useAsync } from "react-use"
import { getUser } from "lib/user-session"
import { useIsImpersonating } from "components/layouts/TopNavBar/hooks/useIsImpersonating"

export const useHasCustomerSignedAgreement = () => {
  const { value, loading } = useAsync(hasCustomerSignedAgreement)
  const isImpersonating = useIsImpersonating()
  const user = getUser()
  const shouldRedirectIfNotSigned = user.tenantKey !== "thinkingphones" && !isImpersonating
  return { hasCustomerSignedAgreement: value, shouldRedirectIfNotSigned, loading }
}
