import React, { lazy } from "react"
import { Switch } from "routing"

import { ProtectedHubLayoutRoute } from "components/layouts/HubLayout"

import {
  guestPolicyPath,
  desktopUpdatesPath,
  notificationPolicyPath,
  actOnListVisibilityPath,
  identityManagementPath,
  rostersVisibilityPath,
  guestActionPath,
  activeGuestsPath,
  deactivatedGuestsPath
} from "./Paths"

const DesktopUpdates = lazy(() => import("./DesktopUpdates/DesktopUpdates"))
const GuestPolicy = lazy(() => import("./Guests/Policy/GuestsPolicy"))
const NotificationPolicy = lazy(() => import("./EmailSettings/EmailSettingsPage"))
const IdentityManagement = lazy(() => import("./IdentityManagement/IdentityManagement"))
const DefaultVisibilityPage = lazy(() => import("./ContactRosters/DefaultVisibilityPage"))
const ActOnList = lazy(() => import("CompanySettings/ActOnList/ActOnList"))
const GuestsActive = lazy(() => import("./Guests/List/GuestsActive"))
const GuestsDeactivated = lazy(() => import("./Guests/List/GuestsDeactivated"))

const Routes = () => {
  return (
    <Switch>
      <ProtectedHubLayoutRoute exact path={notificationPolicyPath} Component={NotificationPolicy} />
      <ProtectedHubLayoutRoute path={desktopUpdatesPath} Component={DesktopUpdates} />
      <ProtectedHubLayoutRoute path={guestPolicyPath} Component={GuestPolicy} />
      <ProtectedHubLayoutRoute exact path={guestActionPath} Component={GuestsActive} />
      <ProtectedHubLayoutRoute exact path={activeGuestsPath} Component={GuestsActive} />
      <ProtectedHubLayoutRoute exact path={deactivatedGuestsPath} Component={GuestsDeactivated} />
      <ProtectedHubLayoutRoute path={identityManagementPath} Component={IdentityManagement} />
      <ProtectedHubLayoutRoute path={rostersVisibilityPath} Component={DefaultVisibilityPage} />
      <ProtectedHubLayoutRoute exact path={actOnListVisibilityPath} Component={ActOnList} />
    </Switch>
  )
}

export default Routes
