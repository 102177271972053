import { connect } from "react-redux"
import {
  handsetsLoadActions,
  userLoadActions,
  servicesLoadActions,
  mobilesLoadActions,
  idpsLoadActions,
  idpsUpdateActions,
  idpsResetActions,
  recordingConfigLoadActions,
  contractProductsLoadActions,
  resetPasswordUpdateActions,
  resetPasswordClearActions
} from "./actionTypes"

const reducer = (state = {}, action) => {
  switch (action.type) {
    case userLoadActions.types.start:
      return {
        ...state,
        userLoading: true,
        userFetchError: false
      }
    case userLoadActions.types.success:
      return {
        ...state,
        user: action.results,
        userLoading: false,
        userFetchError: false
      }
    case userLoadActions.types.failure:
      return {
        ...state,
        userLoading: false,
        userFetchError: true
      }
    case servicesLoadActions.types.start:
      return {
        ...state,
        servicesLoading: true,
        servicesFetchError: false
      }
    case servicesLoadActions.types.success:
      return {
        ...state,
        services: action.results.services,
        extensions: action.results.extensions,
        servicesLoading: false,
        servicesFetchError: false
      }
    case servicesLoadActions.types.failure:
      return {
        ...state,
        servicesLoading: false,
        servicesFetchError: true
      }
    case handsetsLoadActions.types.start:
      return {
        ...state,
        handsetsLoading: true,
        handsetsFetchError: false
      }
    case handsetsLoadActions.types.success:
      return {
        ...state,
        handsets: action.results || [],
        handsetsLoading: false,
        handsetsFetchError: false
      }
    case handsetsLoadActions.types.failure:
      return {
        ...state,
        handsetsLoading: false,
        handsetsFetchError: true
      }
    case mobilesLoadActions.types.start:
      return {
        ...state,
        mobilesLoading: true,
        mobilesFetchError: false
      }
    case mobilesLoadActions.types.success:
      return {
        ...state,
        mobiles: action.results,
        mobilesLoading: false,
        mobilesFetchError: false
      }
    case mobilesLoadActions.types.failure:
      return {
        ...state,
        mobilesLoading: false,
        mobilesFetchError: true
      }
    case idpsResetActions.type:
      return {
        ...state,
        idpsLoading: false,
        idpsFetchError: false,
        idpsUpdateComplete: false
      }
    case idpsLoadActions.types.start:
      return {
        ...state,
        idpsLoading: true,
        idpsFetchError: false
      }
    case idpsLoadActions.types.success:
      return {
        ...state,
        idps: action.results,
        idpsLoading: false,
        idpsFetchError: false
      }
    case idpsLoadActions.types.failure:
      return {
        ...state,
        idpsLoading: false,
        idpsFetchError: true
      }
    case idpsUpdateActions.types.start:
      return {
        ...state,
        idpsUpdateLoading: true,
        idpsUpdateError: false,
        idpsUpdateComplete: false
      }
    case idpsUpdateActions.types.success:
      return {
        ...state,
        idps: action.results,
        idpsUpdateLoading: false,
        idpsUpdateError: false,
        idpsUpdateComplete: true
      }
    case idpsUpdateActions.types.failure:
      return {
        ...state,
        idpsUpdateLoading: false,
        idpsUpdateError: true,
        idpsUpdateComplete: false
      }
    case recordingConfigLoadActions.types.start:
      return {
        ...state,
        recordingConfigLoading: true,
        recordingConfigLoadFetchError: false
      }
    case recordingConfigLoadActions.types.success:
      return {
        ...state,
        recordingConfig: action.results,
        recordingConfigLoading: false,
        recordingConfigLoadFetchError: false
      }
    case recordingConfigLoadActions.types.failure:
      return {
        ...state,
        recordingConfig: undefined,
        recordingConfigLoading: false,
        recordingConfigLoadFetchError: true
      }
    case contractProductsLoadActions.types.start:
      return {
        ...state,
        contractProductsLoading: true,
        contractProductsLoadFetchError: false
      }
    case contractProductsLoadActions.types.success:
      return {
        ...state,
        contractProducts: action.results,
        contractProductsLoading: false,
        contractProductsLoadFetchError: false
      }
    case contractProductsLoadActions.types.failure:
      return {
        ...state,
        contractProductsLoading: false,
        contractProductsLoadFetchError: true
      }
    case resetPasswordUpdateActions.types.start:
      return {
        ...state,
        hasSubmittedPasswordRequest: false,
        resetPasswordLoading: true,
        resetPasswordError: false
      }
    case resetPasswordUpdateActions.types.success:
      return {
        ...state,
        hasSubmittedPasswordRequest: true,
        resetPasswordLoading: false,
        resetPasswordError: false
      }
    case resetPasswordUpdateActions.types.failure:
      return {
        ...state,
        hasSubmittedPasswordRequest: false,
        resetPasswordLoading: false,
        resetPasswordError: true
      }
    case resetPasswordClearActions.type:
      return {
        ...state,
        hasSubmittedPasswordRequest: false,
        resetPasswordLoading: false,
        resetPasswordError: false
      }
    default:
      return state
  }
}

function UserProfile(mapStateToProps, mapDispatchToProps, mergeProps, options = {}) {
  return connect(
    state => (mapStateToProps ? mapStateToProps(state.UserProfile) : {}),
    mapDispatchToProps,
    mergeProps,
    options
  )
}

export default reducer
export { UserProfile as connect }
