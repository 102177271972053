import React from "react"
import { FormattedDate, FormattedMessage } from "react-intl"
import { getFullUserName } from "lib/string-utilities"

const OrdersModalContent = ({ date, completedBy, children }) => {
  return (
    <>
      <p className="font-weight-bold">
        <FormattedDate
          value={date}
          year="numeric"
          month="short"
          day="2-digit"
          weekday="long"
          hour="2-digit"
          minute="2-digit"
          hour12={false}
          timeZoneName="short"
        />
      </p>
      <FormattedMessage id={"table.modals.completedBy"} values={{ user: getFullUserName(completedBy) }} />
      {children}
    </>
  )
}

export default OrdersModalContent
