import React from "react"
import { Button, Collapse, Navbar, NavbarBrand, NavbarToggler } from "reactstrap"
import { BrowserRouter, Link } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { HomeAccess } from "../../../Dashboard/Access"
import { useToggle } from "react-use"
import ExternalLinks from "./components/ExternalLinks"
import UserMenu from "./components/UserMenu"
import TenantSwitcher from "./components/TenantSwitcher"
import { changeFocusToElementWithId } from "lib/a11y-utilities"
import useIsHubActive from "./hooks/useIsHubActive"
import { IconMenu } from "@fuze/icon-font"

const TopNavBar: React.FunctionComponent = () => {
  const intl = useIntl()
  const [isOpen, toggleOpen] = useToggle(false)
  const isHubActive = useIsHubActive()

  return (
    // Encapsulating with a new BrowserRouter because unlike the top router we do need forceRefresh for the tenant switcher
    <header>
      <Navbar
        color="faded"
        light
        id="header"
        className="navbar-expand-md"
        aria-label={intl.formatMessage({ id: "header.ariaLabel" })}
      >
        <span className="skip-link-wrapper">
          <Button
            id="skip-link"
            tag={Link}
            color="primary"
            size="sm"
            outline
            to="#main-content"
            onClick={() => changeFocusToElementWithId("main-content")}
          >
            <FormattedMessage id="header.skipLink" />
          </Button>
        </span>
        <NavbarToggler onClick={toggleOpen}>
          <IconMenu />
        </NavbarToggler>
        <HomeAccess>
          {path => (
            <NavbarBrand href={path}>
              <span className="sr-only">
                <FormattedMessage id="header.homeButton" />
              </span>
            </NavbarBrand>
          )}
        </HomeAccess>
        <Collapse id="top-nav-expand-collapse" isOpen={isOpen} navbar>
          <div className={"top-nav-bar"}>
            {isHubActive && (
              <div className={"top-nav-bar--tenant-switcher"}>
                <BrowserRouter forceRefresh={true}>
                  <TenantSwitcher allowCustomerSwitching={true} />
                </BrowserRouter>
              </div>
            )}
            <ExternalLinks />
            <UserMenu isOpen={isOpen} />
          </div>
        </Collapse>
      </Navbar>
    </header>
  )
}

export default TopNavBar
