import { DID_REQUEST_STATUS_VALUES } from "./constants"
import { doesUserHavePermission } from "lib/user-session"
import permissions from "constants/permissions"

export const getDefaultRequestStatus = () => {
  const hasNoDidRestrictions = doesUserHavePermission(permissions.dids.didRequestCreateNoRestrictions)

  if (hasNoDidRestrictions) {
    // Fuze will be interested on pending requests
    return [
      DID_REQUEST_STATUS_VALUES.REQUEST_RECEIVED,
      DID_REQUEST_STATUS_VALUES.SUBMITTED_TO_CARRIER,
      DID_REQUEST_STATUS_VALUES.CARRIER_ACKNOWLEDGED,
      DID_REQUEST_STATUS_VALUES.PARTIALLY_COMPLETED
    ]
  } else {
    // Customers will be interested on all requests
    return []
  }
}
