import React from "react"
import { FormattedMessage } from "react-intl"
import FormattedNumberNoWarning from "components/FormattedNumberNoWarning"
import { printPlusOrMinus } from "../data/utils"

const BillingImpactMessage = ({ billing }) => {
  if (billing.billingImpact === undefined) {
    return null
  }
  if (billing.billingImpact < 0) {
    return (
      <>
        <span>{printPlusOrMinus(billing.billingImpact)}</span>
        <FormattedNumberNoWarning
          value={Math.abs(billing.billingImpact)}
          styleProp={"currency"}
          currency={billing.currency}
        />
      </>
    )
  } else {
    return (
      <FormattedMessage
        id={billing.billingImpact === 0 ? "table.modals.noNetCharge" : "table.modals.billingImpactRecurring"}
        values={{
          isPositive: printPlusOrMinus(billing.billingImpact),
          amount: (
            <FormattedNumberNoWarning
              value={Math.abs(billing.billingImpact)}
              styleProp={"currency"}
              currency={billing.currency}
            />
          )
        }}
      />
    )
  }
}

export default BillingImpactMessage
