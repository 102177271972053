import getConfiguration from "../configuration"
import { getToken } from "lib/token"

import _createClaim from "@fuze/apis-claims/dist/claims/functions/createClaim"
import _getClaim from "@fuze/apis-claims/dist/claims/functions/getClaim"
import _getClaims from "@fuze/apis-claims/dist/claims/functions/getClaims"
import _retryClaim from "@fuze/apis-claims/dist/claims/functions/retryClaim"
import _getModels from "@fuze/apis-claims/dist/deviceModels/functions/getModels"
import { decorateWithPaginationFunctionAware } from "./pagination"
import { getOrganization as getActingOrganization } from "lib/user-session"
import _flow from "lodash/flow"
import { errorInterceptor } from "data/apis/errorInterceptor"

function wrapClaimsFunction(fn) {
  return async function (...args) {
    const { claims } = await getConfiguration()
    const token = getToken()
    const { data } = await fn(claims.url, token, ...args)
    return data
  }
}

const getClaim = id => _flow(errorInterceptor, wrapClaimsFunction)(_getClaim)(id)
const getClaims = filter => _flow(errorInterceptor, wrapClaimsFunction)(_getClaims)(filter)
const retryClaim = id => _flow(errorInterceptor, wrapClaimsFunction)(_retryClaim)(id)

// The default _getModels expects a make and series, so for the base case, send undefined for those parameters
const getModels = _flow(
  errorInterceptor,
  wrapClaimsFunction
)(
  decorateWithPaginationFunctionAware(
    (baseUrl, token, pagination) => _getModels(baseUrl, token, "", "", pagination),
    1000
  )
)

const getModelsByMake = make =>
  _flow(
    errorInterceptor,
    wrapClaimsFunction
  )(
    decorateWithPaginationFunctionAware(
      (baseUrl, token, pagination) => _getModels(baseUrl, token, make, "", pagination),
      1000
    )
  )()

/**
 * This function is to do bulk claiming of devices
 *
 * @param claimRequest is an array of
 *     {
 *       "macAddress": string,
 *       "partNumber": string
 *     }
 */
const createClaim = claim =>
  _flow(errorInterceptor, wrapClaimsFunction)(_createClaim)({ organization: getActingOrganization(), claim })

const fiterDevicesByStatus = (claim, status) => claim.claimDeviceList.filter(device => device.status === status)

const filterPendingDevices = claim => fiterDevicesByStatus(claim, "PENDING_CLAIM")
const filterClaimedDevices = claim => fiterDevicesByStatus(claim, "CLAIMED")
const filterFailedDevices = claim => fiterDevicesByStatus(claim, "ERROR")

const claimErrorCodes = {
  ZTP_ALREADY_CLAIMED: "already_claimed",
  ZTP_REGISTER_ERROR: "security_error",
  ZTP_BAD_RESPONSE_CODE: "server_busy",
  MISMATCHED_FUZE_ORG: "mismatched"
}

const getClaimFailedReasoning = claim => claimErrorCodes[claim?.errorCode]
const claimNeedsContactSupport = claimList =>
  filterFailedDevices(claimList).find(claim =>
    ["already_claimed", "security_error", "mismatched"].includes(getClaimFailedReasoning(claim))
  )

const getClaimStatus = claim => {
  if (filterPendingDevices(claim).length) {
    return "pending"
  } else if (filterFailedDevices(claim).length) {
    return "failed"
  } else {
    return "complete"
  }
}

export {
  getClaim,
  getClaims,
  getModels,
  getModelsByMake,
  createClaim,
  retryClaim,
  filterPendingDevices,
  filterClaimedDevices,
  filterFailedDevices,
  getClaimStatus,
  getClaimFailedReasoning,
  claimNeedsContactSupport
}
