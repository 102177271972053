import { useOrganizationEnablements } from "data/enablements"
import _intersection from "lodash/intersection"
import _keyBy from "lodash/keyBy"
import { usePermissions, AuthorizationProvider, AuthenticationProvider } from "@fuze/hub-authorization"
import { Redirect, Router, matchPath } from "routing"
import { hasEveryPermission, hasSomePermission } from "lib/permissions"

import ErrorUnauthorized from "Errors/ErrorUnauthorized"

import useRoutes from "components/authentication/useRoutes"
import { identifyUser } from "components/authentication/identifyUser"
import useConfiguration from "hooks/useConfiguration"

import PageSpinner from "components/spinner/PageSpinner"
import GeneralErrorPage from "Errors/GeneralErrorPage"
import SuspendedOrganization from "Errors/SuspendedOrganization"

import parse from "url-parse"
import { useHasCustomerSignedAgreement } from "Legal/hooks/useHasCustomerSignedAgreement"

function getPermissionsCheck(permissionsCheckString) {
  if (permissionsCheckString === "OR") {
    return hasSomePermission
  }
  return hasEveryPermission
}

function getRouteErrorReason({ hasEnablements, hasPermissions, hasCustomerSignedAgreement }) {
  if (!hasCustomerSignedAgreement) {
    return "agreement"
  }
  if (!hasPermissions) {
    return "permission"
  }
  if (!hasEnablements) {
    return "enablement"
  }
}

function routeErrorHandler(reason) {
  if (reason === "agreement") {
    return <Redirect to={"/legal"} />
  }

  if (reason === "enablement") {
    return "enablement error page"
  }

  if (reason === "permission") {
    return <ErrorUnauthorized />
  }

  return null
}

function CustomAuthorizationProvider({ appName, children }) {
  const permissions = usePermissions()
  const enablements = useOrganizationEnablements()
  const routes = useRoutes()
  const {
    hasCustomerSignedAgreement,
    loading: agreementLoading,
    shouldRedirectIfNotSigned
  } = useHasCustomerSignedAgreement()
  const routesCollection = _keyBy(routes, route => route.path)

  function resolveLocation(to) {
    return typeof to === "function" ? to() : to
  }

  function getPathname(to) {
    const location = resolveLocation(to)
    return parse(location).pathname
  }

  const isInternalPath = to => {
    const pathname = getPathname(to)

    const [route] = routes.filter(route => matchPath(pathname, { path: route.path, exact: true, strict: true }))
    return route && route.app === appName
  }

  function isRoutePermitted(path) {
    const route =
      routesCollection[parse(path).pathname] ||
      routes.find(route => matchPath(parse(path).pathname, { path: route.path, exact: true, strict: true }))

    const hasPermissions =
      route && (!route.permissions || getPermissionsCheck(route.permissionsCheck)(permissions, route.permissions))
    const hasEnablements =
      route && (!route.enablements || _intersection(enablements, route.enablements).length === route.enablements.length)
    const reason = getRouteErrorReason({ hasEnablements, hasPermissions, hasCustomerSignedAgreement })

    return [
      hasPermissions && hasEnablements && (hasCustomerSignedAgreement || !shouldRedirectIfNotSigned),
      reason,
      route
    ]
  }

  if (agreementLoading) {
    return null
  }

  return (
    <Router isInternalPath={isInternalPath}>
      <AuthorizationProvider isRoutePermitted={isRoutePermitted} routeErrorHandler={routeErrorHandler}>
        {children}
      </AuthorizationProvider>
    </Router>
  )
}

function useAuthComponents() {
  return {
    PageSpinner: () => <PageSpinner />,
    GeneralErrorPage: () => <GeneralErrorPage />,
    ErrorUnauthorized: () => <ErrorUnauthorized />,
    SuspendedOrganization: () => <SuspendedOrganization />
  }
}

export default function AuthProvider({ appName, children }) {
  const { warden } = useConfiguration()
  const Components = useAuthComponents()

  return (
    <AuthenticationProvider value={{ warden, Components, authenticationCallback: identifyUser }}>
      <CustomAuthorizationProvider appName={appName}>{children}</CustomAuthorizationProvider>
    </AuthenticationProvider>
  )
}
