import { stringify } from "query-string"
import { getDefaultRequestStatus } from "./data/convertStatuses"

const didRequestsPath = "/dids/requests"

const buildDidRequestsPath = queryParams => {
  const withDefault = {
    ...queryParams,
    status: getDefaultRequestStatus()
  }
  const serializedQueryParams = stringify(withDefault)
  return `${didRequestsPath}?${serializedQueryParams}`
}

const useDIDsAccess = () => {
  const path = buildDidRequestsPath()
  const canUserSeeNumberRequests = false

  return [canUserSeeNumberRequests, path]
}

export { useDIDsAccess }
