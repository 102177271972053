import { lazy } from "react"
import { ProtectedHubLayoutRoute } from "../components/layouts/HubLayout"

const BulkProvision = lazy(() => import("./BulkProvision"))

const bulkProvisionPath = "/provision/bulk"
const Routes = () => {
  return <ProtectedHubLayoutRoute exact path={bulkProvisionPath} Component={BulkProvision} />
}

const BulkProvisionAccess = ({ children }) => children(bulkProvisionPath)

export { BulkProvisionAccess }
export default Routes
