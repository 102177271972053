import React, { Component, Fragment } from "react"
import { withRouter } from "routing"
// TODO This shouldn't be a releases connect
import { connect, clearError } from "../../CompanySettings/DesktopUpdates/data/redux"
import ModalErrorGeneral from "components/ModalErrorGeneral"
import HelmetDefaults from "./HelmetDefaults"

class BaseLayout extends Component {
  render() {
    const { children, clearError, error } = this.props

    return (
      <Fragment>
        <HelmetDefaults />
        {children}
        <ModalErrorGeneral isOpen={!!error} toggle={clearError} />
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    error: state.error
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearError: () => dispatch(clearError())
  }
}

const renderBaseLayout = Component => {
  return props => (
    <BaseLayout>
      <Component {...props} />
    </BaseLayout>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseLayout))
export { renderBaseLayout }
