import { getProducts, searchProducts } from "data/apis/foundry"
import { getPhoneModels } from "./phoneModels"
import { getOrganizationCatalog } from "data/apis/foundry/organizations"

async function getPhone(sku) {
  const getPhoneProduct = sku => searchProducts({ skus: [sku] })
  const [foundryProducts, enabledProducts] = await Promise.all([getPhoneProduct(sku), getPhoneModels()])

  const combinedProducts = combineFoundryProductsWithPhoneModels(foundryProducts, enabledProducts)
  const [product] = combinedProducts
  return product
}

async function getPhoneProducts() {
  const hardwareProducts = await getHardwareProducts()

  return (hardwareProducts || []).filter(p => p?.isForSale === true)
}

const getHardwareProducts = async () => {
  const getAllHardwareProducts = async () => {
    const catalog = await getOrganizationCatalog()
    return await getProducts(catalog, ["HW-HS", "HW"])
  }

  const [allProducts, phoneModels] = await Promise.all([getAllHardwareProducts(), getPhoneModels()])

  return combineFoundryProductsWithPhoneModels(allProducts, phoneModels)
}

function combineFoundryProductsWithPhoneModels(foundryProducts, phoneModels) {
  const phoneModelsOnly = foundryProducts.flatMap(product =>
    phoneModels
      .map(enabledProduct => (enabledProduct.sku === product.sku ? { ...product, ...enabledProduct } : false))
      .filter(Boolean)
  )

  return phoneModelsOnly
}

async function getPhoneBySku(sku) {
  if (!sku) return null
  const phoneModels = await getPhoneModels()
  const [matchingPhoneModel] = phoneModels.filter(phoneModel => phoneModel.sku === sku)
  return matchingPhoneModel
}

async function getPhoneByModel(model) {
  if (!model) return null
  const phoneModels = await getPhoneModels()
  const [matchingPhoneModel] = phoneModels.filter(phoneModel => phoneModel.model === model)
  return matchingPhoneModel
}

export { getPhoneBySku, getPhoneByModel, getPhoneProducts, getPhone }
