import {
  parsePhoneNumberFromString as _parsePhoneNumberFromString,
  formatNumber,
  getCountryCallingCode,
  isValidNumber as _isValidNumber
} from "libphonenumber-js"

/**
 * Parses a did into a human readable phone number
 *
 * @param {string} did
 * ie 12052021576 -> +1 205 202 1576
 */
function parseDidToPhoneNumber(did) {
  return did ? formatNumber(did, "International") : ""
}

function parsePhoneNumberFromString(did) {
  return _parsePhoneNumberFromString(did)
}

function isValidNumber(str, country) {
  if (!!str && str.length > 30) {
    return false
  }
  return country ? _isValidNumber(str, country, { extended: true }) : _isValidNumber(str, { extended: true })
}

export { parseDidToPhoneNumber, parsePhoneNumberFromString, getCountryCallingCode, isValidNumber }
