import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classNames from "classnames"

interface IBackdropProps {
  transparent?: boolean
}

const Backdrop: FuzzyFunctionalComponent<IBackdropProps> = ({ className, transparent, ...props }) => (
  <div className={classNames(className, "fuze-backdrop", { "fuze-backdrop--transparent": transparent })} {...props} />
)

export default Backdrop
