import React from "react"
import { FormattedMessage } from "react-intl"
import HubSidebarSubmenu from "./HubSidebarSubmenu"
import { useAsync } from "react-use"
import { findMatchingLocale } from "lib/translations"
import { canLoggedInThinkingphonesUserAccess } from "lib/user-session"
import { useIntl } from "react-intl"
import _zip from "lodash/zip"

import useActionRequiredLocationsTotalCount from "../../../Locations/hooks/useActionRequiredLocationsTotalCount"
import { ErrorBadgeWithTooltip } from "../../ErrorBadge/ErrorBadgeWithTooltip"

import useSidebarSubmenu from "hooks/useSidebarSubmenu"

import { useProtectedLinkSection, useProtectedLinks } from "@fuze/hub-authorization"
import { NavLink } from "@fuze/hub-router"

import useIsSkuOnContract from "hooks/useIsSkuOnMsaContract"
import { ALLOY_UTILITY_SKU } from "constants/skus"

import useConfiguration from "hooks/useConfiguration"
import HubSidebarBadge from "./HubSidebarBadge"
import { useDIDsAccess } from "DIDs/NumberRequests/Access"
import SidebarIcon from "./SidebarIcon"

const useMultiAppProtectedLinkSection = path => useProtectedLinkSection(path, ExtraProtectedSections)
const useMultiAppProtectedLinkSections = path => useProtectedLinks(path, ExtraProtectedLink)

const ExtraProtectedSections = ({ to, ...props }) => {
  if (to === "/troubleshooting") {
    return <TroubleshootingLink to={to} {...props} />
  }
  return <NavLink to={to} {...props} />
}

const ExtraProtectedLink = ({ to, ...props }) => {
  if (to === "/devices/utilities") {
    return <UtilityLink to={to} {...props} />
  }
  if (to === "/dids/requests") {
    return <PortingRequestsLink to={to} {...props} />
  }
  if (to === "/dids/port-orders") {
    return <PortingOrdersLink to={to} {...props} />
  }
  return <NavLink to={to} {...props} />
}

const UtilityLink = ({ ...props }) => {
  const [hasUtilitySku, isSkuLoaded] = useIsSkuOnContract(ALLOY_UTILITY_SKU)
  return hasUtilitySku && isSkuLoaded && <NavLink {...props} />
}

const PortingRequestsLink = ({ ...props }) => {
  const [hasAccess] = useDIDsAccess()

  return hasAccess ? <NavLink {...props} /> : null
}

const PortingOrdersLink = ({ ...props }) => {
  return null
}

const TroubleshootingLink = ({ ...props }) => {
  if (!canLoggedInThinkingphonesUserAccess()) {
    return null
  }
  return <NavLink {...props} />
}

const Badge = ({ path }) => path === "/locations" && <LocationBadge />
const BetaBadge = ({ path }) => {
  if (path !== "/dids/port-orders") {
    return null
  }
  return (
    <HubSidebarBadge>
      <FormattedMessage id="sidebar.beta" />
    </HubSidebarBadge>
  )
}

const LocationBadge = () => {
  const intl = useIntl()
  const { totalCount } = useActionRequiredLocationsTotalCount()

  return (
    <ErrorBadgeWithTooltip
      id="ide-bar__locations"
      errorCount={totalCount}
      tooltipMsg={intl.formatMessage({ id: "sidebar.errorLocations" })}
      placement="left"
      boundariesElement="window"
    />
  )
}

const SidebarItem = ({ subLinks, ...props }) => {
  if (!!subLinks) {
    return <SidebarLinkWithSubLinks {...props} subLinks={subLinks} />
  }
  return <SidebarLink {...props} />
}

const SidebarLink = ({ path, name, Icon, label }) => {
  const [Section, Link] = useMultiAppProtectedLinkSection(path)

  return (
    <Section>
      <li>
        <Link exact id={name}>
          {Icon && <Icon />}
          <span className="side-bar__label">{label}</span>
          <Badge path={path} />
        </Link>
      </li>
    </Section>
  )
}

const SidebarLinkWithSubLinks = ({ name, label, subLinks, Icon }) => {
  const [Links, hasAnyAccess] = useMultiAppProtectedLinkSections(subLinks.map(subLink => subLink.path))

  const { isSubmenuOpen, toggleSubMenu, isCurrentPageInSubmenu } = useSidebarSubmenu(subLinks)

  if (!hasAnyAccess) {
    return null
  }

  // _zip combines two (or more) lists together into a list of tuples
  const LinksWithMetadata = _zip(Links, subLinks)
  const locale = findMatchingLocale()

  return (
    <HubSidebarSubmenu
      isSubmenuOpen={isSubmenuOpen}
      onSubmenuToggle={toggleSubMenu}
      isActive={isCurrentPageInSubmenu}
      submenuName={name}
      isLoaded={true}
      toggleButtonTranslation={label}
      Icon={Icon}
    >
      {LinksWithMetadata.filter(([Link]) => !!Link).map(([Link, metadata]) => (
        <Link id={metadata.id} key={metadata.id} exact tabIndex={isSubmenuOpen ? "0" : "-1"}>
          <span className="link-text">{metadata.label?.[locale] || <FormattedMessage id={metadata.label} />}</span>
          <BetaBadge path={metadata.path} />
        </Link>
      ))}
    </HubSidebarSubmenu>
  )
}

const useSideBarIcons = routes => {
  const { value = {} } = useAsync(async () => {
    const iconFont = await import("@fuze/icon-font")
    return routes.reduce((components, route) => {
      const Component = iconFont[route.icon]
      components[route.name] = Component ? () => <Component className="side-bar__icon" /> : null
      return components
    }, {})
  })

  return value
}

export default function HubSidebar() {
  // TODO Handle inaccessible troubleshooting link on troubleshooting page - always show link, but give message on page if they can't access data
  const { hub } = useConfiguration()
  const icons = useSideBarIcons(hub.sidebar)
  const locale = findMatchingLocale()
  const { formatMessage } = useIntl()

  return (
    <ul id="sidebar-hub">
      {hub.sidebar.map(item => {
        const legacyIcon = () => <SidebarIcon icon={item.icon || `icon-${item.name}`} />

        return (
          <SidebarItem
            key={item.name}
            path={item.path}
            name={item.name}
            Icon={icons[item.name] || legacyIcon}
            label={item.label?.[locale] || formatMessage({ id: item.label })}
            subLinks={item.subLinks}
          />
        )
      })}
    </ul>
  )
}
