import React from "react"

/**
 * Wrap with html bold <b> portions of string that contain subString
 * @param value Text blob which might contain subStrings
 * @param pattern A string to wrap in html bold
 * @returns React element with parts of Text that contain occurrences of subString wrapped in <b> while other parts wrapped in <span>
 *
 *   example: PartialHighlight(value: "This is a DumMy string", pattern: "dummy") == <span>This is a </span><b>DumMy</b><span> string</span>
 */
function PartialHighlight({ value, pattern }) {
  if (!pattern.length) {
    return value
  }
  const parts = value.split(new RegExp("(" + pattern + ")", "gi"))
  return parts
    .map(part =>
      part.toLowerCase() === pattern.toLowerCase() ? <b key={part}>{part}</b> : <span key={part}>{part}</span>
    )
    .reduce((prev, curr) => [prev, curr])
}

export default PartialHighlight
