import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Popover, PopoverBody } from "reactstrap"
import { supportData } from "constants/support"

import styles from "./SupportPopover.module.scss"

/**
 * Usage:
 *
 * <Button id="identifyingName">Click me!</Button>
 * <SupportPopover isOpen={isOpen} target="identifyingName" isWithinModal={isWithinModal}>
 * {(supportData) => <p>Error message. <a href={supportData.supportDocURL}>Click me for support</a></p>}
 * </SupportPopover>
 */
class SupportPopover extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    target: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]).isRequired,
    children: PropTypes.func.isRequired,
    isWithinModal: PropTypes.bool
  }

  render() {
    const { children, isWithinModal, onClearErrorMessage, ...otherProps } = this.props
    return (
      <Popover
        className={classNames(styles.supportPopover, "popover-danger", { [styles.supportPopoverModal]: isWithinModal })}
        placement="top-start"
        toggle={onClearErrorMessage}
        {...otherProps}
      >
        <PopoverBody>
          <div className={styles.supportPopoverBody}>{children(supportData)}</div>
        </PopoverBody>
      </Popover>
    )
  }
}

export default SupportPopover
