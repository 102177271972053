import { lazy } from "react"
import RedirectRoute from "components/RedirectRoute"
import { ProtectedHubLayoutRoute } from "components/layouts/HubLayout"
import { activateALinePath, legacyActivateALinePath } from "./Paths"

const ActivateALine = lazy(() => import("./ActivateALine"))

const LineRoutes = () => (
  <>
    <ProtectedHubLayoutRoute exact path={activateALinePath} Component={ActivateALine} />
    <RedirectRoute pathIn={legacyActivateALinePath} pathOut={activateALinePath} />
  </>
)

export default LineRoutes
