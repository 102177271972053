import {
  registerUrlProvider,
  registerBearerTokenProvider,
  registerImpersonatorBearerTokenProvider,
  registerOriginProvider,
  registerOrganizationProvider,
  registerErrorHandlerProvider
} from "@fuze/apis-cfm"

import getConfiguration from "data/configuration"
import { getToken, getNonImpersonatingToken } from "lib/token"
import getOrigin from "lib/origin"
import { getOrganization } from "lib/user-session"
import { errorHandler } from "../errorInterceptor"

export default function registerApiProviders() {
  registerUrlProvider(() => getConfiguration().then(data => data.cfm.url))
  registerBearerTokenProvider(async () => getToken())
  registerOriginProvider(getOrigin)
  registerOrganizationProvider(async () => getOrganization())
  registerErrorHandlerProvider(async () => errorHandler)
  registerImpersonatorBearerTokenProvider(async () => getNonImpersonatingToken())
}
