import React, { lazy } from "react"
import { ProtectedHubLayoutRoute } from "../components/layouts/HubLayout"
import { Switch } from "routing"
import { ContactRostersViewPath, ContactRostersViewDetailsPath } from "ContactRosters/Paths"
import { CONTACT_ROSTERS, useEnablements } from "data/enablements"

const UsersPage = lazy(() => import("users/UsersList/UsersPage"))
const UserProfile = lazy(() => import("./NewUserProfile/UserProfile"))
const UserDeactivateReassign = lazy(() => import("./DeactivateReassign/DeactivateReassign"))
const NewUser = lazy(() => import("./new-user/NewUserRouter"))
const NewUserNoService = lazy(() => import("./new-user/NewUserWithoutService"))
const UserUpgrade = lazy(() => import("./legacyServiceUpdate/LegacyServiceUpdate"))
const AdminsList = lazy(() => import("./Administrators/AdministratorsPage"))
const ContactRostersPage = lazy(() => import("ContactRosters/ContactRostersList/ContactRostersTab"))
const ContactRosterDetailsPage = lazy(() => import("ContactRosters/ContactRosterDetails/ContactRosterDetails"))

const UsersListPath = "/users"
const AdminListPath = "/users/administrators"

const Paths = Object.freeze({
  UserProfile: "/users/:userName",
  UserRatings: "/users/:userName/ratings",
  UserOverview: "/users/:userName/overview",
  UserAdd: "/users/new",
  UserAddNoService: "/users/new/noservice",
  UserClone: "/users/:userName/clone",
  ActivateDevice: "/users/:userName/device/activate",
  UserDeactivate: "/users/:userName/deactivate",
  UserServiceUpdate: "/users/:userName/update"
})

const Routes = () => {
  const isActiveOrganizationEnabledForContactRosters = useEnablements(CONTACT_ROSTERS)

  return (
    <>
      <Switch>
        <ProtectedHubLayoutRoute exact path={Paths.UserAdd} Component={NewUser} />
        <ProtectedHubLayoutRoute exact path={UsersListPath} Component={UsersPage} />
        <ProtectedHubLayoutRoute exact path={AdminListPath} Component={AdminsList} />
        <ProtectedHubLayoutRoute exact path={ContactRostersViewDetailsPath} Component={ContactRosterDetailsPage} />
        {isActiveOrganizationEnabledForContactRosters && (
          <ProtectedHubLayoutRoute exact path={ContactRostersViewPath} Component={ContactRostersPage} />
        )}
        <ProtectedHubLayoutRoute exact path={Paths.UserProfile} Component={UserProfile} />
        <ProtectedHubLayoutRoute exact path={Paths.UserRatings} Component={UserProfile} />
        <ProtectedHubLayoutRoute exact path={Paths.UserOverview} Component={UserProfile} />
      </Switch>
      <ProtectedHubLayoutRoute exact path={Paths.UserDeactivate} Component={UserDeactivateReassign} />
      <ProtectedHubLayoutRoute exact path={Paths.UserClone} Component={NewUser} />
      <ProtectedHubLayoutRoute exact path={Paths.UserAddNoService} Component={NewUserNoService} />
      <ProtectedHubLayoutRoute exact path={Paths.UserServiceUpdate} Component={UserUpgrade} />
    </>
  )
}

export default Routes
