import React, { KeyboardEventHandler, useEffect, useState } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classNames from "classnames"
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardEnd, KeyboardHome } from "../../constants/keyboardKeys"

export type tabId = string | number

interface ITabs {
  defaultSelected?: tabId
  onTabChange?: Function
}

interface ITabsContext {
  activeTab: tabId
  onKeyUp: Function
  onTabChange?: Function
  onTabClick: Function
  registerTab: Function
}

export const TabsContext = React.createContext<ITabsContext>(undefined)

const Tabs: FuzzyFunctionalComponent<ITabs> = ({ children, className, defaultSelected, onTabChange, ...props }) => {
  const [tabIds, setTabIds] = useState([])
  const [activeTab, setActiveTab] = useState(defaultSelected || tabIds?.[0])

  useEffect(() => {
    if (onTabChange) {
      onTabChange(activeTab)
    }
  }, [activeTab, onTabChange])

  return (
    <div className={classNames("fuze-tabs", className)} {...props}>
      <TabsContext.Provider value={{ activeTab, onKeyUp, onTabClick, registerTab }}>{children}</TabsContext.Provider>
    </div>
  )

  function onKeyUp(event: KeyboardEvent) {
    const { code } = event

    console.log(code)

    const currentIndex = tabIds.findIndex((id) => id === activeTab)
    const lastIndex = tabIds.length - 1
    let newActiveTab

    switch (code) {
      case KeyboardArrowLeft:
        if (currentIndex === 0) {
          newActiveTab = tabIds[lastIndex]
        } else {
          newActiveTab = tabIds[currentIndex - 1]
        }
        break
      case KeyboardArrowRight:
        if (currentIndex === lastIndex) {
          newActiveTab = tabIds[0]
        } else {
          newActiveTab = tabIds[currentIndex + 1]
        }
        break
      case KeyboardEnd:
        newActiveTab = tabIds[lastIndex]
        break
      case KeyboardHome:
        newActiveTab = tabIds[0]
        break
      default:
        return
    }

    setActiveTab(newActiveTab)
  }

  function onTabClick(value: tabId) {
    setActiveTab(value)
  }

  function registerTab(id: tabId) {
    if (tabIds.includes(id)) {
      return
    }
    setTabIds((state) => [...state, id])
  }
}

export default Tabs
