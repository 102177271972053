import React, { lazy } from "react"
import { Switch } from "routing"
import { ProtectedHubLayoutRoute } from "../components/layouts/HubLayout"

const Orders = lazy(() => import("./Orders"))

const ordersPath = "/orders"
const hardwareOrdersPath = "/orders/hardwareOrders"

const Routes = () => {
  return (
    <>
      <Switch>
        <ProtectedHubLayoutRoute exact path={ordersPath} Component={Orders} />
        <ProtectedHubLayoutRoute exact path={hardwareOrdersPath} Component={Orders} />
      </Switch>
    </>
  )
}

const OrdersAccess = ({ children }) => children(ordersPath)

export { OrdersAccess, ordersPath, hardwareOrdersPath }
export default Routes
