import { createGlobalState, useAsyncFn, useEffectOnce } from "react-use"
import { cachedAPICall, getActionRequiredLocationTotalCount, getAllActionRequiredLocations } from "../../data/locations"

const getActionRequiredLocationTotalCountCached = cachedAPICall(getActionRequiredLocationTotalCount)
const useGlobalActionReqTotalCount = createGlobalState(0)

function useActionRequiredLocationsTotalCount() {
  const [totalCount, setTotalCount] = useGlobalActionReqTotalCount()
  const [{ loading }, refreshTotalCount] = useAsyncFn(async invalidateCache =>
    setTotalCount(await getActionRequiredLocationTotalCountCached(invalidateCache))
  )

  useEffectOnce(refreshTotalCount)

  return { totalCount, loading, refreshTotalCount }
}

export function useAllActionRequiredLocations() {
  return useAsyncFn(getAllActionRequiredLocations)
}

export default useActionRequiredLocationsTotalCount
