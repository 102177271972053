import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import { ButtonProps } from "reactstrap"
import classNames from "classnames"

interface IButton {
  circle?: boolean
}
/**
 * Button
 *
 * Specs can be found: https://fuze.invisionapp.com/console/%5BL1%5D-Fuze_DesignSystem_Atoms-ckmxsjxtq4q7p016k6l3c94el/ckmxsmjjz089301zfbchva9od/play
 *
 */
const Button: FuzzyFunctionalComponent<ButtonProps & IButton> = ({
  block,
  children,
  circle,
  className,
  color = "primary",
  outline,
  size,
  tag,
  transparent,
  type = "button",
  ...props
}) => {
  let Tag = tag || "button"

  if (props.href) {
    Tag = "a"
  }

  return (
    <Tag
      className={classNames(
        "fuze-button",
        className,
        color && `fuze-button--${color}`,
        size && `fuze-button--${size}`,
        { "fuze-button--block": block },
        { "fuze-button--circle": circle },
        { "fuze-button--transparent": transparent },
        { "fuze-button--disembodied": !transparent && !circle && !block && !circle },
        { "fuze-button--link": Tag === "a" },
        { "fuze-button--outline": outline }
      )}
      type={type}
      {...props}
    >
      {children}
    </Tag>
  )
}

export default Button
