import _getDIDs from "@fuze/apis-foundry/dist/dids/functions/getDIDs"
import _searchDIDs from "@fuze/apis-foundry/dist/dids/functions/searchDIDs"

import { wrapFoundryFunction, wrapAllResults, wrapFoundryFunctionWithOrganizationWithPagination } from "./decorators"

const defaultPortStatuses = ["direct", "internal", "ported-in", "porting-in", "reserved"]

/**
 * @param {String[]} portStatuses A list of port statuses to filter on
 * @param {boolean} inUse If true, return only in use DIDs, if false return only DIDs not in use
 *
 * Defaults to only showing DIDs that are not automation numbers or toll-free numbers
 */
const getAllDIDs = ({
  portStatuses = defaultPortStatuses,
  inUse = false,
  q = null,
  pagination = {
    offset: 0,
    limit: 1000
  }
}) => wrapAllResults(_getDIDs)(portStatuses, inUse, false, false, q, null)

/**
 * Get the results from getAllDIDs but filter out invalid entries
 *
 */
export async function getDIDs(...args) {
  const dids = await getAllDIDs(...args)
  /**
   * validDID is a property returned from Foundry. It is true if the number can be parsed into E164 format and false otherwise
   */
  return dids.filter(did => did.validDID === true)
}

export const getDIDCount = ({ portStatuses = defaultPortStatuses, inUse = false, q = null }) =>
  wrapFoundryFunctionWithOrganizationWithPagination(_getDIDs)(portStatuses, inUse, false, false, q, null, {
    offset: 0,
    limit: 0
  })

export const searchDIDs = query => wrapFoundryFunction(_searchDIDs)(query)
