import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import InternalCheckbox, { IInternalCheckbox } from "./InternalCheckbox"

interface CheckboxProps {
  size?: "sm" | "small" | "md" | "medium" | "lg" | "large"
}

const Checkbox: FuzzyFunctionalComponent<IInternalCheckbox & CheckboxProps> = ({
  className,
  children,
  size = "medium",
  ...props
}) => {
  return (
    <InternalCheckbox className={classnames("fuze-checkbox", `fuze-checkbox--${size}`, className)} {...props}>
      {children}
    </InternalCheckbox>
  )
}

export default Checkbox
