import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Avatar from "components/Avatar"
import { getFullUserName } from "lib/string-utilities"
import PartialHighlight from "./PartialHighlight"
import { useResponsive } from "react-hooks-responsive"
import { breakpoints } from "constants/breakpoints"
import { Link } from "routing"
import { useAccess } from "components/permissions/Access"
import permissions from "constants/permissions"
import { buildUserProfilePath } from "users/Access"

const showAtAllBreakpoints = ["xs", "sm", "md", "lg", "xl"]

const UserDetails = ({
  user,
  isALink = false,
  showAvatar = showAtAllBreakpoints,
  isSmall = true,
  showDepartment = showAtAllBreakpoints,
  showLocation = showAtAllBreakpoints,
  highlight = "",
  isReadonly = false,
  isDisabled = false
}) => {
  const [userProfileAccess, path] = useAccess([permissions.users.view], buildUserProfilePath(user?.userName))
  const [size] = useResponsive(breakpoints)
  const hasDepartmentOrLocation =
    (user?.department && user?.department?.name) || (user?.location && user?.location?.name)

  const fullName = getFullUserName(user)
  const userName = fullName.length > 1 ? fullName : user?.userName
  const name = <PartialHighlight value={userName} pattern={highlight} />

  return (
    <div className={classNames("user-details", { "user-details--disabled": isDisabled })}>
      {showAvatar.includes(size) && (
        <Avatar
          avatar={user?.avatar}
          initials={userName
            ?.split(" ")
            .map(word => word?.[0])
            .join("")}
          classes={!isSmall && "avatar--static"}
          active={user?.active}
          isReadonly={isReadonly}
          userId={user?.id}
          size={isSmall && "small"}
        />
      )}
      <div className="user-details__information">
        <div className="user-details__username">
          {!isDisabled && isALink && userProfileAccess ? <Link to={path}>{name}</Link> : name}
        </div>
        {user && hasDepartmentOrLocation && (
          <div className="subHeading">
            {showDepartment.includes(size) && user?.department && user?.department?.description}
            {showDepartment.includes(size) &&
              showLocation.includes(size) &&
              user?.department &&
              user?.department?.description &&
              user?.location &&
              user?.location?.name &&
              ` · `}
            {showLocation.includes(size) && user?.location && user?.location?.name}
          </div>
        )}
      </div>
    </div>
  )
}

UserDetails.propTypes = {
  // user object
  user: PropTypes.object
}

export default UserDetails
