import React from "react"
import { FormattedMessage } from "react-intl"
import BackButton from "components/buttons/BackButton"
import classnames from "classnames"

const ActionWithBackButton = ({ className, wide, children, ...props }) => (
  <div className={className}>
    <BackButton className={classnames("back-button", { "back-button--not-wide": !wide })} {...props}>
      <FormattedMessage id={"common.cancel"} />
    </BackButton>
    {children}
  </div>
)

export default ActionWithBackButton
