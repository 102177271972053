import { stringify } from "query-string"

export const ContactRostersViewPath = "/users/contactRosters"
export const ContactRostersAdd = "/contactRosters/new"
export const ContactRostersViewDetailsPath = "/users/contactRosters/:contactRosterId/contacts"

export const buildContactRostersListPath = queryParams => {
  const withDefault = {
    ...queryParams
  }
  const serializedQueryParams = stringify(withDefault)
  return `/users/contactRosters?${serializedQueryParams}`
}

export const buildContactRosterContactsListPath = (contactRosterId, queryParams) => {
  const withDefault = {
    ...queryParams
  }
  const serializedQueryParams = stringify(withDefault)
  return `/users/contactRosters/${contactRosterId}/contacts?${serializedQueryParams}`
}

export function buildContactRostersViewDetailsPath(contactRosterId) {
  return `/users/contactRosters/${contactRosterId}/contacts`
}

export function buildContactRostersEditPath(rosterId) {
  return `/users/contactRosters/${rosterId}/update`
}

export function buildContactRostersDeletePath(rosterId) {
  return `/users/contactRosters/${rosterId}/delete`
}

export function buildAddUsersToRosterPath(rosterId) {
  return `/users/contactRosters/${rosterId}/users/add`
}

export function buildViewUsersOfRosterPath(rosterId) {
  return `/users/contactRosters/${rosterId}/users`
}
