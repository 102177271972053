import { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { getFullUserName } from "lib/string-utilities"
import { parseDidToPhoneNumber } from "lib/phone-number-utilities"
import { IconArrowsSwitch, IconFuze, IconUsers, IconUser, IconPhonePlus, IconDeskphone } from "@fuze/icon-font"

function getClassName(isRemovingService) {
  return `billing-event icon--bordered fuze-icon--${isRemovingService ? "red-80" : "green-80"}`
}

export function serviceIcon({ isRemovingService }) {
  const className = getClassName(isRemovingService)

  return (
    <div className={className}>
      <IconFuze className="billing-event__icon" />
    </div>
  )
}

export function userIcon({ isRemovingService }) {
  const className = getClassName(isRemovingService)
  return (
    <div className={className}>
      <IconUser className="billing-event__icon" />
    </div>
  )
}

export function phoneLineIcon({ isRemovingService }) {
  const className = getClassName(isRemovingService)
  return (
    <div className={className}>
      <IconPhonePlus className="billing-event__icon" />
    </div>
  )
}

export function usersIcon() {
  const className = getClassName(false)
  return (
    <div className={className}>
      <IconUsers className="billing-event__icon" />
    </div>
  )
}

export function hardwareIcon() {
  const className = getClassName(false)
  return (
    <div className={className}>
      <IconDeskphone className="billing-event__icon" />
    </div>
  )
}

export function upgradeIcon() {
  return (
    <div className="billing-event icon--bordered fuze-icon--yellow-80">
      <IconArrowsSwitch className="billing-event__icon" />
    </div>
  )
}

export function genericIcon() {
  return (
    <div className="billing-event icon--bordered">
      <IconFuze className="billing-event__icon" />
    </div>
  )
}

export function serviceTitle({ isRemovingService }) {
  if (isRemovingService) {
    return <FormattedMessage id={"events.serviceRemoved"} />
  }
  return <FormattedMessage id={"events.serviceAdded"} />
}

export function genericTitle({ action }) {
  return <FormattedMessage id={`events.${action}`} />
}

function deriveUserName(row) {
  if (!row || !row.targetUser) {
    return null
  }

  const name = getFullUserName(row.targetUser)
  const { userName } = row.targetUser
  return name || userName
}

export function serviceSubtitle(row) {
  const name = deriveUserName(row.targetUser)

  return (
    <>
      {name && <span className="service-subtitle__name">{name}</span>}
      {row.servicesAffected.length && (
        <span className="service-subtitle__affected-services">
          {row.servicesAffected.map(service => (
            <Fragment key={row.uowId}>
              <span>
                <FormattedMessage id={service} key={service} />
              </span>
              {row.did && <span className="ml-1">{parseDidToPhoneNumber(row.did)}</span>}
            </Fragment>
          ))}
        </span>
      )}
    </>
  )
}

export function userSubtitle(row) {
  const userName = getFullUserName(row.targetUser)

  if (userName.length > 1) {
    return userName
  }

  return row?.targetUser?.userName
}

export function phoneLineSubtitle(row) {
  return <>{row.lineDetails && <span>{parseDidToPhoneNumber(row.lineDetails.did)}</span>}</>
}

export function bulkUserCreateSubtitle(row) {
  return (
    <>
      {row.numberOfUsers && (
        <FormattedMessage id={"table.modals.bulkUsersAdded"} values={{ numberOfUsers: row.numberOfUsers }} />
      )}
    </>
  )
}

export function hardwareSubtitle(row) {
  return (
    row.order &&
    row.order.shippingLocation && (
      <FormattedMessage id={"table.modals.hardwareShippingTo"} values={{ location: row.order.shippingLocation }} />
    )
  )
}
