import React, { useContext } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classnames from "classnames"
import { Button } from "src"
import { TableActionsColumnContext } from "./TableActionsColumn"

interface ITableButtonsTrigger {}

const TableButtonsTrigger: FuzzyFunctionalComponent<ITableButtonsTrigger> = ({ children, className, ...props }) => {
  const tableActionsColumnContext = useContext(TableActionsColumnContext)

  if (!tableActionsColumnContext.breakpoint) {
    return null
  }

  return (
    <Button
      block
      className={classnames("fuze-table-buttons__trigger", className)}
      onClick={() => tableActionsColumnContext.setIsButtonsOpen(true)}
      {...props}
    >
      {children}
    </Button>
  )
}

export default TableButtonsTrigger
