import { createAsyncActionTypes, createSyncActionType } from "../../../ducks/utils"

export const uploadFileAction = createSyncActionType("tailor", "file", "UPLOAD")
export const downloadFileActions = createAsyncActionTypes("tailor", "file", "DOWNLOAD")
export const fileValidationAction = createSyncActionType("tailor", "file", "VALIDATION")
export const userLoadActions = createAsyncActionTypes("tailor", "users", "LOAD")
export const foundryValidationActions = createAsyncActionTypes("tailor", "validation", "LOAD")
export const departmentCheckActions = createAsyncActionTypes("tailor", "departments", "CHECK")
export const departmentCreationActions = createAsyncActionTypes("tailor", "departments", "CREATE")
export const provisioningActions = createAsyncActionTypes("tailor", "bulk", "PROVISION")

export const sendNotificationAction = createSyncActionType("tailor", "sendNotification", "CHANGE")
export const invoicingStartDateAction = createSyncActionType("tailor", "invoicingStartDate", "CHANGE")

export const isTicketRequiredRetrieveActions = createAsyncActionTypes("tailor", "isTicketRequired", "RETRIEVE")
export const setUnitOfWorkTokenAction = createSyncActionType("tailor", "supportTicket", "TOKEN")

export const getPermissionsActions = createAsyncActionTypes("tailor", "permissions", "GET")
export const resetStateAction = createSyncActionType("tailor", "reset", "CHANGE")
export const selectLocationAction = createSyncActionType("tailor", "selectedLocation", "CHANGE")
export const selectServiceBundleAction = createSyncActionType("tailor", "selectedBundle", "CHANGE")
export const setVoiceProductAction = createSyncActionType("tailor", "voiceProduct", "CHANGE")
export const setVideoProductAction = createSyncActionType("tailor", "videoProduct", "CHANGE")
export const removeFileAction = createSyncActionType("tailor", "file", "CHANGE")
