import React, { useState } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classnames from "classnames"

interface ITableActionsColumnContext {
  breakpoint?: string
  isButtonsOpen: boolean
  setIsButtonsOpen: any
}

export const TableActionsColumnContext = React.createContext<ITableActionsColumnContext>(undefined)

interface ITableActionsColumn {
  align?: "left" | "right"
  breakpoint?: "xs" | "sm" | "md" | "lg" | "xl"
}

const TableActionsColumn: FuzzyFunctionalComponent<ITableActionsColumn> = ({
  align,
  breakpoint,
  children,
  className,
  ...props
}) => {
  const [isButtonsOpen, setIsButtonsOpen] = useState(false)

  return (
    <TableActionsColumnContext.Provider value={{ breakpoint, isButtonsOpen, setIsButtonsOpen }}>
      <div
        className={classnames(
          "fuze-table-actions__column",
          className,
          breakpoint && `fuze-table-actions__responsive--${breakpoint}`,
          {
            "fuze-table-actions__column--align-right": align === "right",
          }
        )}
        {...props}
      >
        {children}
      </div>
    </TableActionsColumnContext.Provider>
  )
}

export default TableActionsColumn
