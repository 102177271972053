import classnames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"
import { InfoButton } from "components/buttons/InfoButton"
import { getFullUserName, getInitialsFromFullName } from "lib/string-utilities"
import { Picture } from "@fuze/apis-contactive/dist/contacts/models/Origins"
import { Avatar as FuzzyAvatar, AvatarLabel, AvatarInitials, AvatarImage, AvatarIcon } from "@fuze/react-fuzzy"
import { IconLock } from "@fuze/icon-font"

type AvatarProps = {
  children?: any
  avatar?: Picture
  initials?: string
  className?: string
  classes?: string
  active?: boolean
  Icon?: Function
  isReadonly?: boolean
  userId?: string
  size?: string
}
const Avatar = ({
  avatar,
  initials = "",
  children,
  className,
  classes = "",
  active = true,
  isReadonly = false,
  Icon,
  userId,
  size
}: AvatarProps) => {
  const { formatMessage } = useIntl()
  const classNames = classnames("avatar", className, classes, { inactive: !active }, { "avatar--locked": isReadonly })
  const arialLabelId = isReadonly ? "avatarAria.avatarReadOnly" : "avatarAria.avatar"

  return (
    <FuzzyAvatar className={classNames} aria-label={formatMessage({ id: arialLabelId })} role="img" size={size}>
      {initials && <AvatarInitials>{initials}</AvatarInitials>}
      {avatar?.smallUrl && <AvatarImage backgroundImage={avatar?.smallUrl} />}
      {Icon && (
        <AvatarIcon>
          <Icon />
        </AvatarIcon>
      )}
      {isReadonly && (
        <InfoButton
          anchor={
            <AvatarIcon className="avatar--locked">
              <div id={`readonly-${userId}`}>
                <IconLock className="avatar__icon" />
              </div>
            </AvatarIcon>
          }
          id={`readonly-${userId}`}
        >
          <FormattedMessage id={"users.readonly"} />
        </InfoButton>
      )}
      {children && <AvatarLabel>{children}</AvatarLabel>}
    </FuzzyAvatar>
  )
}

export function AvatarWithName({ children, className, Icon, user, ...props }) {
  if (!user) return null

  const fullName = user?.displayName || getFullUserName(user)
  const initials = getInitialsFromFullName(fullName)

  return (
    <Avatar avatar={user.avatar} className="avatar--with-name" Icon={Icon} initials={initials} {...props}>
      <div className="username">{fullName}</div>
      {children && <div className="user__additional-details">{children}</div>}
    </Avatar>
  )
}

export default Avatar
