import { lazy } from "react"

import { ProtectedNoSidebarLayoutRoute } from "components/layouts/HubLayout"

import { migrationDashboardPath, migrationStatusPath, migrationPlanPath } from "./Paths"

const AlloyMigrationDashboard = lazy(() => import("./components/Dashboard/AlloyMigrationDashboard"))
const AlloyMigrationStatus = lazy(() => import("./components/StatusPage/AlloyMigrationStatus"))
const AlloyMigrationPlan = lazy(() => import("./components/PlanPage/AlloyMigrationPlanActionsTabs"))

const Routes = () => (
  <>
    <ProtectedNoSidebarLayoutRoute exact path={migrationDashboardPath} Component={AlloyMigrationDashboard} />
    <ProtectedNoSidebarLayoutRoute exact path={migrationStatusPath} Component={AlloyMigrationStatus} />
    <ProtectedNoSidebarLayoutRoute exact path={migrationPlanPath} Component={AlloyMigrationPlan} />
  </>
)

export default Routes
