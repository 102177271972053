import React from "react"
import classnames from "classnames"
import { FuzzyFunctionalComponent } from "@interfaces/common"

interface IModalTitle {}

const ModalTitle: FuzzyFunctionalComponent<IModalTitle> = ({ children, className, ...props }) => {
  return (
    <h1 className={classnames(className, "fuze-modal__title modal-title")} {...props}>
      {children}
    </h1>
  )
}

export { ModalTitle }
