import React from "react"
import OrdersModalContent from "./OrdersModalContent"
import { TripleRowSingleDoubleColTableWithFooter } from "./tables"
import { FormattedMessage, useIntl } from "react-intl"
import { getFullUserName } from "lib/string-utilities"
import UserDetails from "../../components/UserDetails"
import styles from "./ServiceChangedModal.module.scss"
import BillingImpactMessage from "./BillingImpactMessage"
import { Modal, ModalHeader, ModalBody, ModalSubHeader, ModalFooter } from "@fuze/react-fuzzy"
import ModalFooterButtons from "components/buttons/ModalFooterButtons"

const ServiceChangedModal = ({
  isOpen,
  onToggle,
  servicesAffected,
  originalService,
  updatedService,
  billing,
  completedBy,
  targetUser,
  loadDate
}) => {
  const intl = useIntl()

  if (!servicesAffected || typeof originalService !== "object") {
    return null
  }
  return (
    <Modal color="secondary" id="service-changed-modal" isOpen={isOpen} toggle={onToggle}>
      {targetUser && (
        <ModalHeader>
          {intl.formatMessage(
            { id: "table.modals.serviceChangedTitle" },
            { service: intl.formatMessage({ id: servicesAffected[0] }), user: getFullUserName(targetUser) }
          )}
        </ModalHeader>
      )}
      <ModalSubHeader>
        <OrdersModalContent date={loadDate} completedBy={completedBy} />
      </ModalSubHeader>

      <ModalBody>
        <TripleRowSingleDoubleColTableWithFooter
          firstRowTitle={<FormattedMessage id={"table.modals.addedTo"} />}
          firstRowData={
            targetUser && (
              <UserDetails user={targetUser} isALink={true} showAvatar={["md", "lg", "xl"]} isSmall={false} />
            )
          }
          secondRowLeftSideTitle={<FormattedMessage id={"table.modals.originalService"} />}
          secondRowRightSideTitle={<FormattedMessage id={"table.modals.updatedService"} />}
          thirdRowLeftSideData={
            <ul className="mb-0">
              <li key={originalService}>
                {originalService.messageId ? (
                  <FormattedMessage
                    id={originalService.messageId}
                    values={{ details: originalService.details ? `(${originalService.details})` : null }}
                  />
                ) : (
                  originalService.details
                )}
              </li>
            </ul>
          }
          thirdRowRightSideData={
            <>
              <div className={styles.mobileText}>
                <FormattedMessage id={"table.modals.updatedService"} />
              </div>
              <ul className="mb-0">
                <li key={updatedService}>
                  {updatedService.messageId ? (
                    <FormattedMessage
                      id={updatedService.messageId}
                      values={{ details: updatedService.details ? `(${updatedService.details})` : null }}
                    />
                  ) : (
                    updatedService.details
                  )}
                </li>
              </ul>
            </>
          }
          billingImpact={billing.billingImpact !== undefined && <BillingImpactMessage billing={billing} />}
        />
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons onAction={onToggle}>
          <FormattedMessage id="common.finish" />
        </ModalFooterButtons>
      </ModalFooter>
    </Modal>
  )
}

export default ServiceChangedModal
