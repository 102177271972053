import { lazy } from "react"

import { ProtectedHubLayoutRoute } from "components/layouts/HubLayout"
import RedirectRoute from "../components/RedirectRoute"

import {
  devicesHomepagePath,
  activateDevicePath,
  purchasePhonesPath,
  purchasePhonePath,
  claimDevicesPath,
  claimReportPath
} from "./Paths"

const Homepage = lazy(() => import("./Homepage/Homepage"))
const ClaimDevices = lazy(() => import("./ClaimDevices/ClaimDevices"))
const ClaimReport = lazy(() => import("./ClaimReports/ClaimReport"))
const OrderPhones = lazy(() => import("./OrderPhones/CompletePhoneOrderPage"))
const Phones = lazy(() => import("./OrderPhones/PhonesListPage"))
const ActivateDevices = lazy(() => import("./DeviceBulkActivation/DeviceBulkActivation"))

const buildPurchasePhonePath = sku => `/devices/phones/order/${sku}`

const buildClaimReportPath = claimId => `/devices/claims/${claimId}`

const DevicesRoutes = ({ sku }) => (
  <>
    <ProtectedHubLayoutRoute exact path={devicesHomepagePath} Component={Homepage} />
    <ProtectedHubLayoutRoute exact path={claimDevicesPath} Component={ClaimDevices} />
    <ProtectedHubLayoutRoute exact path={claimReportPath} Component={ClaimReport} />
    <ProtectedHubLayoutRoute exact path={purchasePhonesPath} Component={Phones} />
    <ProtectedHubLayoutRoute exact path={purchasePhonePath} Component={OrderPhones} />
    <ProtectedHubLayoutRoute exact path={activateDevicePath} Component={ActivateDevices} />

    {/* Legacy routes */}
    <RedirectRoute exact pathIn="/phones" pathOut={purchasePhonesPath} />
    <RedirectRoute exact pathIn={`/devices/phones/order/${sku}`} pathOut={buildPurchasePhonePath(sku)} />
  </>
)

export { buildPurchasePhonePath, buildClaimReportPath }
export default DevicesRoutes
