import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"

import DesktopUpdatesReducer from "./CompanySettings/DesktopUpdates/data/redux"
import GuestsReducer from "./CompanySettings/Guests/data/redux"
import DevicesReducer from "./Devices/data/redux"
import BulkProvisioningReducer from "./BulkProvision/data/reducers"
import OrdersReducer from "./Orders/data/ducks/reducers"
import UserProfileReducer from "users/NewUserProfile/data/ducks/reducers"
import { combineReducers } from "redux"
import { reduxErrorHandler } from "./ducks/utils"
import { FormattedMessage } from "react-intl"
import React from "react"

const reducer = combineReducers({
  DesktopUpdates: DesktopUpdatesReducer,
  Guests: GuestsReducer,
  Devices: DevicesReducer,
  BulkProvision: BulkProvisioningReducer,
  Orders: OrdersReducer,
  UserProfile: UserProfileReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function store() {
  return createStore(
    reducer,
    {
      Devices: { users: [] },
      Orders: {
        orders: [],
        users: [],
        locations: [],
        departments: [],
        jobs: [],
        products: [],
        selectedUserFilter: { value: "showAll", label: <FormattedMessage id={"loading"} /> },
        pagination: { hasMore: false }
      },
      BulkProvision: {},
      UserProfile: {
        handsets: []
      }
    },
    composeEnhancers(applyMiddleware(thunk, reduxErrorHandler))
  )
}
