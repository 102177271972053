import React, { useContext } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classnames from "classnames"
import { TableActionsColumnContext } from "./TableActionsColumn"
import Backdrop from "../Generic/Backdrop"

interface ITableButtons {
  breakpoint?: "xs" | "sm" | "md" | "lg" | "xl"
}

const TableButtons: FuzzyFunctionalComponent<ITableButtons> = ({ breakpoint, children, className, ...props }) => {
  const tableActionsColumnContext = useContext(TableActionsColumnContext)

  const onClose = () => tableActionsColumnContext.setIsButtonsOpen(false)

  return (
    <>
      {tableActionsColumnContext.isButtonsOpen && <Backdrop onClick={onClose} transparent />}
      <div
        className={classnames("fuze-table-buttons", className, breakpoint, {
          "fuze-table-buttons--open fuze-backdrop__sibling": tableActionsColumnContext.isButtonsOpen,
        })}
        onClick={onClose}
        {...props}
      >
        {children}
      </div>
    </>
  )
}

export default TableButtons
