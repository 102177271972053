import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { ReactComponent as BuildingIcon } from "../../images/building.svg"
import iconStyles from "../../components/icon/Icon.module.scss"

import styles from "./LineDetails.module.scss"

const LineDetails = ({ details }) => {
  return (
    <Row>
      <Col xs={2} className={classNames(styles.avatarCol, styles.mobileHiddenCol)}>
        <span className={classNames(iconStyles.buildingWrapper)}>
          <BuildingIcon className={classNames(iconStyles.purple, iconStyles.tall)} />
        </span>
      </Col>
      <Col className={styles.information}>
        <div>
          <FormattedMessage
            id={"table.modals.phoneNumber"}
            values={{
              did: <span className={styles.informationValue}>{details.did}</span>
            }}
          />
        </div>
        <div>
          <FormattedMessage
            id={"table.modals.location"}
            values={{
              location: <span className={styles.informationValue}>{details.location}</span>
            }}
          />
        </div>
        <div>
          <FormattedMessage
            id={"table.modals.servicePlan"}
            values={{
              plan: <span className={styles.informationValue}>{details.plan}</span>
            }}
          />
        </div>
      </Col>
    </Row>
  )
}

LineDetails.propTypes = {
  // details object
  details: PropTypes.object
}

export default LineDetails
