import { useHistory, Redirect, matchPath } from "routing"
import useRoutes from "components/authentication/useRoutes"
import { error404Path } from "./Paths"

const Redirect404 = () => {
  const history = useHistory()
  const routes = useRoutes()
  const pathname = history.location.pathname
  // remove possible trailing slashes from the pathname if its not the home path
  const routePathname = pathname === "/" ? pathname : pathname.replace(/\/$/, "")
  const isValidRoute = routes.find(route => matchPath(routePathname, { path: route.path, exact: true, strict: true }))

  return isValidRoute ? null : <Redirect to={error404Path} />
}

export default Redirect404
