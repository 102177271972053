import { logJavascriptError } from "lib/logging/logging"

import _keyBy from "lodash/keyBy"
import _compact from "lodash/compact"

import { createSelector } from "reselect"
import { getSelector } from "../../eventTypes"

function normalize(state) {
  return {
    ...state,
    entities: {
      departments: _keyBy(state.departments, "id"),
      jobs: _keyBy(state.jobs, "unitOfWorkId"),
      locations: _keyBy(state.locations, "id"),
      orders: _keyBy(state.orders, "uowId"),
      products: _keyBy(state.products, "sku"),
      productsById: _keyBy(state.products, "id"),
      services: _keyBy(state.services, "id"),
      extensions: _keyBy(state.extensions, "service.id"),
      // This is a user lookup by username
      users: _keyBy(state.users, "userName"),
      usersById: _keyBy(state.users, "userId")
    }
  }
}

export const ordersSelector = createSelector(
  state => state.Orders,
  state => normalize(state.Orders),
  (ordersState, normalizedState) => {
    return {
      ordersLoading: ordersState.ordersLoading,
      orders: ordersState.orders.map(order => orderSelector(ordersState, normalizedState, order.uowId)),
      selectedEventFilter: normalizedState.selectedEventFilter,
      selectedDateRangeFilter: normalizedState.selectedDateRangeFilter
    }
  }
)

const entityCostReducer = (acc, entry) => {
  let price = 0
  if (entry.type === "DELETE") {
    price = price - (entry.prev.price ? parseFloat(entry.prev.price) : 0)
  } else {
    price =
      price +
      (entry.cur.price ? parseFloat(entry.cur.price) : 0) -
      (entry.prev.price ? parseFloat(entry.prev.price) : 0)
  }

  return acc + price
}
const hasCost = event => _compact(event.entities.filter(entity => !!entity.cur.price || !!entity.prev.price)).length
// if event has price info anywhere - return calculated cost, otherwise return undefined (in order not to return a false 0 number)
// todo fix cost for deleted services (if entity.type === deleted subtract the cost)
export const calcCostSelector = event => (hasCost(event) ? event.entities.reduce(entityCostReducer, 0) : undefined)

export const orderSelector = (state, normalizedState, uowId) => {
  const event = normalizedState.entities.orders[uowId]

  try {
    const selector = getSelector(event.action)
    if (selector) {
      return selector(event, normalizedState)
    }
    return null
  } catch (e) {
    console.error(e)
    logJavascriptError(
      "Orders selector error",
      `error message: ${e.message}, uowId: ${uowId}, event action: ${event.action}`
    )
    return null
  }
}
