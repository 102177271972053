import React, { useState, useEffect } from "react"

import ActionWithBackButton from "components/buttons/ActionWithBackButton"
import LoadingButton from "components/buttons/LoadingButton"
import SupportPopover from "components/support/SupportPopover"

import { FormattedMessage, injectIntl } from "react-intl"
import classNames from "classnames"

import _uniqBy from "lodash/uniqBy"

const ActionProcessingButtons = ({
  color,
  isValid,
  onAction,
  isProcessing,
  id,
  children,
  onCancel,
  className,
  ...otherProps
}) => {
  const getColor = () => {
    // TODO: Secondary (purple) modals and gray (editable header) modals should have a button style of their own
    if (color === "gray" || color === "secondary") return "primary"

    return color
  }

  return (
    <ActionWithBackButton onCancel={onCancel} className={classNames("buttons", className)}>
      <LoadingButton
        id={id}
        isLoading={isProcessing}
        onClick={onAction}
        color={getColor()}
        disabled={!isValid}
        {...otherProps}
      >
        {children}
      </LoadingButton>
    </ActionWithBackButton>
  )
}

function translateValidationErrors(intl, validationErrors) {
  const c = _uniqBy(validationErrors, "code")
    .map(error => intl.formatMessage({ id: error.code, defaultMessage: error.message }))
    .join(" ")
  return c
}

const ActionErrorHandling = injectIntl(({ intl, target, errors, ...props }) => {
  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    const { validationErrors, isFailed, isTimedOut, miscError } = errors

    let newErrorMessage = ""

    if (validationErrors && !!validationErrors.length) {
      newErrorMessage = "provision.errors.validation"
    } else if (isFailed) {
      newErrorMessage = "provision.errors.failed.to.submit"
    } else if (isTimedOut) {
      newErrorMessage = "provision.errors.processing.failure"
    } else if (miscError) {
      newErrorMessage = miscError
    }

    setErrorMessage(newErrorMessage)
  }, [errors])

  const onClearErrorMessage = () => {
    if (props.onClearErrorMessage) props.onClearErrorMessage()

    setErrorMessage("")
  }
  const domTarget = document.querySelector(`#${target}`)

  return (
    <>
      {domTarget && (
        <SupportPopover isOpen={!!errorMessage} target={target} toggle={onClearErrorMessage} {...props}>
          {supportData => (
            <p>
              {!!errorMessage && (
                <FormattedMessage
                  id={errorMessage}
                  values={{
                    errors:
                      errors && errors.validationErrors && translateValidationErrors(intl, errors.validationErrors),
                    supportLink: (
                      <a href={supportData.supportDocURL} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="common.contactSupport" />
                      </a>
                    )
                  }}
                />
              )}
            </p>
          )}
        </SupportPopover>
      )}
    </>
  )
})

export default ActionProcessingButtons
export { ActionErrorHandling, translateValidationErrors }
