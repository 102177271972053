import {
  searchUsers as foundrySearchUsers,
  searchUsersByQueryWithoutPagination,
  searchServices,
  getUsersByIds,
  getUsersByIdsAnyOrganization,
  searchUsersByUsername,
  getUsersExtensions
} from "./apis/foundry"
import { categorizeServices } from "./services"
import { getInitialsFromFullName } from "lib/string-utilities"
import { services, locations, departments, avatars } from "./user-resolvers"
import { products } from "./service-resolvers"
import { buildQuery } from "./query/queryEntities"
import { allLocations } from "data/user-resolvers"

function getFullName(user) {
  return user.firstName + " " + user.lastName
}

function getDisplayUser(user) {
  const fullName = getFullName(user)
  return {
    ...user,
    fullName,
    initials: getInitialsFromFullName(fullName)
  }
}

function getUsers(ids) {
  return getUsersByIds(ids)
}

async function getUserWithDepartmentAndLocation(id) {
  const [user] = await getUsersWithDepartmentsAndLocations([id])
  return user
}

function getUsersWithLocations(ids) {
  return buildQuery()
    .with(locations())
    .execute(() => getUsersByIds(ids))
}

function getUsersWithDepartmentsAndLocations(ids) {
  return buildQuery()
    .with(locations())
    .with(departments())
    .execute(() => getUsersByIds(ids))
}

function getUsersWithDepartmentsAndNonAllocatedLocations(ids) {
  return buildQuery()
    .with(allLocations())
    .with(departments())
    .execute(() => getUsersByIds(ids))
}

function getUsersOfAnyOrganizationWithDepartmentsAndLocations(ids) {
  return buildQuery()
    .with(locations())
    .with(departments())
    .execute(() => getUsersByIdsAnyOrganization(ids))
}

function getUsersWithDepartmentsLocationsAndAvatars(ids) {
  return buildQuery()
    .with(locations())
    .with(departments())
    .with(avatars())
    .execute(() => getUsersByIds(ids))
}

async function searchUsers(query) {
  return await buildQuery()
    .with(locations())
    .with(departments())
    .with(avatars())
    .execute(() => foundrySearchUsers(query))
}

async function searchUsersByQuery(query) {
  return await buildQuery()
    .with(locations())
    .with(departments())
    .with(avatars())
    .execute(() => searchUsersByQueryWithoutPagination(query))
}

async function getUserByUsername(username) {
  const [user] = await getUsersByUsername([username])

  return user
}

async function getUsersByUsername(usernames) {
  const users = await buildQuery()
    .with(allLocations())
    .with(departments())
    .with(avatars())
    .execute(() => searchUsersByUsername(usernames))
  return users
}

async function getUserWithServices(userId) {
  const [user] = await getUsersWithServices([userId])
  return user
}

const servicesWithProducts = async userIds => {
  return await buildQuery()
    .with(products())
    .execute(() => searchServices({ users: userIds }))
}

async function getUsersWithServices(userIds) {
  return buildQuery()
    .with(services(servicesWithProducts))
    .with(locations())
    .with(departments())
    .execute(() => getUsersByIds(userIds))
}

async function getUsersWithServicesByUsername(usernames) {
  return buildQuery()
    .with(services(servicesWithProducts))
    .with(locations())
    .with(departments())
    .execute(() => searchUsersByUsername(usernames))
}

async function getUserWithServicesByUsername(username) {
  const [user] = await getUsersWithServicesByUsername([username])
  if (!user) {
    return undefined
  }
  const { voiceServices, videoServices, faxServices } = categorizeServices(user.services)

  return {
    ...user,
    voiceService: voiceServices[0],
    videoService: videoServices[0],
    faxService: faxServices[0]
  }
}

async function searchUsersExtensions(query) {
  // getUsersExtensions() does not have the same formatting as other foundry endpoints
  // and needs to be tweaked to work with QueryEntities
  const formatDataForQueryEntities = data =>
    data.map(user => ({ ...user, id: user.userId, organization: { code: user.organizationCode } }))

  return buildQuery()
    .with(avatars())
    .execute(() => getUsersExtensions(query).then(formatDataForQueryEntities))
}

async function getUsersWithDepartmentsByUserName(usernames) {
  return buildQuery()
    .with(locations())
    .with(departments())
    .with(avatars())
    .execute(() => searchUsersByUsername(usernames))
}

async function getUsersWithAvatarsByUserName(usernames) {
  return buildQuery()
    .with(avatars())
    .execute(() => searchUsersByUsername(usernames))
}

export {
  searchUsers,
  searchUsersByQuery,
  searchUsersExtensions,
  getDisplayUser,
  getUsers,
  getUserByUsername,
  getUsersByUsername,
  getUserWithServices,
  getUsersWithServices,
  getUsersWithLocations,
  getUsersWithDepartmentsAndNonAllocatedLocations,
  getUserWithDepartmentAndLocation,
  getUsersWithDepartmentsAndLocations,
  getUsersWithDepartmentsLocationsAndAvatars,
  getUsersOfAnyOrganizationWithDepartmentsAndLocations,
  getUserWithServicesByUsername,
  getUsersWithDepartmentsByUserName,
  getUsersWithAvatarsByUserName
}
