import React from "react"
import classnames from "classnames"

export default function ImageRow({ children, className = "", isResponsive = false, ...props }) {
  return (
    <div className={classnames("image-row", className, { "image-row--responsive": isResponsive })} {...props}>
      {children}
    </div>
  )
}
