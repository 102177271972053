import { primaryRoles } from "components/layouts/components/scope"
import { getUser, getOrganization, getOriginalImpersonatorOrganization } from "@fuze/services-auth/dist/user-session"
export { getUser, getOrganization, getOriginalImpersonatorOrganization }

export const FUZE_ORG_CODE = "thinkingphones"

export function isOrganizationThinkingphones(orgCode) {
  return orgCode === FUZE_ORG_CODE
}

export function isLoggedInUserRegionalAdmin() {
  return getUser()?.adminScope?.type === primaryRoles.regional
}
export function loggedInUserManagedLocations() {
  return getUser()?.adminScope?.managedLocations || []
}

export function isLoggedInUserThinkingphones() {
  return getUser().tenantKey === FUZE_ORG_CODE
}

export function isLoggedInOrganizationThinkingphones() {
  return getOrganization() === FUZE_ORG_CODE
}

export function doesUserHavePermission(permission) {
  return getUser().permissions && getUser().permissions.includes(permission)
}

export const isDevEnv = process.env.NODE_ENV === "development"

export function canLoggedInThinkingphonesUserAccess() {
  const isLoggedInAsFuzeUser = isLoggedInUserThinkingphones()
  const isSelectedOrgFuze = isOrganizationThinkingphones(getOrganization())
  const fuzeViewingFuze = isLoggedInAsFuzeUser && isSelectedOrgFuze
  const nonFuzeViewingNonFuze = !isLoggedInAsFuzeUser && !isSelectedOrgFuze
  return nonFuzeViewingNonFuze || fuzeViewingFuze
}
