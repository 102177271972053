import useConfiguration from "hooks/useConfiguration"

export default function useDeviceImageResolver() {
  const { s3images } = useConfiguration()
  const imageUrl = s3images.url
  return product => {
    if (product?.image) {
      return {
        ...product,
        image: `${imageUrl}/devices/${product.image}`
      }
    }
    return product
  }
}
