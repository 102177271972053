import React from "react"
import OrdersModalContent from "./OrdersModalContent"
import { SingleRowSingleColTableWithFooter } from "./tables"
import { FormattedMessage, injectIntl } from "react-intl"
import { getFullUserName } from "lib/string-utilities"
import UserDetails from "../../components/UserDetails"
import BillingImpactMessage from "./BillingImpactMessage"
import { Modal, ModalHeader, ModalBody, ModalSubHeader, ModalFooter } from "@fuze/react-fuzzy"
import ModalFooterButtons from "components/buttons/ModalFooterButtons"
import _camelCase from "lodash/camelCase"

const UserModal = ({ isOpen, onToggle, billing, completedBy, targetUser, loadDate, isRemovedModal, intl, action }) => {
  const titleId = `table.modals.${_camelCase(action)}Title`
  const cellTitle = isRemovedModal ? "table.modals.deactivatedUser" : "table.modals.newUser"

  return (
    <Modal id="user-modal" isOpen={isOpen} toggle={onToggle} color="primary">
      <ModalHeader>{intl.formatMessage({ id: titleId }, { user: getFullUserName(targetUser || {}) })}</ModalHeader>
      <ModalSubHeader>
        <OrdersModalContent date={loadDate} completedBy={completedBy} />
      </ModalSubHeader>
      <ModalBody>
        <SingleRowSingleColTableWithFooter
          title={<FormattedMessage id={cellTitle} />}
          data={
            <UserDetails
              user={targetUser}
              isALink={true}
              showAvatar={["md", "lg", "xl"]}
              isSmall={false}
              isDisabled={targetUser?.active || true}
            />
          }
          billingImpact={
            billing.billingImpact !== undefined && (
              <BillingImpactMessage billing={billing} isRemovedModal={isRemovedModal} />
            )
          }
        />
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons onAction={onToggle}>
          <FormattedMessage id="common.finish" />
        </ModalFooterButtons>
      </ModalFooter>
    </Modal>
  )
}

export default injectIntl(UserModal)
