import React from "react"
import BasicHubLayout from "components/layouts/BasicHubLayout"
import ErrorCover from "components/layouts/components/ErrorCover"
import { FormattedMessage } from "react-intl"
import { useHistory } from "routing"

const Error404 = () => {
  const history = useHistory()

  return (
    <BasicHubLayout>
      <ErrorCover
        action={
          <button className="btn btn-primary" type="button" onClick={() => history.push("/")}>
            <FormattedMessage id="errors.404.action" />
          </button>
        }
        description={<FormattedMessage id="errors.404.description" />}
        title={<FormattedMessage id="errors.404.title" />}
      />
    </BasicHubLayout>
  )
}

export default Error404
