import parse from "url-parse"
import { useLocation, matchPath } from "routing"
import { useToggle } from "react-use"

export default function useSidebarSubmenu(subLinks) {
  const page = useLocation()

  const isCurrentPageInSubmenu = subLinks.some(route =>
    matchPath(page.pathname, { path: parse(route.path).pathname, exact: true, strict: false })
  )

  const [isSubmenuOpen, toggleSubMenu] = useToggle(isCurrentPageInSubmenu)

  return { isSubmenuOpen, toggleSubMenu, isCurrentPageInSubmenu }
}
