import { useState, useEffect } from "react"
import { getOrganization as getActingOrganization } from "lib/user-session"
import { getContractedProducts } from "data/apis/mint"
import { getOrganization } from "data/apis/foundry"
import _flatMap from "lodash/flatMap"
import _filter from "lodash/filter"
import { useMountedState } from "react-use"

export default function useIsSkuOnMsaContract(sku) {
  const [isSkuOnContract, setIsSkuOnContract] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const isMounted = useMountedState()

  useEffect(() => {
    const retrieveIsSkuOnContract = async () => {
      const actingOrganization = getActingOrganization()
      const organization = await getOrganization(actingOrganization)
      if (organization.isMsa) {
        const result = await getContractedProducts(actingOrganization)
        let contractedProducts
        if (isMounted()) {
          if (result.length > 0) {
            const { records } = result[0]
            // To merge list of products for all currencies under records
            contractedProducts = _flatMap(records, record => record.products)
            setIsSkuOnContract(_filter(contractedProducts, product => product.sku === sku).length > 0)
          } else {
            setIsSkuOnContract(false)
          }
        }
      }
    }
    if (sku) {
      try {
        retrieveIsSkuOnContract()
      } finally {
        setLoaded(true)
      }
    }
  }, [isMounted, isSkuOnContract, loaded, sku])
  return [isSkuOnContract, loaded]
}
