import React, { FunctionComponent, HTMLAttributes, ReactNode } from "react"
import { Tooltip, TooltipProps } from "reactstrap"
import { useToggle } from "react-use"
import classNames from "classnames"

/**
 * Recommended usage:
 * <InfoButton>Description text</InfoButton>
 *
 * <InfoButton>
 *    <InfoButtonTitle>Title</InfoButtonTitle>
 *    <InfoButtonText>Description text</InfoButtonText>
 * </InfoButton>
 */
type InfoButtonProps = FunctionComponent<
  HTMLAttributes<HTMLOrSVGElement> &
    Partial<TooltipProps> & {
      ariaLabel?: string
      anchor?: ReactNode
      iconSize?: string
      iconClassName?: string
    }
>

export const InfoButton: InfoButtonProps = ({
  children,
  ariaLabel = "", // optional here, as InfoButton may wrap around a different anchor instead of an info button
  anchor,
  id = "InfoButton",
  placement = "top",
  iconSize = "15px",
  iconClassName,
  ...props
}) => {
  const [isTooltipOpen, toggleToolTip] = useToggle(false)
  const displayAnchor = () => {
    if (anchor) return anchor

    return (
      //./InfoButton.svg
      <svg
        id={id}
        className={classNames("info-button__anchor", iconClassName)}
        width={iconSize}
        height={iconSize}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label={ariaLabel} // describes the icon
        aria-describedby={`${id}-tooltip-container`} // describes the content of the tooltip
      >
        <g id={`${id}-page-1`} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id={`${id}-common-info-filled`} transform="translate(-1.000000, -1.000000)">
            <g id={`${id}-info_filled`} transform="translate(3.000000, 3.000000)">
              <path
                d="M17.1709579,8.94116842 C17.1709579,13.4866421 13.4856947,17.1719053 8.94022105,17.1719053 C4.39569474,17.1719053 0.710431579,13.4866421 0.710431579,8.94116842 C0.710431579,4.39569474 4.39569474,0.710431579 8.94022105,0.710431579 C13.4856947,0.710431579 17.1709579,4.39569474 17.1709579,8.94116842 Z"
                id={`${id}-stroke-1`}
                stroke="#000000"
                strokeWidth="1.2"
                fill="#000000"
                strokeLinecap="round"
              ></path>
              <path
                d="M9.036,11.8692947 L9.036,8.29024211 C9.036,8.17978516 8.94645695,8.09024211 8.836,8.09024211 L7.5,8.09024211 L7.5,8.09024211"
                id={`${id}-stroke-5`}
                stroke="#FFFFFF"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <line
                x1="7.5"
                y1="12.5"
                x2="10.5"
                y2="12.5"
                id={`${id}-line-39`}
                stroke="#FFFFFF"
                strokeWidth="1.2"
                strokeLinecap="round"
              ></line>
              <circle id={`${id}-oval`} fill="#FFFFFF" cx="8.9" cy="5.8" r="1"></circle>
            </g>
          </g>
        </g>
      </svg>
    )
  }

  return (
    <>
      {displayAnchor()}
      <Tooltip
        id={`${id}-tooltip-container`}
        placement={placement}
        isOpen={isTooltipOpen}
        toggle={toggleToolTip}
        {...props}
        target={id}
      >
        {children}
      </Tooltip>
    </>
  )
}

export const InfoButtonTitle = ({ children, className, ...props }) => {
  return (
    <div className={classNames("info-button__title", className)} {...props}>
      {children}
    </div>
  )
}

export const InfoButtonText = ({ children, className, ...props }) => {
  return (
    <div className={classNames("info-button__text", className)} {...props}>
      {children}
    </div>
  )
}
