import { logJavascriptError } from "lib/logging/logging"
import { isDevEnv } from "lib/user-session"

function createAsyncActionTypes(namespace, entity, action) {
  const types = {
    start: `${namespace}/${entity}/${action}_START`,
    success: `${namespace}/${entity}/${action}_SUCCESS`,
    failure: `${namespace}/${entity}/${action}_FAILURE`
  }

  return {
    types,
    start: initialState => ({ type: types.start, initialState }),
    success: results => ({ type: types.success, results }),
    failure: err => ({ type: types.failure, err })
  }
}

function createSyncActionType(namespace, entity, action) {
  const type = `${namespace}/${entity}/${action}`

  return {
    type,
    fn: ({ ...params }) => ({ type, ...params })
  }
}

// this is a redux middleware used to handle API fetch errors
// in order for this middleware to be useful - redux error action has to be activated on API errors
// with the "err" object argument as described in the createAsyncActionTypes function.
// for example see retrieveView in ducks/releases under the catch statement
const reduxErrorHandler = store => next => action => {
  // prevent infinite loop updates by verifying the error wasn't already handled
  if (action.err !== undefined && action.err !== null && !action.err.handled) {
    if (isDevEnv) {
      console.log("reduxErrorHandler error on action", action)
    } else {
      logJavascriptError("Execution error", {
        message: action,
        additionalData: "Intercepted by reduxErrorHandler"
      })
    }
  }

  return next(action)
}

export { createAsyncActionTypes, createSyncActionType, reduxErrorHandler }
