export const DID_REQUEST_STATUS_VALUES = Object.freeze({
  REQUEST_RECEIVED: "received",
  SUBMITTED_TO_CARRIER: "submitted",
  CARRIER_ACKNOWLEDGED: "acknowledged",
  PARTIALLY_COMPLETED: "partial",
  COMPLETED: "completed",
  FORCE_COMPLETED: "forcecompleted",
  CUSTOMER_CANCELLED: "cancelled",
  REJECTED: "rejected",
  PARTIALLY_BACKORDERED: "partiallybackordered",
  BACKORDERED: "backordered"
})
