import { searchExtensions, searchHardware, searchServices } from "./apis/foundry"
import { searchPeers } from "./apis/foundry/peers"

import _keyBy from "lodash/keyBy"
import _curryRight from "lodash/curryRight"
import { emptyPromise } from "./query/resolverHelpers"

function hardware() {
  return {
    resolve: retrieveHardwareForHandsets,
    reduce: combineHardwareWithHandsets
  }
}

function retrieveHardwareForHandsets(handsets) {
  const hardwareIds = handsets.filter(handset => !!handset.hardware).map(handset => handset.hardware.id)
  if (hardwareIds.length > 0) {
    return searchHardware({ ids: hardwareIds })
  }
  return emptyPromise()
}

function combineHardwareWithHandsets(handsets, hardwares) {
  const hardwareMap = _keyBy(hardwares, "id")
  return handsets.map(handset => ({
    ...handset,
    hardware: hardwareMap[handset?.hardware?.id]
  }))
}

function services(serviceQuery) {
  return {
    resolve: _curryRight(retrieveServicesForHandsets, 2)(serviceQuery),
    reduce: combineHandsetsWithServices
  }
}

function retrieveServicesForHandsets(handsets, serviceQuery = serviceIds => searchServices({ ids: serviceIds })) {
  const serviceIds = handsets.filter(handset => handset.service).map(handset => handset.service.id)
  if (serviceIds.length > 0) {
    return serviceQuery(serviceIds)
  }
  return emptyPromise()
}

function combineHandsetsWithServices(handsets, services) {
  const serviceMap = _keyBy(services, "id")
  return handsets.map(handset => ({
    ...handset,
    service: handset.service && serviceMap[handset.service.id]
  }))
}

function peers(peersQuery) {
  return {
    resolve: _curryRight(retrievePeersForHandsets, 2)(peersQuery),
    reduce: combinePeersWithHandsets
  }
}

function retrievePeersForHandsets(handsets, request = peerIds => searchPeers({ ids: peerIds })) {
  const lines = handsets.map(handset => handset.lines)
  const peerIds = lines.map(line => line.map(number => number?.peer?.id).filter(Boolean)).flat()
  if (peerIds.length > 0) {
    return request(peerIds)
  }
  return emptyPromise()
}

function combinePeersWithHandsets(handsets, peers) {
  const peersMap = _keyBy(peers, "id")
  return handsets.map(handset => {
    const newLines = handset.lines.map(line => ({
      ...line,
      peer: peersMap[line?.peer?.id]
    }))
    return { ...handset, lines: newLines }
  })
}

function peerExtensions(request) {
  return {
    resolve: _curryRight(retrieveExtensionsForPeers, 2)(request),
    reduce: combinePeersWithExtensions
  }
}

function retrieveExtensionsForPeers(extensions, request = peerIds => searchExtensions(peerIds)) {
  const extensionIds = extensions.filter(data => data.extension).map(data => data.extension.id)
  if (extensionIds.length > 0) {
    return request({ ids: extensionIds })
  }
  return emptyPromise()
}

function combinePeersWithExtensions(peers, extensions) {
  const extensionMap = _keyBy(extensions, "id")
  return peers.map(peer => ({
    ...peer,
    extension: extensionMap[peer.extension.id]
  }))
}

export { hardware, services, peers, peerExtensions, retrieveHardwareForHandsets, combineHardwareWithHandsets }
