import { createAsyncActionTypes, createSyncActionType } from "ducks/utils"

export const userLoadActions = createAsyncActionTypes("userProfile", "user", "load")
export const servicesLoadActions = createAsyncActionTypes("userProfileServices", "services", "load")
export const handsetsLoadActions = createAsyncActionTypes("userProfile", "handsets", "load")
export const mobilesLoadActions = createAsyncActionTypes("userProfile", "mobiles", "load")
export const idpsLoadActions = createAsyncActionTypes("userProfile", "idps", "load")
export const idpsUpdateActions = createAsyncActionTypes("userProfile", "idps", "update")
export const idpsResetActions = createSyncActionType("userProfile", "idps", "clear")
export const recordingConfigLoadActions = createAsyncActionTypes("userProfile", "recordingConfig", "load")
export const contractProductsLoadActions = createAsyncActionTypes("userProfile", "contractProducts", "load")
export const resetPasswordUpdateActions = createAsyncActionTypes("userProfile", "resetPassword", "update")
export const resetPasswordClearActions = createSyncActionType("userProfile", "resetPassword", "clear")
