import { lazy } from "react"

import { ProtectedHubLayoutRoute } from "components/layouts/HubLayout"

import { troubleshootingPath } from "./Paths"

const Troubleshooting = lazy(() => import("./Troubleshooting"))

const Routes = () => {
  return (
    <>
      <ProtectedHubLayoutRoute exact path={troubleshootingPath} Component={Troubleshooting} />
    </>
  )
}

export default Routes
