import { useIntl } from "react-intl"
import SupportWarningBanner from "components/support/SupportWarningBanner"
import { IconLock } from "@fuze/icon-font"

export default function NoContractedProducts() {
  const { formatMessage } = useIntl()

  return (
    <SupportWarningBanner
      icon={<IconLock />}
      title={formatMessage({ id: "product.no.products.title" })}
      description={formatMessage({ id: "product.no.products.description" })}
    />
  )
}
