import { getToken } from "lib/token"
import getConfiguration from "data/configuration"
import _getEvents from "@fuze/apis-butler/dist/events/functions/getEvents"
import _getUsers from "@fuze/apis-butler/dist/events/functions/getUsers"
import _getGeoInfo from "@fuze/apis-butler/dist/locations/functions/getGeoInfo"
import _createSalesforceLocationTicket from "@fuze/apis-butler/dist/locations/functions/createSalesforceLocationTicket"
import _retrieveUsersListAsCSV from "@fuze/apis-butler/dist/events/functions/retrieveUsersListAsCSV"
import _getGoogleMapApiToken from "@fuze/apis-butler/dist/tokens/functions/getGoogleMapApiToken"
import _getAnalyticsApiToken from "@fuze/apis-butler/dist/tokens/functions/getAnalyticsApiToken"
import _flow from "lodash/flow"
import { errorInterceptor } from "data/apis/errorInterceptor"
import { getOrganization as getActingOrganization } from "lib/user-session"
import { bearerToken, extractData, populateParameter } from "./utils"

const url = populateParameter(async () => {
  const { butler } = await getConfiguration()
  return butler.url
})

const defaultParameters = _flow(errorInterceptor, url, bearerToken)
const wrapButlerFunctionWithoutOrganization = _flow(defaultParameters, extractData)

/**
 * Some of our butler library function parameters have the format of (baseUrl, token, arguments)
 * This library takes a function and returns a new function that automatically fills in the baseUrl, warden token
 *
 * @param {Function} fn A function call to the foundry API
 */
function wrapButlerFunctionWithoutOrganizationPaginated(fn) {
  return async function (...query) {
    const { butler } = await getConfiguration()
    const token = getToken()
    const organization = getActingOrganization()

    const { data, pagination } = await fn(butler.url, token, organization, ...query)
    return { data, pagination }
  }
}

const retrieveUsersListAsCSV = _flow(
  wrapButlerFunctionWithoutOrganizationPaginated,
  errorInterceptor
)(_retrieveUsersListAsCSV)
async function downloadUsersListCsv(filters) {
  const response = await retrieveUsersListAsCSV(filters)

  return { data: response.data }
}

async function getEvents(...props) {
  return wrapButlerFunctionWithoutOrganizationPaginated(_getEvents)(...props)
}

async function getUsers(...props) {
  return wrapButlerFunctionWithoutOrganizationPaginated(_getUsers)(...props)
}

async function getGoogleMapApiToken(...props) {
  const { butler } = await getConfiguration()
  const token = getToken()
  return _getGoogleMapApiToken(butler.url, token)
}

async function getAnalyticsApiToken(...props) {
  const { butler } = await getConfiguration()
  const token = getToken()
  return _getAnalyticsApiToken(butler.url, token)
}

async function getGeoInfo(locationId) {
  const { butler } = await getConfiguration()
  const token = getToken()
  return _getGeoInfo(butler.url, token, locationId)
}

const downloadUsersListCsvHandled = _flow(errorInterceptor)(downloadUsersListCsv)
const getEventsHandled = _flow(errorInterceptor)(getEvents)
const getUsersHandled = _flow(errorInterceptor)(getUsers)

const createSalesforceLocationTicketHandled = data =>
  wrapButlerFunctionWithoutOrganization(_createSalesforceLocationTicket)(data)

export {
  downloadUsersListCsvHandled as downloadUsersListCsv,
  getEventsHandled as getEvents,
  getUsersHandled as getUsers,
  getGeoInfo,
  createSalesforceLocationTicketHandled as createSalesforceLocationTicket,
  getGoogleMapApiToken,
  getAnalyticsApiToken
}
