import _getMigrationPlans from "@fuze/apis-foundry/dist/migration/functions/getMigrationPlans"
import _createMigrationPlan from "@fuze/apis-foundry/dist/migration/functions/createMigrationPlan"
import _getAggregateReport from "@fuze/apis-foundry/dist/migration/functions/getAggregateReport"
import _getMigrationPlanActionsByPage from "@fuze/apis-foundry/dist/migration/functions/getMigrationPlanActionsByPage"
import { wrapAllResultsNoOrganization, wrapAllResultsNoOrganizationNoDataExtracting } from "./decorators"
import { getOrganization as getActingOrganization } from "lib/user-session"
import _searchUserMigrationPlanActions from "@fuze/apis-foundry/dist/migration/functions/searchUserMigrationPlanActions"
import _searchMigrationPlanActions from "@fuze/apis-foundry/dist/migration/functions/searchMigrationPlanActions"
import _getMigrationPlanContractOptions from "@fuze/apis-foundry/dist/migration/functions/getMigrationPlanContractOptions"
import _updateMigrationPlanAction from "@fuze/apis-foundry/dist/migration/functions/updateMigrationPlanAction"
import _validateMigrationActions from "@fuze/apis-foundry/dist/migration/functions/validateMigrationActions"
import _bulkMigrateServices from "@fuze/apis-foundry/dist/migration/functions/bulkMigrateServices"
import _refreshMigrationPlan from "@fuze/apis-foundry/dist/migration/functions/refreshMigrationPlan"

/**
 * Get Alloy migration plans for organizations
 *
 * @param organizationCode
 * @param status
 */
export const getMigrationPlans = (organizationCode, status, query) =>
  wrapAllResultsNoOrganization(_getMigrationPlans)(organizationCode, status, query)

export async function getOrganizationMigrationPlan() {
  const organizationCode = getActingOrganization()
  const data = await getMigrationPlans(organizationCode)
  return data?.[0]
}

export async function isOrganizatinInMigration() {
  const migrationPlan = await getOrganizationMigrationPlan()
  return migrationPlan?.status === "READY" || migrationPlan?.status === "DRAFT"
}

/**
 * Create Alloy migration plan for organization
 *
 * @param organizationCode
 */
export const createMigrationPlan = organizationCode =>
  wrapAllResultsNoOrganization(_createMigrationPlan)(organizationCode)

/**
 * Get Alloy migration aggregate report for organization
 *
 * @param organizationCode
 */
export const getAggregateReport = organizationCode =>
  wrapAllResultsNoOrganization(_getAggregateReport)(organizationCode)

/**
 * Get Alloy migration plan actions for an organization
 *
 * @param organizationCode
 */
export const getMigrationPlanActionsByPage = (organizationCode, pageSize, page = 1) => {
  const offset = (page - 1) * pageSize
  return wrapAllResultsNoOrganizationNoDataExtracting(_getMigrationPlanActionsByPage)(organizationCode, {
    offset,
    limit: pageSize
  })
}

export const searchUserMigrationPlanActionsByPage = (organizationCode, q, pageSize, page = 1) => {
  const offset = (page - 1) * pageSize
  return wrapAllResultsNoOrganizationNoDataExtracting(_searchUserMigrationPlanActions)(organizationCode, q, {
    offset,
    limit: pageSize
  })
}

export const searchMigrationPlanActionsByPage = (organizationCode, q, pageSize, page = 1) => {
  const offset = (page - 1) * pageSize
  q.userless = true
  q.userStatuses = undefined
  return wrapAllResultsNoOrganizationNoDataExtracting(_searchMigrationPlanActions)(organizationCode, q, {
    offset,
    limit: pageSize
  })
}

export const getMigrationPlanContractOptions = organizationCode =>
  wrapAllResultsNoOrganization(_getMigrationPlanContractOptions)(organizationCode)

export const updateMigrationPlanAction = (organizationCode, actionId, targetSKU) =>
  wrapAllResultsNoOrganization(_updateMigrationPlanAction)(organizationCode, actionId, targetSKU)

export const validateMigrationActions = (organizationCode, migrateRequest) =>
  wrapAllResultsNoOrganization(_validateMigrationActions)(organizationCode, migrateRequest)

export const bulkMigrateServices = (organizationCode, migrateRequest) =>
  wrapAllResultsNoOrganization(_bulkMigrateServices)(organizationCode, migrateRequest)

export const refreshMigrationPlan = (organizationCode, migrateRequest) =>
  wrapAllResultsNoOrganization(_refreshMigrationPlan)(organizationCode, migrateRequest)

export async function isCutomerMigrationLocked(orgCode) {
  const customers = await getMigrationPlans(orgCode)
  return !!customers[0]?.workingJobId
}
