import { FormattedMessage } from "react-intl"
import { ImageRow, Image, ImageContent } from "components/Image"
import ContactSupportInline from "./ContactSupportInline"
import { IconAlertWarning } from "@fuze/icon-font"

export default function SupportWarningBanner({ icon, img, alt = "", title, description, ...props }) {
  return (
    <ImageRow className="support-warning-banner" {...props}>
      <Image fontSize="3rem">
        {img && <img src={img} alt={alt} />}
        {icon || <IconAlertWarning className="support-warning-banner_icon" />}
      </Image>
      <ImageContent>
        <h3 className="title">{title || <FormattedMessage id="errors.supportWarning.title" />}</h3>
        <p className="description">
          {description || (
            <FormattedMessage
              id="errors.supportWarning.description"
              values={{ contactSupport: ContactSupportInline() }}
            />
          )}
        </p>
      </ImageContent>
    </ImageRow>
  )
}
