import React from "react"
import { Route, Redirect, useLocation } from "routing"

const RedirectRoute = ({ pathIn, pathOut, ...rest }) => {
  const location = useLocation()
  const pathOutWithQueryParameters = pathOut + location.search

  return <Route {...rest} path={pathIn} render={() => <Redirect to={pathOutWithQueryParameters} />} />
}

export default RedirectRoute
