import React from "react"
import { Col, Row } from "reactstrap"
import classNames from "classnames"
import useExpandedJob from "hooks/useExpandedJob"
import OrdersModalContent from "./OrdersModalContent"
import { SingleRowDoubleColTableWithFooter } from "./tables"
import { FormattedMessage, useIntl } from "react-intl"
import styles from "./BulkModal.module.scss"
import { ReactComponent as BuildingIcon } from "../../images/building.svg"
import iconStyles from "../../components/icon/Icon.module.scss"
import BillingImpactMessage from "./BillingImpactMessage"
import { Modal, ModalHeader, ModalBody, ModalSubHeader, ModalFooter } from "@fuze/react-fuzzy"
import { SpinnerWithText } from "components/spinner/Spinner"

import _uniq from "lodash/uniq"
import _intersectionWith from "lodash/intersectionWith"
import { useAllLocations } from "hooks/useLocations"
import ModalFooterButtons from "components/buttons/ModalFooterButtons"

function useLocationsInJob(jobId) {
  const [job, jobLoading] = useExpandedJob(jobId)
  const [locations, locationsLoading] = useAllLocations()

  const loading = jobLoading || locationsLoading
  if (!loading) {
    const locationIds = (job.tasks && _uniq(job.tasks.map(jobTask => jobTask.task.user.locationId))) || []
    const jobLocations = _intersectionWith(locations, locationIds, (location, id) => location.id === id)
    return [jobLocations, loading]
  }

  return [[], loading]
}

const BulkModal = ({ isOpen, onToggle, job, companyName, billing, completedBy, loadDate }) => {
  const intl = useIntl()
  const [locations, locationsLoading] = useLocationsInJob(job.id)

  if (!job || !job.meta) {
    return null
  }

  const { numberOfUsers, fileName } = job.meta
  const title = numberOfUsers ? "table.modals.bulkUsersAdded" : "table.modals.bulkTitle"

  return (
    <Modal id="bulk-modal" isOpen={true} toggle={onToggle} color="primary">
      <ModalHeader>{intl.formatMessage({ id: title }, { numberOfUsers: numberOfUsers })}</ModalHeader>
      <ModalSubHeader>
        <OrdersModalContent date={loadDate} completedBy={completedBy} />
      </ModalSubHeader>
      <ModalBody>
        <SingleRowDoubleColTableWithFooter
          leftSideTitle={<FormattedMessage id={"table.modals.addedTo"} />}
          rightSideTitle={<FormattedMessage id={"table.modals.fileName"} />}
          leftSideData={
            <Row>
              <Col xs={2} className={classNames(styles.avatarCol, styles.mobileHiddenCol)}>
                <span className={classNames(iconStyles.buildingWrapper)}>
                  <BuildingIcon className={classNames(iconStyles.purple, iconStyles.tall)} />
                </span>
              </Col>
              <Col className={styles.companyDetails}>
                <>
                  {companyName && companyName}
                  <div className={styles.subheading}>
                    {locationsLoading ? (
                      <SpinnerWithText color="secondary" size="sm">
                        <FormattedMessage id={"table.modals.loadingLocations"} />
                      </SpinnerWithText>
                    ) : locations.length > 3 ? (
                      <FormattedMessage id={"table.modals.multipleLocations"} />
                    ) : (
                      locations.map(location => location.city).join(", ")
                    )}
                  </div>
                </>
              </Col>
            </Row>
          }
          rightSideData={
            <>
              <div className={styles.mobileText}>
                <FormattedMessage id={"table.modals.fileName"} />
              </div>
              <div className={styles.fileName}>{fileName}</div>
            </>
          }
          billingImpact={billing.billingImpact !== undefined && <BillingImpactMessage billing={billing} />}
        />
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons onAction={onToggle}>
          <FormattedMessage id="common.finish" />
        </ModalFooterButtons>
      </ModalFooter>
    </Modal>
  )
}

export default BulkModal
