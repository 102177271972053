import React, { lazy } from "react"

import { ProtectedHubLayoutRoute } from "components/layouts/HubLayout"

import {
  audioLibraryPath,
  createRecordingPath,
  userGroupsPath,
  userGroupPath,
  createUserGroupsPath,
  autoAttendantPath
} from "./Paths"
import { Switch } from "routing"

const AudioLibraryPage = lazy(() => import("./AudioLibrary/AudioLibraryPage"))
const UserGroupsPage = lazy(() => import("./UserGroups/UserGroupsPage"))
const NewUserGroupPage = lazy(() => import("./NewUserGroup/NewUserGroupPage"))
const CreateRecordingPage = lazy(() => import("./AudioLibrary/CreateRecordingPage"))
const UserGroupPage = lazy(() => import("./UserGroup/UserGroupPage"))
const AutoAttendantPage = lazy(() => import("./AutoAttendant/AutoAttendantPage"))

export const AudioLibraryRoutes = () => (
  <Switch>
    <ProtectedHubLayoutRoute exact path={createUserGroupsPath} Component={NewUserGroupPage} />
    <ProtectedHubLayoutRoute exact path={autoAttendantPath} Component={AutoAttendantPage} />
    <ProtectedHubLayoutRoute exact path={userGroupsPath} Component={UserGroupsPage} />
    <ProtectedHubLayoutRoute exact path={userGroupPath} Component={UserGroupPage} />
    <ProtectedHubLayoutRoute exact path={audioLibraryPath} Component={AudioLibraryPage} />
    <ProtectedHubLayoutRoute exact path={createRecordingPath} Component={CreateRecordingPage} />
  </Switch>
)

export const CreateRecordingAccess = ({ children }) => children(createRecordingPath)
