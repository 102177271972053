import React from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"
import classnames from "classnames"

interface ITable {}

const Table: FuzzyFunctionalComponent<ITable> = ({ children, className, ...props }) => {
  return (
    <table className={classnames("fuze-table table", className)} {...props}>
      {children}
    </table>
  )
}

export default Table
