import useIsAlloy from "hooks/useIsAlloy"
import useIsSkuOnContract from "hooks/useIsSkuOnMsaContract"

/**
 * Functionality is similar to PermissionsProtected
 * Protects content from being access by someone who isn't on an Alloy catalog OR
 * someone who does not have the proper Alloy SKU to access a feature
 *
 * Usage:
 *
 * <AlloyProtected requiresSku={true} sku={SOME_SKU}>
 *   ... some content you want only Alloy users with SOME_SKU to see ...
 * </AlloyProtected>
 *
 * You can provide a fallback if the user does not have the required SKU/Alloy access
 *
 * <AlloyProtected
 *    requiresSku={true} sku={SOME_SKU}
 *    fallback={... show something to the user if they don't have access ...}>
 *   ... some content you want only Alloy users with the SOME_SKU to see ...
 * </AlloyProtected>
 *
 */
const AlloyProtected = ({ requiresSku, sku, fallback, children }) => {
  const [isAlloy, isAlloyLoading] = useIsAlloy()
  const [hasContractedSku, skuLoaded] = useIsSkuOnContract(sku)
  if ((!isAlloyLoading && !isAlloy) || (skuLoaded && requiresSku && !hasContractedSku)) {
    if (fallback) {
      return fallback
    }
    return null
  }
  return children
}

export default AlloyProtected
