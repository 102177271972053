import { findMatchingLocale } from "lib/translations"
import { isOrganizationAlloy } from "data/apis/foundry/organizations"
import { getItems } from "@fuze/apis-floppy"

export async function getPhoneModels() {
  const locale = findMatchingLocale()
  const isAlloy = await isOrganizationAlloy()
  const catalog = isAlloy ? "alloy" : "legacy"
  const document = `${catalog}-${locale}`

  const results = await getItems("hub", "phone-models", document, "products")

  return results.value
}
