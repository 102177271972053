import { createAsyncActionTypes, createSyncActionType } from "ducks/utils"

export const loadOrders = createAsyncActionTypes("orders", "object", "load")
export const loadUsers = createAsyncActionTypes("orders", "users", "load")
export const loadLocations = createAsyncActionTypes("orders", "locations", "load")
export const loadDepartments = createAsyncActionTypes("orders", "departments", "load")
export const loadServices = createAsyncActionTypes("orders", "services", "load")
export const loadCurrencies = createAsyncActionTypes("orders", "currencies", "load")
export const loadJobs = createAsyncActionTypes("orders", "jobs", "load")
export const loadProducts = createAsyncActionTypes("orders", "products", "load")
export const loadFloppyProducts = createAsyncActionTypes("orders", "floppyProducts", "load")
export const loadExtensions = createAsyncActionTypes("orders", "extensions", "load")

export const initEventFilterTypes = createSyncActionType("orders", "eventFilter", "init")
export const selectEventFilter = createSyncActionType("orders", "eventFilter", "select")
export const initUserFilterTypes = createSyncActionType("orders", "userFilter", "init")
export const selectUserFilter = createSyncActionType("orders", "userFilter", "select")
export const initDateRangeFilterTypes = createSyncActionType("orders", "dateRange", "init")
export const selectDateRangeFilter = createSyncActionType("orders", "dateRange", "select")
export const tabSelection = createSyncActionType("orders", "tab", "select")
