import React from "react"
import OrdersModalContent from "./OrdersModalContent"
import { SingleRowDoubleColTableWithFooter } from "./tables"
import { FormattedMessage, useIntl } from "react-intl"
import { getFullUserName } from "lib/string-utilities"
import UserDetails from "../../components/UserDetails"
import BillingImpactMessage from "./BillingImpactMessage"
import styles from "./ServiceModal.module.scss"
import { parseDidToPhoneNumber } from "lib/phone-number-utilities"
import { Modal, ModalHeader, ModalSubHeader, ModalBody, ModalFooter } from "@fuze/react-fuzzy"
import ModalFooterButtons from "components/buttons/ModalFooterButtons"

const ServiceModal = ({
  isOpen,
  onToggle,
  servicesAffected,
  billing,
  completedBy,
  targetUser,
  loadDate,
  isRemovingService,
  did,
  extension,
  product
}) => {
  const { formatMessage } = useIntl()

  if (!servicesAffected) {
    return null
  }
  const titleId = isRemovingService ? "table.modals.serviceRemovedTitle" : "table.modals.serviceAddedTitle"
  const leftSideTitleId = isRemovingService ? "table.modals.removedFrom" : "table.modals.addedTo"
  const rightSideTitleId = isRemovingService ? "table.modals.serviceRemoved" : "table.modals.serviceAdded"

  return (
    <Modal id="service-modal" isOpen={isOpen} toggle={onToggle} color="primary">
      {targetUser && (
        <ModalHeader>
          {servicesAffected && targetUser
            ? formatMessage(
                { id: titleId },
                { service: formatMessage({ id: servicesAffected[0] }), user: getFullUserName(targetUser) }
              )
            : null}
        </ModalHeader>
      )}
      <ModalSubHeader>
        <OrdersModalContent date={loadDate} completedBy={completedBy} />
      </ModalSubHeader>
      <ModalBody>
        <SingleRowDoubleColTableWithFooter
          leftSideTitle={<FormattedMessage id={leftSideTitleId} />}
          rightSideTitle={<FormattedMessage id={rightSideTitleId} />}
          leftSideData={
            targetUser ? (
              <UserDetails user={targetUser} isALink={true} showAvatar={["md", "lg", "xl"]} isSmall={false} />
            ) : (
              <FormattedMessage id={"user.deleted"} />
            )
          }
          rightSideData={
            <>
              <div className={styles.mobileText}>
                <FormattedMessage id={rightSideTitleId} />
              </div>
              {did && (
                <div>
                  <span>{parseDidToPhoneNumber(did)}</span>
                  {extension?.extension && (
                    <span>
                      <FormattedMessage id={"table.modals.ext"} />
                      {extension?.extension}
                    </span>
                  )}
                </div>
              )}

              {product && <div>{product?.name}</div>}
            </>
          }
          billingImpact={
            billing.billingImpact !== undefined && (
              <BillingImpactMessage billing={billing} isRemovedModal={isRemovingService} />
            )
          }
        />
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons onAction={onToggle}>
          <FormattedMessage id="common.finish" />
        </ModalFooterButtons>
      </ModalFooter>
    </Modal>
  )
}

export default ServiceModal
