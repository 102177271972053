import React, { createContext, useContext } from "react"
import { useAsync } from "react-use"
import getConfiguration from "data/configuration"
import MaintenancePage from "Errors/MaintenancePage"

const ConfigurationContext = createContext()

export function ConfigurationProvider({ children }) {
  const [configuration, loading] = useInternalConfiguration()

  if (loading) {
    return null
  }

  if (configuration?.hub?.maintenance) {
    return <MaintenancePage />
  }

  return <ConfigurationContext.Provider value={configuration}>{children}</ConfigurationContext.Provider>
}

function useInternalConfiguration() {
  const { value: configuration, loading, error } = useAsync(getConfiguration, [])

  return [configuration, loading, error]
}

export default function useConfiguration() {
  return useContext(ConfigurationContext)
}
