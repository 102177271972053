import React, { createContext, useState } from "react"
import { FuzzyFunctionalComponent } from "@interfaces/common"

export const TooltipContext = createContext(undefined)

export const tooltipEventTypes = ["onClick", "onHover"]

interface ITooltipContainer {
  method?: "onClick" | "onHover"
}

const TooltipContainer: FuzzyFunctionalComponent<ITooltipContainer> = ({
  children,
  id,
  method = tooltipEventTypes[0],
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return <TooltipContext.Provider value={{ id, isOpen, method, toggle }}>{children}</TooltipContext.Provider>
}

export default TooltipContainer
