import {
  bulkCreateUsersSelector,
  hardwareOrder,
  serviceUpgrade,
  utilitySelector,
  didService,
  createUserSelector,
  deactivateUserSelector,
  deleteUserSelector,
  createUserContactCenterSelector,
  deleteUserContactCenterSelector,
  createUserPhoneNumberSelector,
  deleteUserPhoneNumberSelector,
  createUserSMSSelector,
  deleteUserSMSSelector,
  createUserDiscoverSelector,
  deleteUserDiscoverSelector,
  createUserFaxSelector,
  deleteUserFaxSelector,
  createUserRecordingSelector,
  deleteUserRecordingSelector,
  createTeamsDirectRoutingSelector,
  removeTeamsDirectRoutingSelector
} from "../data/ducks/eventSelectors"

import {
  createUserFaxServiceEvent,
  removeUserFaxServiceEvent,
  createUserRecordingServiceEvent,
  removeUserRecordingServiceEvent,
  createUserPhoneNumberServiceEvent,
  removeUserPhoneNumberServiceEvent,
  createUserDiscoverServiceEvent,
  removeUserDiscoverServiceEvent,
  createUserContactCenterServiceEvent,
  removeUserContactCenterServiceEvent,
  createUserSMSServiceEvent,
  removeUserSMSServiceEvent,
  createPhoneLineServiceEvent,
  //removePhoneLineService,
  createUserEvent,
  deactivateUserEvent,
  deleteUserEvent,
  bulkCreateUsersEvent,
  upgradeUserServicesEvent,
  createUtilityServiceEvent,
  removeUtilityServiceEvent,
  createHardwareOrderEvent,
  createTeamsDirectRoutingServiceEvent,
  removeTeamsDirectRoutingServiceEvent
} from "constants/eventTypes"

import ServiceModal from "../components/ServiceModal"
import ServiceChangedModal from "../components/ServiceChangedModal"
import UserModal from "../components/UserModal"
import UtilityPhoneModal from "../components/UtilityPhoneModal"
import BulkModal from "../components/BulkModal"
import NewLineActivatedModal from "../components/NewLineActivatedModal"
import HardwareModal from "../components/HardwareModal"

import {
  userIcon,
  usersIcon,
  serviceIcon,
  phoneLineIcon,
  upgradeIcon,
  genericIcon,
  hardwareIcon,
  serviceTitle,
  genericTitle,
  serviceSubtitle,
  userSubtitle,
  phoneLineSubtitle,
  bulkUserCreateSubtitle,
  hardwareSubtitle
} from "../components/eventFormatters"

import _keyBy from "lodash/keyBy"

function createEvent(eventType, selector, Modal, tableFormatter) {
  return {
    eventType,
    selector,
    Modal,
    tableFormatter
  }
}

function createTableFormatter(icon, title, subtitle) {
  return {
    icon,
    title,
    subtitle
  }
}

const serviceTableFormatter = createTableFormatter(serviceIcon, serviceTitle, serviceSubtitle)
const createUserTableFormatter = createTableFormatter(userIcon, genericTitle, userSubtitle)
const phoneLineTableFormatter = createTableFormatter(phoneLineIcon, genericTitle, phoneLineSubtitle)
const bulkUserCreateTableFormatter = createTableFormatter(usersIcon, genericTitle, bulkUserCreateSubtitle)
const upgradeTableFormatter = createTableFormatter(upgradeIcon, genericTitle, serviceSubtitle)
const utilityRemovedTableFormatter = createTableFormatter(genericIcon, genericTitle, phoneLineSubtitle)
const createHardwareOrderFormatter = createTableFormatter(hardwareIcon, genericTitle, hardwareSubtitle)

const events = [
  createEvent(createUserFaxServiceEvent, createUserFaxSelector, ServiceModal, serviceTableFormatter),
  createEvent(removeUserFaxServiceEvent, deleteUserFaxSelector, ServiceModal, serviceTableFormatter),
  createEvent(createUserRecordingServiceEvent, createUserRecordingSelector, ServiceModal, serviceTableFormatter),
  createEvent(removeUserRecordingServiceEvent, deleteUserRecordingSelector, ServiceModal, serviceTableFormatter),
  createEvent(createUserDiscoverServiceEvent, createUserDiscoverSelector, ServiceModal, serviceTableFormatter),
  createEvent(removeUserDiscoverServiceEvent, deleteUserDiscoverSelector, ServiceModal, serviceTableFormatter),
  createEvent(createUserPhoneNumberServiceEvent, createUserPhoneNumberSelector, ServiceModal, serviceTableFormatter),
  createEvent(removeUserPhoneNumberServiceEvent, deleteUserPhoneNumberSelector, ServiceModal, serviceTableFormatter),
  createEvent(
    createUserContactCenterServiceEvent,
    createUserContactCenterSelector,
    ServiceModal,
    serviceTableFormatter
  ),
  createEvent(
    removeUserContactCenterServiceEvent,
    deleteUserContactCenterSelector,
    ServiceModal,
    serviceTableFormatter
  ),
  createEvent(createUserSMSServiceEvent, createUserSMSSelector, ServiceModal, serviceTableFormatter),
  createEvent(removeUserSMSServiceEvent, deleteUserSMSSelector, ServiceModal, serviceTableFormatter),
  createEvent(createPhoneLineServiceEvent, didService, NewLineActivatedModal, phoneLineTableFormatter),
  //createFeature(removePhoneLineService, didService, NewLineActivatedModal), we don't do this... yet
  createEvent(createUserEvent, createUserSelector, UserModal, createUserTableFormatter),
  createEvent(deactivateUserEvent, deactivateUserSelector, UserModal, createUserTableFormatter),
  createEvent(deleteUserEvent, deleteUserSelector, UserModal, createUserTableFormatter),
  createEvent(bulkCreateUsersEvent, bulkCreateUsersSelector, BulkModal, bulkUserCreateTableFormatter),
  createEvent(upgradeUserServicesEvent, serviceUpgrade, ServiceChangedModal, upgradeTableFormatter),
  createEvent(createHardwareOrderEvent, hardwareOrder, HardwareModal, createHardwareOrderFormatter),
  createEvent(removeUtilityServiceEvent, utilitySelector, UtilityPhoneModal, utilityRemovedTableFormatter),
  createEvent(createUtilityServiceEvent, utilitySelector, UtilityPhoneModal, phoneLineTableFormatter),
  createEvent(
    createTeamsDirectRoutingServiceEvent,
    createTeamsDirectRoutingSelector,
    ServiceModal,
    serviceTableFormatter
  ),
  createEvent(
    removeTeamsDirectRoutingServiceEvent,
    removeTeamsDirectRoutingSelector,
    ServiceModal,
    serviceTableFormatter
  )
]

const eventMap = _keyBy(events, "eventType")

/**
 * Used by selectors.js
 *
 * @param {string} eventType
 */
export function getSelector(eventType) {
  const event = eventMap[eventType]
  if (event) {
    return event.selector
  }
  return null
}

/**
 * Used by Modals.js
 *
 * @param {string} eventType
 */
export function getModal(eventType) {
  const event = eventMap[eventType]
  if (event) {
    return event.Modal
  }
  return null
}

/**
 * Used by tableFormatters.js
 *
 * @param {string} eventType
 */
export function getEventFormatter(eventType) {
  const event = eventMap[eventType]

  if (event) {
    return event.tableFormatter
  }
  return null
}
