import React, { lazy } from "react"
import { apiKeyManagementPath, apiKeyManagementNewPath } from "./Paths"
import { ProtectedHubLayoutRoute } from "components/layouts/HubLayout"

const ApiKeyManagement = lazy(() => import("./ApiKeyManagement/ApiKeyManagement"))
const NewApiKeyManagement = lazy(() => import("./ApiKeyManagement/components/NewApiKeyManagement"))

const Routes = () => {
  return (
    <>
      <ProtectedHubLayoutRoute exact path={apiKeyManagementPath} Component={ApiKeyManagement} />
      <ProtectedHubLayoutRoute exact path={apiKeyManagementNewPath} Component={NewApiKeyManagement} />
    </>
  )
}

export default Routes
