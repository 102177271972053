import React from "react"

import { Spinner } from "reactstrap"

import "./Spinner.scss"

export function SpinnerWithText({ children, ...props }) {
  return (
    <div className="d-flex align-items-center">
      <Spinner {...props} />
      <div className="ml-3 align-middle">{children}</div>
    </div>
  )
}
