import React, { lazy, Suspense } from "react"
import { Route } from "routing"
import { legalPath } from "./Paths"

import PageSpinner from "components/spinner/PageSpinner"

import { renderBaseLayout } from "components/layouts/BaseLayout"

const LegalWrapper = lazy(() => import("./LegalWrapper"))

const renderLoadingPage = Component => props => (
  <Suspense fallback={<PageSpinner />}>
    <Component {...props} />
  </Suspense>
)

const LegalRoutes = () => (
  <>
    <Route exact path={legalPath} render={renderBaseLayout(renderLoadingPage(LegalWrapper))} />
  </>
)

export { legalPath }
export default LegalRoutes
